import { createRouter, createWebHashHistory } from 'vue-router'
import { USER_LIST,USER } from '@/assets/js/config'
import Login from '@/views/Login.vue'
import Register from '@/views/Register.vue'
import Home from '@/views/Home.vue'
import Bac from '@/views/Bac.vue'
import Cor from '@/views/Cor.vue'
import Dice from '@/views/Dice.vue'
import Rou from '@/views/Rou.vue'
import Tiger from '@/views/Tiger.vue'
import Texasauto from '@/views/Texasauto.vue'
import Personal from '@/views/Personal.vue'
import Help from '@/views/Help.vue'
import Share from '@/views/Share.vue'
import LongFong from '@/views/LongFong.vue'
import Coin from '@/views/Coin.vue'
import Ouya_Url from '@/app_navigation/ouya/views/Url.vue'

var routes;
if(USER==USER_LIST.longyuan){
  routes = [
    {
      path:'/',
      redirect:'/login'
    },
    {
      name:'login',
      path: '/login/:quickUserInfo*',
      component: Login,
      props:true
    },
    {
      name:'register',
      path: '/register/:str(.*)?',
      component: Register,
      props:true
    },
    {
      name:'home',
      path: '/home',
      component: Home
    },
    {
      name:'bac',
      path: '/bac',
      component: Bac
    },
    {
      name:'cor',
      path: '/cor',
      component: Cor
    },
    {
      name:'dice',
      path: '/dice',
      component: Dice
    },
    {
      name:'rou',
      path: '/rou',
      component: Rou
    },
    {
      name:'tiger',
      path: '/tiger',
      component: Tiger
    },
    {
      name:'texasauto',
      path: '/texasauto',
      component: Texasauto
    },
    {
      name:'personal',
      path: '/personal',
      component: Personal
    },
    {
      name:'help',
      path: '/help',
      component: Help
    },
    {
      name:'share',
      path: '/share/:str(.*)',
      component: Share,
      props:true
    },
    {
      name:'longfong',
      path: '/longfong',
      component: LongFong
    },
    {
      name:'coin',
      path: '/coin',
      component: Coin
    },
  ]
}else if(USER==USER_LIST.ouya){
  routes = [
    // {
    //   path:'/ouya',
    //   redirect:'/ouya_url'
    // },
    {
      name:'ouya_url',
      path:'/ouya_url',
      component:Ouya_Url
    },
    {
      path:'/',
      redirect:'/login'
    },
    {
      name:'login',
      path: '/login',
      component: Login,
    },
    {
      name:'home',
      path: '/home',
      component: Home
    },
    {
      name:'bac',
      path: '/bac',
      component: Bac
    },
    {
      name:'cor',
      path: '/cor',
      component: Cor
    },
    {
      name:'dice',
      path: '/dice',
      component: Dice
    },
    {
      name:'rou',
      path: '/rou',
      component: Rou
    },
    {
      name:'personal',
      path: '/personal',
      component: Personal
    },
    {
      name:'help',
      path: '/help',
      component: Help
    },
    {
      name:'share',
      path: '/share/:str(.*)',
      component: Share,
      props:true
    },
  ]
}

const router = createRouter({
  history: createWebHashHistory(),
  routes
})
router.beforeEach((to,from)=>{
  if(USER==USER_LIST.longyuan){
    if(to.name!="login"&&to.name!="register"&&typeof to.params.guid=="undefined"&&to.name!="share"){//不是访问登录页或者注册页时验证如果没有登录
      return {name:'login'}
    }
  }else if(USER==USER_LIST.ouya){
    if(to.name!="ouya_url"&&to.name!="login"&&typeof to.params.guid=="undefined"&&to.name!="share"){//不是访问登录页时验证如果没有登录
      return {name:'ouya_url'}
    }
  }
})
export default router
