<style scoped>
    
</style>
<template>
    <ul class="pagination mb-0">
        <li class="page-item" :class="{'disabled':currPage==1}">
            <span class="page-link py-1" aria-label="Previous" aria-hidden="true" @click="currPage=1" role="button">&laquo;</span>
        </li>
        <li class="page-item" :class="{'active':item==currPage}" v-for="item in currPage" :key="item">
            <i v-if="item==2&&currPage>4" class="d-flex flex-column justify-content-center bi bi-three-dots h-100 text-info px-1 border border-start-0 border-end-0"></i>
            <button v-if="item==1||item>=currPage-2" type="button" class="page-link py-1" @click="currPage=item">{{item}}</button>
        </li>
        <li class="page-item" v-for="item in countPage-currPage" :key="item">
            <i v-if="item+currPage==countPage-1&&currPage<countPage-3" class="d-flex flex-column justify-content-center bi bi-three-dots h-100 text-info px-1 border border-start-0 border-end-0"></i>
            <button v-if="item+currPage==countPage||item+currPage<=currPage+2" type="button" class="page-link py-1" @click="currPage=item+currPage">{{item+currPage}}</button>
        </li>
        <li class="page-item" :class="{'disabled':currPage==countPage}">
            <span class="page-link py-1" aria-label="Next" aria-hidden="true" @click="currPage=countPage" role="button">&raquo;</span>
        </li>
    </ul>
</template>
<script>
export default {
    // emits: ['count-page','change-page'],
    props: {
        countPage:{
            type:Number,
            default:1
        },//总页数
    },
    data() {
        return {
            currPage:1,//当前页码
        };
    },
    watch: {
        currPage(newVal, oldVal) {
            if(newVal!=oldVal){
                console.log(newVal)
                this.$emit("change-page",newVal);
            }
        },
    },
};
</script>