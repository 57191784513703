<style scoped>
    
</style>
<template>
    <div class="d-flex flex-column h-100">
        <!-- 横向导航 -->
        <navbar :class="[isMultiple?'':'d-sm-none']" class="order-first d-flex flex-column" :user-info="userInfo" :notice="notice" :video-line-list="videoLineList" :audio="audio" :curr-video-line="currVideoLine" :has-chat-msg="hasChatMsg" :is-page-ready="isPageReady" :is-fullscreen="isFullscreen" @logout="$root.logout" ></navbar>
        <div class="d-flex flex-grow-1 h-0 flex-column" :class="[(!$root.gameModel)?(isMultiple?'flex-sm-row':'position-xs-relative start-0 end-0 top-0'):(isMultiple?'position-relative flex-sm-row':'position-relative')]">
            <!-- 经典 -->
            <div :class="{'col-sm-5 col-md-4 col-xxl-3 order-1 h-sm-100':isMultiple,'flex-grow-0 flex-sm-grow-1 h-sm-0':!$root.gameModel}" class="d-flex flex-column flex-sm-row position-relative">
                <!-- 纵向导航 -->
                <navbar-vertical v-if="!isMultiple" class="d-none d-sm-flex" :user-info="userInfo" :is-fullscreen="isFullscreen" @show-modal="v=>currModal=v"></navbar-vertical>
                <!-- 电话模式视频 -->
                <div class="d-flex flex-column flex-sm-row position-relative" v-if="$root.gameModel">
                    <div class="col">
                        <div class="pt-60 h-0 position-relative">
                            <media-video class="border-start border-end border-secondary position-absolute top-0 start-0" :video-index="0" id="firstvideo" :room-info="roomInfo" :video-list="videoList" :countdown="countdown" :room-class="(roomInfo.isAr==1&&!$root.gameModel&&!isMultiple)?['top-xs-0 top-sm-5rem mt-2 mt-sm-1 ms-2']:['top-xs-0 top-sm-0 m-2']" :time-class="(roomInfo.isAr==1&&!$root.gameModel&&!isMultiple)?['top-xs-0','top-sm-5rem top-xl-6rem','ms-2']:['top-0','m-2']" :img-class="(!isMultiple)?['h-100','w-100']:['position-absolute translate-middle top-50 start-50']"></media-video>
                        </div>
                    </div>
                    <div class="col">
                        <div class="pt-60 h-0 position-relative">
                            <media-video class="border-start border-end border-secondary position-absolute top-0 start-0" :video-index="1" id="secondvideo" :video-list="videoList" :room-class="['d-none']" :time-class="['d-none']" :img-class="(!isMultiple)?['h-100','w-100']:['position-absolute translate-middle top-50 start-50']"></media-video>
                        </div>
                    </div>
                </div>
                <!-- 视频区 -->
                <div v-else class="d-flex flex-column flex-grow-1 position-relative">
                    <!-- 公告 -->
                    <notice :notice="notice"></notice>
                    <!-- 视频 -->
                    <media-video :class="{'scale-video':isScale}" :room-info="roomInfo" :video-list="videoList" :countdown="countdown" :room-class="(!isMultiple)?['top-xs-0 top-sm-0 mt-2 mt-sm-5 ms-2']:['top-xs-0 top-sm-0 m-2']" :time-class="(!isMultiple)?['top-0 mt-2 mt-sm-5 ms-2']:['top-0 m-2 mt-sm-5']" :img-class="roomInfo.isAr==1?['h-100','w-100']:['position-absolute translate-middle top-50 start-50']"></media-video>
                    <!-- 牌区 -->
                    <div class="position-absolute top-xs-3_5rem top-sm-26 d-flex flex-column ms-2 index-1">
                        
                    </div>
                    <!-- 提交区 -->
                    <div class="virtual-container perspective-600 position-xs-relative position-sm-absolute start-50 bottom-0 bg-xs-success" :class="{'scale-submit':isScale==true}">
                        <div class="rotate3d-x-sm-50 mb-sm-1 p-1 p-sm-0">
                            <!-- 切换区 -->
                            <div class="d-flex justify-content-between justify-content-sm-end justify-content-lg-between mb-1 mb-sm-2">
                                <div class="ms-sm-5 ps-sm-5 d-flex text-white lh-1 fs-sm-5 align-items-center align-items-sm-end d-sm-none d-lg-flex">
                                    <!-- <span class="ms-2">{{$t("room.ingBet")}}：{{getAmountCount("submiting")}}</span>
                                    <span class="ms-4">{{$t("room.edBet")}}：{{getAmountCount("submited")}}</span>
                                    <span class="ms-4">{{$t("room.allBet")}}：{{submitedCount+getAmountCount("submiting")}}</span> -->
                                    <!-- <span class="ms-4">预扣：100</span> -->
                                </div>
                                <div class="btn-group btn-shadow-right" role="group">
                                    <!-- <input type="radio" class="btn-check" name="btnradio" @change="changeModel(0,$event)" value="0" v-model="submitModel" id="submitModelZ" >
                                    <label class="btn btn-outline-red fw-bold" for="submitModelZ">{{$t("common.z")}}</label>
                                    <input type="radio" class="btn-check" name="btnradio" @change="changeModel(1,$event)" value="1" v-model="submitModel" id="submitModelX" >
                                    <label class="btn btn-outline-blue fw-bold" for="submitModelX">{{$t("common.x")}}</label> -->
                                </div>
                            </div>
                            <div class="d-flex flex-column flex-sm-row">
                                <!-- 选筹区 -->
                                <div class="d-flex flex-sm-column justify-content-between justify-content-sm-end align-items-center text-black order-2 order-sm-1 mb-2 mb-sm-0">
                                    <div class="d-flex justify-content-around justify-content-sm-start col col-sm-auto mt-1" v-for="i in 3" :key="i">
                                        <div class="position-relative me-1 chips rounded-circle" :class="{active:amount==currAmountListSort[2*i-2]}" @click="amount=currAmountListSort[2*i-2]" role="button">
                                            <img class="w-3rem rounded-circle chips-shadow" :src="require('@/assets/images/room/chips_'+currAmountListSort[2*i-2]+'.png')" alt="">
                                            <span class="position-absolute start-50 top-50 fw-bold chips-text text-nowrap">{{$root.chipsTxtConvert(currAmountListSort[2*i-2])}}</span>
                                        </div>
                                        <button v-if="i==3" @click="isShowMoreAmount=!isShowMoreAmount" class="btn btn-outline-secondary btn-shadow-left">
                                            <i class="bi bi-grid-fill"></i>
                                        </button>
                                        <div v-else class="position-relative me-1 chips rounded-circle" :class="{active:amount==currAmountListSort[2*i-1]}" @click="amount=currAmountListSort[2*i-1]" role="button">
                                            <img class="w-3rem rounded-circle chips-shadow" :src="require('@/assets/images/room/chips_'+currAmountListSort[2*i-1]+'.png')" alt="">
                                            <span class="position-absolute start-50 top-50 fw-bold chips-text text-nowrap">{{$root.chipsTxtConvert(currAmountListSort[2*i-1])}}</span>
                                        </div>
                                    </div>
                                </div>
                                <!-- 提交区 -->
                                <div class="d-flex flex-column fw-bold text-white rounded-10 border-solid border-top-0 border-xs-2 border-sm-4 me-sm-1 mb-2 mb-sm-0 position-relative order-1 order-sm-2 fs-3">
                                    <!-- 一二行 -->
                                    <div class="d-flex">
                                        <div class="d-flex flex-column flex-grow-1 text-center">
                                            <!-- 大小 -->
                                            <div class="d-flex flex-column">
                                                <div class="d-flex">
                                                    <div class="flex-fill border-2 border-end-jasper bg-success rounded-start-top-10">
                                                        <span class="d-block py-2 border-solid border-xs-2 border-sm-4 border-end-0 border-start-0 border-bottom-0 border-white rounded-start-top-10 text-danger text-shadow-white">大</span>
                                                    </div>
                                                    <div class="flex-fill" style="background:radial-gradient(circle at 14rem -1.4rem,transparent 5.3rem,white 5.3rem, white 5.46rem,#198754 0)">
                                                        <div class="d-flex flex-column justify-content-center h-100 position-relative">
                                                            <div class="bg-white position-absolute top-0" style="height:4px;width:8.9rem"></div>
                                                            <span class="text-info text-shadow-white">小</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- 赔率 -->
                                                <div class="fs-6 lh-1 bg-jasper">
                                                    <span class="small">1:1</span>
                                                </div>
                                            </div>
                                            <!-- 二围 -->
                                            <div class="d-flex flex-grow-1 align-items-stretch small bg-success">
                                                <div class="d-flex justify-content-center align-items-center flex-fill border-2 border-end-jasper px-1 py-2">
                                                    <i class="bi bi-dice-6 text-info me-1"></i>
                                                    <i class="bi bi-dice-6 text-info"></i>
                                                </div>
                                                <div class="d-flex justify-content-center align-items-center flex-fill border-2 border-end-jasper px-1 py-2">
                                                    <i class="bi bi-dice-5 text-info me-1"></i>
                                                    <i class="bi bi-dice-5 text-info"></i>
                                                </div>
                                                <div class="d-flex justify-content-center align-items-center flex-fill border-2 border-end-jasper px-1 py-2">
                                                    <i class="bi bi-dice-4 text-danger text-opacity-75 me-1"></i>
                                                    <i class="bi bi-dice-4 text-danger text-opacity-75"></i>
                                                </div>
                                                <div class="d-flex justify-content-center align-items-center flex-fill border-2 border-end-jasper px-1 py-2">
                                                    <i class="bi bi-dice-3 text-info me-1"></i>
                                                    <i class="bi bi-dice-3 text-info"></i>
                                                </div>
                                                <div class="d-flex justify-content-center align-items-center flex-fill border-2 border-end-jasper px-1 py-2">
                                                    <i class="bi bi-dice-2 text-info me-1"></i>
                                                    <i class="bi bi-dice-2 text-info"></i>
                                                </div>
                                                <div class="d-flex justify-content-center align-items-center flex-fill border-2 border-end-jasper px-1 py-2">
                                                    <i class="bi bi-dice-1 text-danger text-opacity-75 me-1"></i>
                                                    <i class="bi bi-dice-1 text-danger text-opacity-75"></i>
                                                </div>
                                            </div>
                                            <!-- 赔率 -->
                                            <div class="fs-6 lh-1 bg-jasper">
                                                <span class="small">1:8</span>
                                            </div>
                                        </div>
                                        <div class="d-flex flex-column justify-content-end text-center" style="background: radial-gradient(circle at 50% -10.6rem,transparent 15rem,white 15rem, white 15.2rem,#198754 0);">
                                            <div class="d-flex px-2">
                                                <div class="d-flex fs-6">
                                                    <div class="d-flex flex-column">
                                                        <i class="d-flex bi bi-dice-6 text-info mb-1"></i>
                                                        <i class="d-flex bi bi-dice-6 text-info mb-1"></i>
                                                        <i class="d-flex bi bi-dice-6 text-info mb-1"></i>
                                                    </div>
                                                    <div class="d-flex flex-column px-1 justify-content-center">
                                                        <i class="d-flex bi bi-dice-5 text-info mb-1"></i>
                                                        <i class="d-flex bi bi-dice-5 text-info mb-1"></i>
                                                        <i class="d-flex bi bi-dice-5 text-info mb-1"></i>
                                                    </div>
                                                    <div class="d-flex flex-column justify-content-end">
                                                        <i class="d-flex bi bi-dice-4 text-danger text-opacity-75 mb-1"></i>
                                                        <i class="d-flex bi bi-dice-4 text-danger text-opacity-75 mb-1"></i>
                                                        <i class="d-flex bi bi-dice-4 text-danger text-opacity-75 mb-1"></i>
                                                    </div>
                                                </div>
                                                <div class="d-flex flex-column justify-content-center py-3 fs-5 text-vertical">全围</div>
                                                <div class="d-flex fs-6">
                                                    <div class="d-flex flex-column justify-content-end">
                                                        <i class="d-flex bi bi-dice-3 text-info mb-1"></i>
                                                        <i class="d-flex bi bi-dice-3 text-info mb-1"></i>
                                                        <i class="d-flex bi bi-dice-3 text-info mb-1"></i>
                                                    </div>
                                                    <div class="d-flex flex-column px-1 justify-content-center">
                                                        <i class="d-flex bi bi-dice-2 text-info mb-1"></i>
                                                        <i class="d-flex bi bi-dice-2 text-info mb-1"></i>
                                                        <i class="d-flex bi bi-dice-2 text-info mb-1"></i>
                                                    </div>
                                                    <div class="d-flex flex-column">
                                                        <i class="d-flex bi bi-dice-1 text-danger text-opacity-75 mb-1"></i>
                                                        <i class="d-flex bi bi-dice-1 text-danger text-opacity-75 mb-1"></i>
                                                        <i class="d-flex bi bi-dice-1 text-danger text-opacity-75 mb-1"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="fs-6 lh-1 bg-jasper">
                                                <span class="small">1:30</span>
                                            </div>
                                        </div>
                                        <div class="d-flex flex-column flex-grow-1 text-center">
                                            <!-- 单双 -->
                                            <div class="d-flex flex-column">
                                                <div class="d-flex">
                                                    <div class="flex-fill border-2 border-end-jasper" style="background:radial-gradient(circle at -2rem -1.4rem,transparent 5.3rem,white 5.3rem, white 5.46rem,#198754 0)">
                                                        <div class="d-flex flex-column justify-content-center h-100 position-relative">
                                                            <div class="bg-white position-absolute top-0 end-0" style="height:4px;width:8.9rem"></div>
                                                            <span class="text-info text-shadow-white">单</span>
                                                        </div>
                                                    </div>
                                                    <div class="flex-fill bg-success rounded-end-top-10">
                                                        <span class="d-block py-2 border-solid border-xs-2 border-sm-4 border-end-0 border-start-0 border-bottom-0 border-white rounded-end-top-10 text-danger text-shadow-white">双</span>
                                                    </div>
                                                </div>
                                                <!-- 赔率 -->
                                                <div class="fs-6 lh-1 bg-jasper">
                                                    <span class="small">1:1</span>
                                                </div>
                                            </div>
                                            <!-- 三围 -->
                                            <div class="d-flex flex-grow-1 align-items-stretch small bg-success">
                                                <div class="d-flex flex-column justify-content-center flex-fill border-2 border-start-jasper px-1 py-2">
                                                    <div class="d-flex justify-content-center mb-1">
                                                        <i class="d-flex bi bi-dice-6 text-info"></i>
                                                    </div>
                                                    <div class="d-flex">
                                                        <i class="d-flex bi bi-dice-6 text-info me-1"></i>
                                                        <i class="d-flex bi bi-dice-6 text-info"></i>
                                                    </div>
                                                </div>
                                                <div class="d-flex flex-column justify-content-center flex-fill border-2 border-start-jasper px-1 py-2">
                                                    <div class="d-flex justify-content-center mb-1">
                                                        <i class="d-flex bi bi-dice-5 text-info"></i>
                                                    </div>
                                                    <div class="d-flex">
                                                        <i class="d-flex bi bi-dice-5 text-info me-1"></i>
                                                        <i class="d-flex bi bi-dice-5 text-info"></i>
                                                    </div>
                                                </div>
                                                <div class="d-flex flex-column justify-content-center flex-fill border-2 border-start-jasper px-1 py-2">
                                                    <div class="d-flex justify-content-center mb-1">
                                                        <i class="d-flex bi bi-dice-4 text-danger text-opacity-75"></i>
                                                    </div>
                                                    <div class="d-flex">
                                                        <i class="d-flex bi bi-dice-4 text-danger text-opacity-75 me-1"></i>
                                                        <i class="d-flex bi bi-dice-4 text-danger text-opacity-75"></i>
                                                    </div>
                                                </div>
                                                <div class="d-flex flex-column justify-content-center flex-fill border-2 border-start-jasper px-1 py-2">
                                                    <div class="d-flex justify-content-center mb-1">
                                                        <i class="d-flex bi bi-dice-3 text-info"></i>
                                                    </div>
                                                    <div class="d-flex">
                                                        <i class="d-flex bi bi-dice-3 text-info me-1"></i>
                                                        <i class="d-flex bi bi-dice-3 text-info"></i>
                                                    </div>
                                                </div>
                                                <div class="d-flex flex-column justify-content-center flex-fill border-2 border-start-jasper px-1 py-2">
                                                    <div class="d-flex justify-content-center mb-1">
                                                        <i class="d-flex bi bi-dice-2 text-info"></i>
                                                    </div>
                                                    <div class="d-flex">
                                                        <i class="d-flex bi bi-dice-2 text-info me-1"></i>
                                                        <i class="d-flex bi bi-dice-2 text-info"></i>
                                                    </div>
                                                </div>
                                                <div class="d-flex flex-column justify-content-center flex-fill border-2 border-start-jasper px-1 py-2">
                                                    <div class="d-flex justify-content-center mb-1">
                                                        <i class="d-flex bi bi-dice-1 text-danger text-opacity-75"></i>
                                                    </div>
                                                    <div class="d-flex">
                                                        <i class="d-flex bi bi-dice-1 text-danger text-opacity-75 me-1"></i>
                                                        <i class="d-flex bi bi-dice-1 text-danger text-opacity-75"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- 赔率 -->
                                            <div class="fs-6 lh-1 bg-jasper">
                                                <span class="small">1:180</span>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- 数字行 -->
                                    <div class="d-flex flex-column text-center bg-success">
                                        <div class="d-flex">
                                            <div class="d-flex flex-column col" :class="{'border-2 border-end-jasper':i!=13}" v-for="(item,i) in [17,16,15,14,13,12,11,10,9,8,7,6,5,4]" :key="i">
                                                <span>{{item}}</span>
                                            </div>
                                        </div>
                                        <div class="d-flex fs-6 lh-1 bg-jasper">
                                            <span class="small col" v-for="item in ['1:60','1:30','1:18','1:12','1:8','1:6','1:6','1:6','1:6','1:8','1:12','1:18','1:30','1:60']" :key="item">{{item}}</span>
                                        </div>
                                    </div>
                                    <!-- 二混搭 -->
                                    <div class="d-flex flex-column bg-success">
                                        <div class="d-flex">
                                            <div class="d-flex flex-fill justify-content-center align-items-center border-2 border-end-jasper py-2 px-1">
                                                <i class="d-flex bi bi-dice-6 text-info small me-1"></i>
                                                <i class="d-flex bi bi-dice-5 text-info small"></i>
                                            </div>
                                            <div class="d-flex flex-fill justify-content-center align-items-center border-2 border-end-jasper py-2 px-1">
                                                <i class="d-flex bi bi-dice-6 text-info small me-1"></i>
                                                <i class="d-flex bi bi-dice-4 text-danger text-opacity-75 small"></i>
                                            </div>
                                            <div class="d-flex flex-fill justify-content-center align-items-center border-2 border-end-jasper py-2 px-1">
                                                <i class="d-flex bi bi-dice-6 text-info small me-1"></i>
                                                <i class="d-flex bi bi-dice-3 text-info small"></i>
                                            </div>
                                            <div class="d-flex flex-fill justify-content-center align-items-center border-2 border-end-jasper py-2 px-1">
                                                <i class="d-flex bi bi-dice-6 text-info small me-1"></i>
                                                <i class="d-flex bi bi-dice-2 text-info small"></i>
                                            </div>
                                            <div class="d-flex flex-fill justify-content-center align-items-center border-2 border-end-jasper py-2 px-1">
                                                <i class="d-flex bi bi-dice-6 text-info small me-1"></i>
                                                <i class="d-flex bi bi-dice-1 text-danger text-opacity-75 small"></i>
                                            </div>
                                            <div class="d-flex flex-fill justify-content-center align-items-center border-2 border-end-jasper py-2 px-1">
                                                <i class="d-flex bi bi-dice-5 text-info small me-1"></i>
                                                <i class="d-flex bi bi-dice-4 text-danger text-opacity-75 small"></i>
                                            </div>
                                            <div class="d-flex flex-fill justify-content-center align-items-center border-2 border-end-jasper py-2 px-1">
                                                <i class="d-flex bi bi-dice-5 text-info small me-1"></i>
                                                <i class="d-flex bi bi-dice-3 text-info small"></i>
                                            </div>
                                            <div class="d-flex flex-fill justify-content-center align-items-center border-2 border-end-jasper py-2 px-1">
                                                <i class="d-flex bi bi-dice-5 text-info small me-1"></i>
                                                <i class="d-flex bi bi-dice-2 text-info small"></i>
                                            </div>
                                            <div class="d-flex flex-fill justify-content-center align-items-center border-2 border-end-jasper py-2 px-1">
                                                <i class="d-flex bi bi-dice-5 text-info small me-1"></i>
                                                <i class="d-flex bi bi-dice-1 text-danger text-opacity-75 small"></i>
                                            </div>
                                            <div class="d-flex flex-fill justify-content-center align-items-center border-2 border-end-jasper py-2 px-1">
                                                <i class="d-flex bi bi-dice-4 text-danger text-opacity-75 small me-1"></i>
                                                <i class="d-flex bi bi-dice-3 text-info small"></i>
                                            </div>
                                            <div class="d-flex flex-fill justify-content-center align-items-center border-2 border-end-jasper py-2 px-1">
                                                <i class="d-flex bi bi-dice-4 text-danger text-opacity-75 small me-1"></i>
                                                <i class="d-flex bi bi-dice-2 text-info small"></i>
                                            </div>
                                            <div class="d-flex flex-fill justify-content-center align-items-center border-2 border-end-jasper py-2 px-1">
                                                <i class="d-flex bi bi-dice-4 text-danger text-opacity-75 small me-1"></i>
                                                <i class="d-flex bi bi-dice-1 text-danger text-opacity-75 small"></i>
                                            </div>
                                            <div class="d-flex flex-fill justify-content-center align-items-center border-2 border-end-jasper py-2 px-1">
                                                <i class="d-flex bi bi-dice-3 text-info small me-1"></i>
                                                <i class="d-flex bi bi-dice-2 text-info small"></i>
                                            </div>
                                            <div class="d-flex flex-fill justify-content-center align-items-center border-2 border-end-jasper py-2 px-1">
                                                <i class="d-flex bi bi-dice-3 text-info small me-1"></i>
                                                <i class="d-flex bi bi-dice-1 text-danger text-opacity-75 small"></i>
                                            </div>
                                            <div class="d-flex flex-fill justify-content-center align-items-center border-2 py-2 px-1">
                                                <i class="d-flex bi bi-dice-2 text-info small me-1"></i>
                                                <i class="d-flex bi bi-dice-1 text-danger text-opacity-75 small"></i>
                                            </div>
                                        </div>
                                        <div class="fs-6 lh-1 text-center bg-jasper">
                                            <span class="small">1:5</span>
                                        </div>
                                    </div>
                                    <!-- 单个 -->
                                    <div class="d-flex flex-column">
                                        <div class="d-flex bg-success">
                                            <div class="flex-column flex-fill align-items-center px-5 border-2 border-end-jasper py-2">
                                                <i class="d-flex justify-content-center bi bi-dice-6 text-info small"></i>
                                            </div>
                                            <div class="flex-column flex-fill align-items-center px-5 border-2 border-end-jasper py-2">
                                                <i class="d-flex justify-content-center bi bi-dice-5 text-info small"></i>
                                            </div>
                                            <div class="flex-column flex-fill align-items-center px-5 border-2 border-end-jasper py-2">
                                                <i class="d-flex justify-content-center bi bi-dice-4 text-danger text-opacity-75 small"></i>
                                            </div>
                                            <div class="flex-column flex-fill align-items-center px-5 border-2 border-end-jasper py-2">
                                                <i class="d-flex justify-content-center bi bi-dice-3 text-info small"></i>
                                            </div>
                                            <div class="flex-column flex-fill align-items-center px-5 border-2 border-end-jasper py-2">
                                                <i class="d-flex justify-content-center bi bi-dice-2 text-info small"></i>
                                            </div>
                                            <div class="flex-column flex-fill align-items-center px-5 py-2">
                                                <i class="d-flex justify-content-center bi bi-dice-1 text-danger text-opacity-75 small"></i>
                                            </div>
                                        </div>
                                        <div class="d-flex lh-1 fs-6 bg-jasper rounded-end-bottom-10 rounded-start-bottom-10 overflow-hidden">
                                            <span class="small text-center flex-fill">单骰：1:1</span>
                                            <span class="small text-center flex-fill">双骰：1:2</span>
                                            <span class="small text-center flex-fill">全骰：1:3</span>
                                        </div>
                                    </div>
                                    <!-- 更多筹区 -->
                                    <!-- <div v-if="isShowMoreAmount" class="position-absolute h-100 w-100 bg-dark rounded-10 d-flex flex-sm-column bg-opacity-75 flex-wrap justify-content-between align-items-center text-black fs-6 p-3 p-sm-0">
                                        <div v-for="i in amountList" :key="i" :class="{active:currAmountList.indexOf(i)!=-1}" @click="updateAmount(i)" class="position-relative ms-3 me-3 ms-sm-0 me-sm-1 chips rounded-circle" role="button">
                                            <img class="w-3rem rounded-circle chips-shadow" :src="require('@/assets/images/room/chips_'+i+'.png')" alt="">
                                            <span class="position-absolute start-50 top-50 fw-bold chips-text text-nowrap">{{$root.chipsTxtConvert(i)}}</span>
                                        </div>
                                    </div> -->
                                </div>
                                <!-- 按钮区 -->
                                <div class="d-flex flex-sm-column justify-content-end text-nowrap order-3 mb-1 mb-sm-0">
                                    <button class="btn btn-warning bg-gradient text-white btn-shadow-right mb-sm-1" :class="{'disabled':!canClear}" :disabled="!canClear" @click="submitEvent('clear')">{{$t("room.clear")}}</button>
                                    <button class="btn btn-danger bg-gradient btn-shadow-right mb-sm-1" :class="{'disabled':!canCancel}" :disabled="!canCancel" @click="submitEvent('cancel')">{{$t("room.cancel")}}</button>
                                    <button class="btn btn-success bg-gradient btn-shadow-right" :class="{'disabled':!canConfirm}" :disabled="!canConfirm" @click="submitEvent('confirm')">{{$t("room.confirm")}}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- 池额 -->
                    <div class="position-absolute top-0 end-0 d-flex flex-column bg-dark text-light bg-opacity-50 px-3 py-1 rounded me-2 mt-2 mt-sm-5 fw-bold index-1">
                        <div class="text-center border-bottom">{{$t("video.pool")}}</div>
                        <div class="d-flex text-end small justify-content-between">
                            <div class="d-flex flex-column me-3 ms-2">
                                <span :style="'color:'+color.red">{{$t("common.big")}}</span>
                                <span :style="'color:'+color.green">{{$t("common.small")}}</span>
                                <span :style="'color:'+color.blue">{{$t("common.odd")}}</span>
                                <span :style="'color:'+color.blue">{{$t("common.even")}}</span>
                            </div>
                            <div class="d-flex flex-column me-2">
                                <span :style="'color:'+color.red">0</span>
                                <span :style="'color:'+color.green">100</span>
                                <span :style="'color:'+color.blue">0</span>
                                <span :style="'color:'+color.blue">0</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 路单区 -->
            <div class="flex-grow-1 flex-sm-grow-0 h-sm-8rem position-relative">
                <canvas id="pearl" class="bg-white d-block min-w-100 min-h-100 mw-100 mh-100" v-set-size width="0" height="0" @mousemove.stop="showPoker($event,1)"></canvas>
                <div class="bg-dark bg-opacity-25 position-absolute" :style="pearlLayerStyle" @mouseout.stop="showPoker($event,0)"></div>
            </div>
            <!-- 换台区 -->
            <change-room :class="{'me-sm-0':roomInfo.isAr==1}" :game-list="gameList" :room-list="roomList" :count-list="countList" :table-id="roomInfo.id" :active-game="activeGame" :user-info="userInfo" :pearl-obj="pearlObj" @change-room-list="changeRoomList" @go-room="goRoom" @get-room-pearl="getRoomPearl"></change-room>
            <!-- 音效 -->
            <audio-player v-show="false" ref="startAudioPlayer" :isLoop="true" :isAutoPlayNext="false" :muted="!audio.startAudio" :audio-list="startAudioList.map(elm => elm.url)" />
            <audio-player v-show="false" ref="endAudioPlayer" :isLoop="true" :isAutoPlayNext="false" :muted="!audio.startAudio" :audio-list="endAudioList.map(elm => elm.url)" />
            <audio-player v-show="false" ref="tenAudioPlayer" :isLoop="true" :isAutoPlayNext="false" :muted="!audio.tenAudio" :audio-list="tenAudioList.map(elm => elm.url)" />
            <!-- 弹出框 -->
            <modal :user-info="userInfo" :audio="audio" :curr-modal="currModal" :video-line-list="videoLineList" 
            :curr-video-line="currVideoLine" :recharge-funds-type="rechargeFundsType" :currency-list="currencyList" :recharge-result="rechargeResult"
            :withdraw-funds-type="withdrawFundsType" :source-alipay-list="sourceAlipayList" :source-bank-list="sourceBankList" :source-coin-list="sourceCoinList"
            :withdraw-limit="withdrawLimit" :withdraw-result="withdrawResult" :updatepwd-result="updatepwdResult" :qr-text="qrText" :problem-list="problemList"
            :addquestion-result="addquestionResult" :setuserchannel-result="setuserchannelResult" :deluserchannel-result="deluserchannelResult"
            :getbetlog-data="getbetlogData" :getrecharge-data="getrechargeData" :getwithdraw-data="getwithdrawData"
            @close-modal="currModal=''" @set-user-info="v=>$emit('set-user-info',v)"></modal>
        </div>
    </div>
</template>
<script>
    import {COMMAND,GAME_ROW,COLOR,LAYER_TYPE,GAME_ID,RESULT,SUBMIT_RESULT,CANCEL_RESULT,RATIO,AMOUTN_ARR} from "@/assets/js/config.js"
    import mediaVideo from "@/components/public/video.vue";
    import navbarVertical from '@/components/public/navbarVertical.vue';
    import navbar from '@/components/public/navbar.vue';
    import modal from '@/components/public/modal.vue';
    import notice from '@/components/public/notice.vue';
    import changeRoom from '@/components/public/changeRoom.vue'
    import { useLayer } from '@/assets/js/useLayer';
    export default{
        components:{
            mediaVideo,
            changeRoom,
            navbarVertical,
            navbar,
            modal,notice
        },
        props:{
            currVideoLine:"",//当前视频线路id
            userInfo:{
                type:Object,
                default:{
                    name:"",//用户名
                    guid:"",//用户guid
                    realCoin:0,//真人币
                    esportsCoin:0//竞技币
                }
            },
            audio:{
                type:Object,
                default:{
                    bgAudio:true,//背景音效开关
                    startAudio:true,//开始/结束音效开关
                    resultAudio:true,//开注音效
                    tenAudio:true,//最后10秒音效
                }
            },
            videoLineList:{
                type:Array,
                default:[]
            },//视频线路列表
            hasChatMsg:false,//是否有未读的客服消息
            isPageReady:false,//页面是否加载完毕
            isFullscreen:{
                type:Boolean,
                default:false
            },//当前是否全屏
        },
        data(){
            return {
                color:COLOR,//获取颜色配置
                roomInfo:JSON.parse(this.$route.params.roomInfo),//台面信息
                countdown:0,//倒计时
                videoList:[],//视频线路地址，当前支持最多两个
                roomList:[],//切换台面的台面列表
                pearlArr:[],//台面路单列表
                gameList:this.$route.params.gameList,//游戏列表
                countList:[],//路单统计列表
                currentGame:this.$route.params.enterGame,//当前游戏
                activeGame:this.$route.params.enterGame,//当前游戏列表选中项
                enterGame:0,//当前将要进入的游戏
                pearlObj: {
                    id:0,//台面id
                    pearl:[]//路单数据
                },//要重绘路单的对象
                amountList:AMOUTN_ARR,//全部额度列表
                currAmountList:[10,100,1000,10000,100000],//选中的额度列表
                currAmountListSort:[10,100,1000,10000,100000],//排序后的选中额度列表
                amount:10,//选中额度
                canClear:false,//是否可以点击清除
                canCancel:false,//是否可以点击取消
                canConfirm:false,//是否可以点击确定
                startAudioList:[
                    {
                        url: require('@/assets/media/common/bet_start_1.mp3')
                    },
                    {
                        url: require('@/assets/media/common/bet_start_2.mp3')
                    },
                    {
                        url: require('@/assets/media/common/bet_start_3.mp3')
                    },
                    {
                        url: require('@/assets/media/common/bet_start_4.mp3')
                    },
                    {
                        url: require('@/assets/media/common/bet_start_5.mp3')
                    },
                    {
                        url: require('@/assets/media/common/bet_start_6.mp3')
                    },
                ],//开始音效
                endAudioList:[
                    {
                        url:require('@/assets/media/common/bet_stop.mp3')
                    }
                ],//结束音效
                tenAudioList:[
                    {
                        url:require('@/assets/media/common/time_ten_1.mp3')
                    },
                    {
                        url:require('@/assets/media/common/time_ten_2.mp3')
                    },
                    {
                        url:require('@/assets/media/common/time_ten_3.mp3')
                    }
                ],//最后10秒音效
                isShowMoreAmount:false,//是否显示更多筹码
                pearlLayerStyle:{
                    width:0,
                    height:0,
                    left:0,
                    top:0,
                    display:'none'
                },//鼠标移上路单的遮罩层
                point:[0,0],//鼠标在路单上的坐标点
                isScale:false,//是否放大提交区和视频区
                isShowPoker:true,//鼠标移入画布是否显示牌
                canvasObj:null,//画布对象
                isMultiple:false,//是否显示多台
                notice:"",//公告内容
                currModal:"",//当前显示模态框

                rechargeFundsType:[],//充值支付方式列表
                currencyList:[],//充值币种列表
                rechargeResult:{},//充值返回对象
                withdrawFundsType:[],//提现提现方式列表
                sourceAlipayList:[],//支付宝资金渠道
                sourceBankList:[],//银联资金渠道
                sourceCoinList:[],//虚拟币资金渠道
                withdrawLimit:{
                    min:0,
                    max:0
                },//提现限额
                withdrawResult:{},//提现返回对象
                updatepwdResult:{},//修改密码返回对象
                qrText:"",//分享二维码文本
                problemList:[],//问题反馈列表
                addquestionResult:{},//问题反馈返回对象
                setuserchannelResult:{},//操作用户资金方式返回对象
                deluserchannelResult:{},//删除用户资金方式返回对象
                getbetlogData:{},//获取提交记录返回数据
                getrechargeData:{},//获取充值记录返回数据
                getwithdrawData:{},//获取提现记录返回数据
            }
        },
        directives: {
            'set-size': {
                mounted(elem,binding){
                    let _this=binding.instance;
                    //设置画布大小
                    _this.$root.setCanvasSize(elem);
                    //绘制画布表格
                    _this.canvasObj = _this.$root.drawCanvasTable(elem,Object.keys(GAME_ROW).find(key=>GAME_ROW[key].indexOf(Number(_this.currentGame))!=-1),true);
                    //请求数据
                    _this.$emit("ws-send",COMMAND.all_getroompearl, _this.currentGame,_this.roomInfo.id,_this.canvasObj.column,"table", _this.userInfo.name, _this.userInfo.guid);
                }
            },
        },
        mounted() {
            //获取公告
            this.$emit('ws-send', COMMAND.all_getnotice, this.currentGame, this.userInfo.name, this.userInfo.guid);
            //获取台面视频
            if(this.currVideoLine==""){
                useLayer({str:this.$t("video.noVideoLine")});
            }else{
                this.$emit("ws-send",COMMAND.all_getvideo,this.roomInfo.id,this.currVideoLine,this.userInfo.name,this.userInfo.guid);
            }
            //获取倒计时
            this.$emit("ws-send",COMMAND.all_getcountdown,this.currentGame,this.roomInfo.id,this.userInfo.name,this.userInfo.guid);
            //获取赔率
            // this.$emit("ws-send",COMMAND.cor_getodds,this.roomInfo.id,this.userInfo.name,this.userInfo.guid);
            //获取台面列表
            this.$emit("ws-send",COMMAND.all_getroomlist, this.currentGame,this.userInfo.name, this.userInfo.guid);
            //获取限红
            this.$emit("ws-send",COMMAND.dice_getlimitred,this.userInfo.name, this.userInfo.guid);
            //获取各位置已提交额度
            this.$emit("ws-send",COMMAND.dice_getbetdetail,this.roomInfo.id,this.roomInfo.boot,this.roomInfo.innings,this.userInfo.name,this.userInfo.guid);
            
            //获取分享地址
            this.$emit("ws-send",COMMAND.all_getshareurl,this.userInfo.name,this.userInfo.guid);
            //获取用户资金账号
            this.$emit("ws-send",COMMAND.all_getuserchannel,"list",this.userInfo.name,this.userInfo.guid);
            //获取资金方式(充值)
            this.$emit("ws-send",COMMAND.all_getbankrollway,1,this.userInfo.name,this.userInfo.guid);
            //获取资金方式(提现)
            this.$emit("ws-send",COMMAND.all_getbankrollway,2,this.userInfo.name,this.userInfo.guid);
            
            //获取问题反馈
            this.$emit("ws-send",COMMAND.all_getquestion,1,100,this.userInfo.name,this.userInfo.guid);
            //获取提现限额
            this.$emit("ws-send",COMMAND.all_getwithdrawlimit,this.userInfo.name,this.userInfo.guid);
            
            this.setWsMessage();
            addEventListener("resize",this.setResize);
        },
        unmounted() {
            removeEventListener("resize",this.setResize);
        },
        methods: {
            setWsMessage(){
                this.$emit("ws-msg",(data)=>{
                    switch (data.command) {
                        case COMMAND.all_getroompearl:
                            if(data.id==this.roomInfo.id&&data.useto=="table"){//获取的是当前台面的数据
                                this.pearlArr=data.pearl;
                            }else{//用于切换台面获取数据
                                this.pearlObj={
                                    id:data.id,
                                    pearl:data.pearl
                                }
                                this.countList[data.id]=data.count;
                            }
                            break;
                        case COMMAND.all_getvideo:
                            let videoArr=data.video;
                            let list=[];//拼装好的视频地址数组
                            for (let i = 0; i < videoArr.length; i++) {
                                list.push(data.flvline+'/'+videoArr[i]+'.flv');
                            }
                            this.videoList=list;
                            break;
                        case COMMAND.all_getcountdown:
                            this.countdown=data.countdown;
                            break;
                        case COMMAND.all_startgame:
                            if(data.id==this.roomInfo.id){
                                this.countdown=data.countdown;
                                this.roomInfo.boot=data.boot;
                                this.roomInfo.innings=data.innings;
                                this.$refs.startAudioPlayer.playNext();
                            }
                            let item=this.roomList.find(v=>v.id==data.id);//在台面列表中找到与当前开始的游戏匹配的项
                            if(typeof item!="undefined"){//台面存在且可见
                                if(item.id==data.id){//当前开始计时的游戏存在于台面列表中
                                    this.listCountdown(data.id,data.countdown);
                                    item.boot=data.boot;
                                    item.innings=data.innings;
                                }
                            }
                            break;
                        case COMMAND.all_endgame:
                            if(data.id==this.roomInfo.id){
                                this.resetAmount("submiting");
                                this.countdown=0;
                                this.$refs.endAudioPlayer.playNext();
                            }else{
                                let roomItem=this.roomList.find(v=>v.id==data.id);
                                typeof roomItem!="undefined"&&(roomItem.countdown=0);
                            }
                            break;
                        case COMMAND.all_getroomlist:
                            if(this.$root.isTryUser){
                                this.roomList=data.list.filter(v=>window[USER].tryUserUnTableId.indexOf(Number(v.id))==-1);
                            }else{
                                this.roomList=data.list;
                            }
                            for (let item of this.roomList) {
                                this.listCountdown(item.id,item.countdown);
                            }
                            break;
                        case COMMAND.all_entertable:
                            if(data.result!=RESULT.enterTable.success){
                                useLayer({str:this.$t("home.goRoomErr")});
                                return;
                            }
                            if(this.activeGame==GAME_ID.dice){//与当前游戏一致则只修改参数
                                this.$refs.startAudioPlayer.pause();
                                this.$refs.endAudioPlayer.pause();
                                this.$refs.tenAudioPlayer.pause();
                                //更改台面信息
                                this.roomInfo.id=data.id;
                                this.roomInfo.room=data.room;
                                this.roomInfo.table=data.table;
                                this.roomInfo.boot=data.boot;
                                this.roomInfo.innings=data.innings;
                                this.roomInfo.isRebate=data.iscommission;
                                this.roomInfo.second=this.roomList.find(v=>v.id==data.id).second;
                                this.roomInfo.isAr=this.roomList.find(v=>v.id==data.id).isar;
                                this.countdown=this.roomList.find(v=>v.id==data.id).countdown;
                                this.currentGame=this.enterGame;
                                this.showPokerArr=[];
                                this.coruscateArr=[];
                                this.pokerArr=[];
                                //获取台面路单
                                this.$emit("ws-send",COMMAND.all_getroompearl, this.enterGame,data.id,this.canvasObj.column,"table", this.userInfo.name, this.userInfo.guid);
                                //获取台面视频
                                if(this.currVideoLine==""){
                                    useLayer({str:this.$t("video.noVideoLine")});
                                }else{
                                    this.$emit("ws-send",COMMAND.all_getvideo,this.roomInfo.id,this.currVideoLine,this.userInfo.name,this.userInfo.guid);
                                }
                                //获取各位置已提交额度
                                this.$emit("ws-send",COMMAND.cor_getbetdetail,this.roomInfo.id,this.roomInfo.boot,this.roomInfo.innings,this.userInfo.name,this.userInfo.guid);
                                //获取赔率
                                this.$emit("ws-send",COMMAND.cor_getodds,this.roomInfo.id,this.userInfo.name,this.userInfo.guid);
                                //获取台面扑克
                                this.$emit("ws-send",COMMAND.cor_getroompoker, this.roomInfo.id,this.roomInfo.boot,this.canvasObj.column,this.userInfo.name, this.userInfo.guid);
                            }else{//不一致则进行页面跳转
                                this.$root.enterTable(this.activeGame,{
                                    guid:this.guid,
                                    roomInfo:JSON.stringify({
                                        id:data.id,
                                        room:data.room,
                                        table:data.table,
                                        boot:data.boot,
                                        innings:data.innings,
                                        isRebate:data.iscommission,
                                        second:data.second,
                                        isAr:this.roomList.find(v=>v.id==data.id).isar
                                    }),
                                    enterGame:this.enterGame,
                                    gameList:this.gameList,
                                })
                            }
                            break;
                        case COMMAND.all_getnotice:
                            this.notice=data.str;
                            break;
                        case COMMAND.all_workerupdate:
                            let room=this.roomList.find(v=>v.id==data.table);//在台面列表中找到与当前开始的游戏匹配的项
                            if(typeof room!="undefined"){//台面存在且可见
                                if(room.id==data.table){//当前变更工作者的台面存在于台面列表中
                                    room.workerimg=data.img;
                                    room.worker=data.name;
                                }
                            }
                            case COMMAND.all_getbankrollway:
                            if(data.type==1){//充值
                                this.rechargeFundsType=data.list;
                            }else if(data.type==2){//提现
                                this.withdrawFundsType=data.list;
                            }
                            break;
                        case COMMAND.all_getcoin:
                            this.currencyList=data.list;
                            break;
                        case COMMAND.all_recharge:
                            this.rechargeResult=data;
                            break;
                        case COMMAND.all_getuserchannel:
                            this.sourceAlipayList=data.alipay;
                            this.sourceBankList=data.bank;
                            this.sourceCoinList=data.coin;
                            break;
                        case COMMAND.all_getwithdrawlimit:
                            this.withdrawLimit.min=data.min;
                            this.withdrawLimit.max=data.max;
                            break;
                        case COMMAND.all_withdraw:
                            this.withdrawResult=data;
                            break;
                        case COMMAND.all_updatepwd:
                            this.updatepwdResult=data;
                            break;
                        case COMMAND.all_getshareurl:
                            this.qrText=data.url;
                            break;
                        case COMMAND.all_getquestion:
                            this.problemList=data.list.reverse();
                            break;
                        case COMMAND.all_addquestion:
                            this.addquestionResult=data;
                            break;
                        case COMMAND.all_setuserchannel:
                            this.setuserchannelResult=data;
                            break;
                        case COMMAND.all_deluserchannel:
                            this.deluserchannelResult=data;
                            break;
                        case COMMAND.all_getbetlog:
                            this.getbetlogData=data;
                            break;
                        case COMMAND.all_getrecharge:
                            this.getrechargeData=data;
                            break;
                        case COMMAND.all_getwithdraw:
                            this.getwithdrawData=data;
                            break;
                        break;
                    }
                });
            },
            /**
             * 提交事件
             */
            submitEvent(event){
                
            },
            /**
             * 修改常用额度
             */
            updateAmount(amount){
                let index=this.currAmountList.indexOf(amount);
                if(index==-1){//当前点击额度未选中
                    this.currAmountList.shift();//去掉最先加入的额度
                    this.currAmountList.push(amount);//加入点击项
                }
                this.currAmountListSort=this.currAmountList.slice();
                this.currAmountListSort.sort((a,b)=>a-b);
                this.amount=this.currAmountListSort[0];//设置额度为排序后的额度的第一项
            },
            /**
             * 显示牌
             * @param {Number} type 移入或移出
             */
            showPoker(e,type){
                if(type==1){//移入
                    if(this.pearlLayerStyle.width!=0){
                        let cellWidth=this.pearlLayerStyle.width.replace('px','');
                        let cellHeight=this.pearlLayerStyle.height.replace('px','');
                        let column=(getComputedStyle(e.target).width.replace('px','')/cellWidth).toFixed()-1;//获取总列数减去一列标题列
                        let x = Math.ceil(e.offsetX / cellWidth)-1;//列
                        let y = Math.ceil(e.offsetY / cellHeight);//行
                        let pokerArr=this.pokerArr.slice(-column);
                        let poker=pokerArr[x-1];//获取对应位置的扑克
                        if((x!=this.point[0])&&y!=0&&x>0&&x<=column&&poker&&this.isShowPoker){//坐标点相比于上次有不同并且大于0行0列并且对应位置有扑克数据
                            this.point=[x,y];
                            this.$nextTick(()=>{
                                this.pearlLayerStyle.display='block';
                                this.pearlLayerStyle.left=x*cellWidth+'px';
                                this.pearlLayerStyle.top=(y-1)*cellHeight+'px';
                                this.showPokerArr=poker;
                                this.showPokerTxtArr=this.resultToTxt(this.pearlArr.slice(-column)[x-1]);
                            })
                        }
                    }
                }else{//移出
                    this.showPokerArr=[];
                    this.showPokerTxtArr=[];
                    this.pearlLayerStyle.display='none';
                    this.point=[0,0];
                }
            },
            /**
             * 改变台面列表
             */
            changeRoomList(gameId){
                this.activeGame=gameId;
                //获取台面列表
                this.$emit("ws-send",COMMAND.all_getroomlist, gameId,this.userInfo.name, this.userInfo.guid);
            },
            /**
             * 设置窗口大小改变
             */
            setResize(){
                this.drawPearl(this.pearlArr);
            },
            /**
             * 绘制路单
             */
            drawPearl(pearlArr){
                let elem=document.getElementById("pearl");
                //设置画布大小
                this.$root.setCanvasSize(elem);
                //绘制画布表格
                let obj = this.$root.drawCanvasTable(elem,Object.keys(GAME_ROW).find(key=>GAME_ROW[key].indexOf(Number(this.currentGame))!=-1),true);
                if(obj){//判断对象是否存在，防止视口被拉小到没有的时候找不到而报错
                    this.pearlLayerStyle.width=obj.cellWidth/RATIO+'px';
                    this.pearlLayerStyle.height=(obj.cellHeight/RATIO)*4+'px';
                    this.pearlLayerStyle.left=obj.cellWidth/RATIO+'px';
                    this.$root.drawPearl(obj,pearlArr.slice(-obj.column),null,this.currentGame);
                }
            },
            /**
             * 进入台面
             */
            goRoom(id,gameId){
                if(id==this.roomInfo.id){
                    useLayer({str:this.$t("roomlist.hearTip")});
                    return;
                }else{
                    //进入房间
                    this.enterGame=gameId;
                    this.$emit("ws-send",COMMAND.all_entertable, gameId, id,this.userInfo.name, this.userInfo.guid);
                }
            },
            /**
             * 获取台面路单
             * @param {Number} tableId 台面id
             * @param {Number} column 获取的数据列数
             */
            getRoomPearl(tableId,column){
                this.$emit("ws-send",COMMAND.all_getroompearl, this.activeGame,tableId,this.activeGame==GAME_ID.bac?200:column,"list", this.userInfo.name, this.userInfo.guid);
            },
            /**
             * 倒计时
             * @param {Number} tableId 要计时的台面id
             * @param {Number} countdown 计时起始值
             */
            listCountdown(tableId,countdown){
                let room=this.roomList.find(v=>v.id==tableId);
                room.countdown=countdown;
                var timer = setInterval(()=>{
                    if(room.countdown!=countdown){//两个值不一样说明台面计时被重新赋值了，应当停掉定时器
                        clearInterval(timer);
                        return;
                    }
                    if(room.countdown <= 0){
                        room.countdown=0;
                        clearInterval(timer);
                        return;
                    }
                    room.countdown--;
                    countdown--;
                }, 1000);
            },
        },
        watch: {
            /**
             * 监听路单数据变化
             */
            pearlArr:{
                handler(newVal){
                    this.drawPearl(newVal);
                },
                deep:true//深度监听
            },
            /**
             * 监听视频线路id改变
             */
            currVideoLine(newVal){
                if(newVal!=""){
                    //获取台面视频
                    this.$emit("ws-send",COMMAND.all_getvideo,this.roomInfo.id,newVal,this.userInfo.name,this.userInfo.guid);
                }else{
                    this.videoList=null;
                }
            },
            /**
             * 监听倒计时改变
             */
            countdown(newVal){
                newVal>=0&&this.isScale==false&&(this.isScale=true);
                //一旦倒计时大于0，则每秒减1
                newVal==10&&this.$refs.tenAudioPlayer.playNext();
                if(newVal>0){
                    setTimeout(()=>{
                        this.countdown--;
                        if(this.countdown<=0){
                            this.countdown=0;
                            this.$nextTick(()=>{
                                this.isScale=false;
                            })
                        }
                    }, 1000);
                }
            },
        },
    }
</script>