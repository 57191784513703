<style scoped>
    .sibbon{
        left: 16%;
        bottom: -38%;
        transform: translate(-50%,0) rotateZ(-45deg);
    }
    .ray-hover::before{
        position: absolute;
        content: '';
        display: inline-block;
        background-color: #fff;
        z-index: 1;
        left: 0;
        width: 20%;
        height: 200%;
        transform: scale(0);
    }
    .ray-hover:hover::before{
        animation: ray-hover 2.5s linear;
        animation-delay: -1.6s;
    }
    @keyframes ray-hover{
        0% { transform: scale(0) rotate(-45deg); opacity: 0; }
        80% { transform: scale(0) rotate(-45deg); opacity: 0; }
        81% { transform: scale(4) rotate(-45deg); opacity: .8; }
        100% { transform: scale(40) rotate(-45deg); opacity: 0; }
    }
    .scroll-shadow{
        box-shadow: inset 0 0 .2rem #ededed;
    }
    .text-3d>div{
        background-clip: text;
        grid-area: overlap;
        -webkit-text-stroke: .2rem transparent;
    }
    .text-3d-bg{
        /* background-image: repeating-linear-gradient( 105deg, #ffb338 0% , #3e2904 5%, rgb(233, 233, 0) 12%); */
        background-image: repeating-linear-gradient( 105deg, #ffb338 0% , #3e2904 20%, rgb(233, 233, 0) 40%);
        color: transparent;
        transform: scale(1.05);
        transform-origin: top left;
    }
    .text-3d-fg{
        /* color: transparent; */
        background-image: repeating-linear-gradient( 5deg, #ffb338 0% , #77571d 23%, yellow 31%);
        transform: scale(1);
    }
    .img-cover-top{
        object-fit: cover;
        object-position: top;
    }
    .filter-drop-hover{
        transition: filter .15s linear;
    }
    .bg-ticket-left-outer{
        background: 
            radial-gradient(circle at left top, transparent .8rem, var(--outer-blue) 0) left top / 51% 51% no-repeat,
            radial-gradient(circle at left bottom, transparent .8rem, var(--outer-blue) 0) left bottom / 51% 51% no-repeat,
            radial-gradient(circle at right top, transparent .6rem, var(--outer-blue) 0) right top / 51% 51% no-repeat,
            radial-gradient(circle at right bottom, transparent .6rem, var(--outer-blue) 0) right bottom / 51% 51% no-repeat
    }
    .bg-ticket-left-outer.lh{
        background: 
            radial-gradient(circle at left top, transparent .8rem, var(--outer-red) 0) left top / 51% 51% no-repeat,
            radial-gradient(circle at left bottom, transparent .8rem, var(--outer-red) 0) left bottom / 51% 51% no-repeat,
            radial-gradient(circle at right top, transparent .6rem, var(--outer-red) 0) right top / 51% 51% no-repeat,
            radial-gradient(circle at right bottom, transparent .6rem, var(--outer-red) 0) right bottom / 51% 51% no-repeat
    }
    .bg-ticket-left-inside{
        background: 
            radial-gradient(circle at left top, transparent .6rem, var(--inside-blue) 0) left top / 51% 51% no-repeat,
            radial-gradient(circle at left bottom, transparent .6rem, var(--inside-blue) 0) left bottom / 51% 51% no-repeat,
            radial-gradient(circle at right top, transparent .4rem, var(--inside-blue) 0) right top / 51% 51% no-repeat,
            radial-gradient(circle at right bottom, transparent .4rem, var(--inside-blue) 0) right bottom / 51% 51% no-repeat
    }
    .bg-ticket-left-inside.lh{
        background: 
            radial-gradient(circle at left top, transparent .6rem, var(--inside-red) 0) left top / 51% 51% no-repeat,
            radial-gradient(circle at left bottom, transparent .6rem, var(--inside-red) 0) left bottom / 51% 51% no-repeat,
            radial-gradient(circle at right top, transparent .4rem, var(--inside-red) 0) right top / 51% 51% no-repeat,
            radial-gradient(circle at right bottom, transparent .4rem, var(--inside-red) 0) right bottom / 51% 51% no-repeat
    }
    .bg-ticket-right-outer{
        background: 
            radial-gradient(circle at left top, transparent .6rem, var(--outer-blue) 0) left top / 51% 51% no-repeat,
            radial-gradient(circle at left bottom, transparent .6rem, var(--outer-blue) 0) left bottom / 51% 51% no-repeat,
            radial-gradient(circle at right top, transparent .8rem, var(--outer-blue) 0) right top / 51% 51% no-repeat,
            radial-gradient(circle at right bottom, transparent .8rem, var(--outer-blue) 0) right bottom / 51% 51% no-repeat
    }
    .bg-ticket-right-outer.lh{
        background: 
            radial-gradient(circle at left top, transparent .6rem, var(--outer-red) 0) left top / 51% 51% no-repeat,
            radial-gradient(circle at left bottom, transparent .6rem, var(--outer-red) 0) left bottom / 51% 51% no-repeat,
            radial-gradient(circle at right top, transparent .8rem, var(--outer-red) 0) right top / 51% 51% no-repeat,
            radial-gradient(circle at right bottom, transparent .8rem, var(--outer-red) 0) right bottom / 51% 51% no-repeat
    }
    .bg-ticket-right-inside{
        background: 
            radial-gradient(circle at left top, transparent .4rem, var(--inside-blue) 0) left top / 51% 51% no-repeat,
            radial-gradient(circle at left bottom, transparent .4rem, var(--inside-blue) 0) left bottom / 51% 51% no-repeat,
            radial-gradient(circle at right top, transparent .6rem, var(--inside-blue) 0) right top / 51% 51% no-repeat,
            radial-gradient(circle at right bottom, transparent .6rem, var(--inside-blue) 0) right bottom / 51% 51% no-repeat
    }
    .bg-ticket-right-inside.lh{
        background: 
            radial-gradient(circle at left top, transparent .4rem, var(--inside-red) 0) left top / 51% 51% no-repeat,
            radial-gradient(circle at left bottom, transparent .4rem, var(--inside-red) 0) left bottom / 51% 51% no-repeat,
            radial-gradient(circle at right top, transparent .6rem, var(--inside-red) 0) right top / 51% 51% no-repeat,
            radial-gradient(circle at right bottom, transparent .6rem, var(--inside-red) 0) right bottom / 51% 51% no-repeat
    }
    .bg-ticket-left-outer::before{
        content: '';
        width: .1rem;
        background: linear-gradient(to top,var(--inside-blue) 0%,var(--inside-blue) 50%,transparent 50%) top right/ 1rem 1rem repeat-y;
        position: absolute;
        right: 0;
        top:.5rem;
        bottom: 1rem;
    }
    .bg-ticket-left-outer.lh::before{
        background: linear-gradient(to top,var(--inside-red) 0%,var(--inside-red) 50%,transparent 50%) top right/ 1rem 1rem repeat-y;
    }
    .text-ticket{
        color: var(--outer-blue);
    }
    .lh .text-ticket{
        color: var(--outer-red);
    }
</style>
<!-- 台面列表 -->
<template>
    <div class="w-100 h-100 position-relative d-flex">
        <div class="flex-grow-1 overflow-y-scroll scroll-none">
            <div id="scrollContent" class="d-flex flex-wrap align-content-start pe-2">

                <div v-for="(item,i) in roomList" class="ps-sm-0 mb-1" v-load="i" :class="$attrs.class" :key="item">
                    <div class="rounded-5 position-relative filter-drop-hover scale-hover overflow-hidden ms-2">
                        
                        <!-- 无路布局 -->
                        <div @click="goRoom(item.id,item.game)" class="d-flex justify-content-center align-items-center ray-hover position-relative" v-if="!$root.hasPearl(item['game'])" role="button">
                            <img class="img-fluid w-9rem" :src="getTableImgSrc(item)" alt="">
                            <div class="position-absolute w-100 h-100 rounded-circle overflow-hidden d-flex justify-content-end flex-column">
                                <span v-if="getTableShow(item)" class="bg-dark bg-opacity-50 text-center fw-bold text-light" v-html="getTableShow(item)"></span>
                            </div>
                            <!-- <span v-if="item.tablelimit" class="position-absolute top-0 start-0 fw-bold text-light bg-dark opacity-75 text-center py-1 px-2 rounded-5">{{item.tablelimit}}</span> -->
                        </div>

                        <!-- 有路布局 -->
                        <div v-else @click="goRoom(item.id,item.game)" class="flex-grow-1 d-flex ray-hover" role="button">
                            <!-- 头像 -->
                            <div class="d-flex flex-column text-center position-relative overflow-hidden p-2 bg-ticket-left-outer" :class="{'lh':item.game==gameIdConfig.lh}">
                                <div class="d-flex flex-column bg-ticket-left-inside p-2 min-h-100 position-relative" :class="{'lh':item.game==gameIdConfig.lh}">
                                    <div class="position-relative w-7rem pt-9rem">
                                        <img class="rounded w-100 h-100 img-cover-top position-absolute top-0 start-0" :src="item.workerimg||require('@/assets/images/home/model.jpg')" alt="">
                                        <div v-if="item.worker" class="bg-dark bg-opacity-50 position-absolute bottom-0 start-0 end-0 text-light rounded-bottom small">{{ decodeURI(item.worker)}}</div>
                                    </div>
                                    <div class="position-absolute h-100 text-light text-vertical fw-bold sibbon small bg-gradient text-spacing-_1" :class="item.isar==1?'bg-success':'bg-secondary'">{{item.isar==1?$t("roomlist.new"):$t("roomlist.old")}}</div>
                                </div>
                            </div>
                            <!-- 信息栏 -->
                            <div class="flex-grow-1 w-0 p-2 bg-ticket-right-outer" :class="{'lh':item.game==gameIdConfig.lh}">
                                <div class="h-100 d-flex flex-column bg-ticket-right-inside p-2" :class="{'lh':item.game==gameIdConfig.lh}">
                                    <!-- 信息 -->
                                    <div class="d-flex lh-1 align-items-center">
                                        <div class="d-none d-sm-flex flex-column fw-bold text-warning">
                                            <span>{{item.room}}</span>
                                            <span class="text-center">{{item.table}}</span>
                                        </div>
                                        <div class="flex-grow-1 d-flex flex-column me-2 ms-sm-2 small">
                                            <div class="d-flex border-bottom justify-content-between" style="border-color:#48769d !important;border-bottom-style:dashed !important">
                                                <b class="d-sm-none text-warning">{{item.room}}{{item.table}}</b>
                                                <div class="text-light pb-1">
                                                    <span>{{item.boot}}{{$t("common.boot")}}</span>
                                                    <span>{{item.innings}}{{$t("common.bout")}}</span>
                                                </div>
                                            </div>
                                            <div class="d-flex pt-1">
                                                <div class="d-flex w-0 flex-grow-1 overflow-x-scroll scroll-none" style="-webkit-overflow-scrolling: touch;">
                                                    <div v-if="!countList[item.id]" class="fw-bold d-flex align-items-center small rounded-pill border border-secondary">
                                                        <span class="text-light rounded-circle p-1 small bg-secondary">无</span>
                                                        <span class="flex-grow-1 text-end px-1 px-sm-2">0</span>
                                                    </div>
                                                    <div v-for="(val,i) in countList[item.id]" :key="(val,i)" :style="{'border':'1px solid '+getNameAndColor(item.game,i)['color']}" :class="{'me-1 me-sm-2 ':i<countList[item.id].length-1}" class="flex-fill fw-bold d-flex justify-content-around align-items-center small rounded-pill border-start-0">
                                                        <span class="text-light rounded-circle p-1 small" :style="{'background':getNameAndColor(item.game,i)['color']}">{{getNameAndColor(item.game,i)['name']}}</span>
                                                        <span class="flex-grow-1 text-end pe-1 pe-sm-2" :style="{'color':getNameAndColor(item.game,i)['color']}">{{val}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- <div class="text-3d fs-3 fw-bold d-grid justify-content-center lh-1 text-dark">
                                            <div class="text-3d-bg">{{item.countdown}}</div>
                                            <div class="text-3d-fg">{{item.countdown}}</div>
                                        </div> -->
                                        <div class="fw-bold fs-2 text-shadow-white lh-1 text-ticket">{{item.countdown}}</div>
                                    </div>
                                    <!-- 路子 -->
                                    <div class="flex-grow-1 position-relative h-0 mt-1">
                                        <div v-if="item.rstate&&item.rstate!=0" class="text-spacing-_1 d-flex justify-content-center align-items-center position-absolute w-100 h-100 bg-dark bg-opacity-50 rounded fw-bold fs-4 text-light text-center">
                                            <span>{{$t("roomlist."+roomStateConfig[item.rstate]) }}</span>
                                        </div>
                                        <canvas :data-id="'home_'+item.id" class="bg-light d-block rounded-3" v-set-size="item.id" data-canvas-home width="0" height="0"></canvas>
                                        <div v-if="tableId==item.id" class="position-absolute rounded text-light bg-dark opacity-50 start-50 top-50 translate-middle py-1 px-2">{{$t("roomlist.hear")}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- 加载 -->
                        <div v-if="isReloadData&&!pearlList.find(v=>v.id==item.id)" class="bg-dark bg-opacity-50 position-absolute top-0 bottom-0 start-0 end-0 rounded-5 d-flex flex-column justify-content-center align-items-center">
                            <div class="spinner-border text-info" role="status"></div>
                        </div>

                    </div>
                </div>

            </div>
        </div>
        <!-- 滚动条 -->
        <div class="w-05rem flex-shrink-0 d-flex">
            <div v-if="!isReloadData" v-scroll-load:scrollContent class="w-100 h-100 d-flex rounded-pill scroll-shadow ">
                <div class="w-100 bg-info bg-opacity-75 rounded-pill position-relative progress-bar-striped"></div>
            </div>
        </div>
        <!-- 加载层 -->
        <div v-if="isShowLoad" class="position-absolute top-0 bottom-0 w-100 start-0 d-flex flex-column justify-content-center">
            <div class="spinner-border text-info mx-auto" role="status"></div>
            <div class="text-light text-center mt-2">{{$t("common.load")}}</div>
        </div>
    </div>
</template>
<script>
import {GAME_ID,PEARL_TYPE,GAME_ROW, COLOR,ROOM_STATE, IS_DEBUG} from "@/assets/js/config.js"
export default {
    inheritAttrs: false,
    emits: ['get-room-pearl','go-room','on-ready'],
    props:{
        //台面列表
        roomList:{
            type:Array,
            default(){
                return []
            }
        },
        //路单统计列表
        countList:{
            type:Array,
            default(){
                return []
            }
        },
        //要重绘路单的对象
        pearlObj:{
            id:0,//台面id
            pearl:[]//路单数据
        },
        tableId:0,//台面id，不为0时显示当前位置
        gameId:0,//游戏id
    },
    data() {
        return {
            gameIdConfig:GAME_ID,//获取游戏id配置
            roomStateConfig:ROOM_STATE,//获取台面状态类型配置
            pearlList: [],//路单数据缓存，用于改变视口大小时重绘使用
            isReloadData:true,//当前是否在重新加载台面列表状态
            isShowLoad:true,//是否显示加载中
        };
    },
    directives: {
        'set-size': {
            mounted(elem,binding){
                let _this=binding.instance;
                _this.$root.setCanvasSize(elem);
                //画表格
                let hasHead=false;
                if(_this.gameId==GAME_ID.cor||_this.gameId==GAME_ID.dice){
                    hasHead=true;
                }
                let obj = _this.$root.drawCanvasTable(elem,Object.keys(GAME_ROW).find(key=>GAME_ROW[key].indexOf(Number(_this.gameId))!=-1),hasHead,_this.gameId==GAME_ID.cor?1.5:1);
                _this.$root.columnList[binding.value]=obj.column;
                //请求数据
                _this.$emit("get-room-pearl",binding.value,obj.column);
            }
        },
        'load':{
            mounted(elem,binding){
                if(binding.value==binding.instance.roomList.length-1){//最后一项加载完毕
                    binding.instance.isShowLoad=false;
                }
            }
        },
        'scroll-load':{
            mounted(elem,binding) {
                let fn=()=>{
                    let parent=elem.parentNode.parentNode;//被滚动内容的父容器
                    let parentStyle=getComputedStyle(parent);
                    let parentHeight=parentStyle.height.replace("px","");//父容器的高度（可见高度）
                    let child=binding.arg&&document.getElementById(binding.arg);//获取传递过来的被滚动内容的id
                    let childStyle=child&&getComputedStyle(child);
                    let childHeight=childStyle&&childStyle.height.replace("px","");//被滚动内容的高度（实际高度）
                    let height=parentHeight/childHeight*100;
                    let scroll=elem.firstElementChild;//滚动条dom元素
                    scroll.style.height=height>100?100:height+"%";//设置滚动条可滚动高度
                    parent.addEventListener("scroll",(e)=>{
                        let scrollTop=e.target.scrollTop;
                        let top=scrollTop/childHeight*100+"%";
                        scroll.style.top=top;
                    },true);//加参数true表示捕获
                    scroll.onmousedown=(e1)=>{
                        let clickY=e1.pageY;
                        e1.preventDefault();
                        let lastY=clickY;
                        document.onmousemove=(e2)=>{
                            let distance=e2.pageY-lastY;//移动距离=移动到的鼠标位置-点击时的鼠标位置
                            child.parentNode.scrollTop+=distance*2;
                            lastY=e2.pageY;
                        }
                    }
                }
                fn();
                window.onresize=fn;
            },
        }
    },
    unmounted() {
        removeEventListener("resize",this.setResize);
    },
    methods: {
        /**
         * 根据游戏获取应该显示的对应统计列表名称和颜色
         */
        getNameAndColor(game,i){
            let obj={
                name:"",
                color:""
            }
            switch (Number(game)) {
                case GAME_ID.bac:
                    i==0&&(obj.name=this.$t("common.z"),obj.color=COLOR.redDark);
                    i==1&&(obj.name=this.$t("common.x"),obj.color=COLOR.blue);
                    i==2&&(obj.name=this.$t("common.h"),obj.color=COLOR.greenDark);
                    break;
                case GAME_ID.lh:
                    i==0&&(obj.name=this.$t("common.l"),obj.color=COLOR.redDark);
                    i==1&&(obj.name=this.$t("common.hu"),obj.color=COLOR.blue);
                    i==2&&(obj.name=this.$t("common.h"),obj.color=COLOR.greenDark);
                    break;
                case GAME_ID.cor:
                    if(i==0){
                        obj.name=this.$t("common.sum");
                        obj.color=COLOR.redDark;
                    }else{
                        obj.name=["Ⅰ","Ⅱ","Ⅲ"][i-1];
                        obj.color=COLOR.redDark;
                    }
                    break;
                case GAME_ID.dice:
                    i==0&&(obj.name=this.$t("common.big"),obj.color=COLOR.redDark);
                    i==1&&(obj.name=this.$t("common.small"),obj.color=COLOR.blue);
                    i==2&&(obj.name=this.$t("common.odd"),obj.color=COLOR.blue);
                    i==3&&(obj.name=this.$t("common.even"),obj.color=COLOR.redDark);
                    break;
                case GAME_ID.rou:
                    i==0&&(obj.name=this.$t("common.red"),obj.color=COLOR.redDark);
                    i==1&&(obj.name=this.$t("common.black"),obj.color=COLOR.black);
                    i==2&&(obj.name=this.$t("common.green"),obj.color=COLOR.greenDark);
                    break;
                case GAME_ID.longfong:
                    i==0&&(obj.name=this.$t("common.l"),obj.color=COLOR.blue);
                    i==1&&(obj.name=this.$t("common.fong"),obj.color=COLOR.redDark);
                    i==2&&(obj.name=this.$t("common.h"),obj.color=COLOR.greenDark);
                    break;
                case GAME_ID.coin:
                    i==0&&(obj.name=this.$t("common.even"),obj.color=COLOR.redDark);
                    i==1&&(obj.name=this.$t("common.odd"),obj.color=COLOR.blue);
                    break;
                default:
                    break;
            }
            return obj;
        },
        /**
         * 设置窗口大小改变
         */
        setResize(){
            for (let i = 0; i < this.pearlList.length; i++) {
                let elem=document.querySelector("[data-id=home_"+this.pearlList[i].id+"]");
                if(elem){//判断元素存在
                    this.$root.setCanvasSize(elem);
                    this.draw(this.pearlList[i].id,elem,this.pearlList[i]);
                }
            }
        },
        /**
         * 绘制画布及内容
         */
        draw(tableId,elem,pearlObj){
            let hasHead=false;
            if(this.gameId==GAME_ID.cor||this.gameId==GAME_ID.dice){
                hasHead=true;
            }
            let obj = this.$root.drawCanvasTable(elem,Object.keys(GAME_ROW).find(key=>GAME_ROW[key].indexOf(Number(this.gameId))!=-1),hasHead,this.gameId==GAME_ID.cor?1.5:1);
            this.$root.columnList[tableId]=obj.column;
            let arr=[];
            if(this.gameId==GAME_ID.bac||this.gameId==GAME_ID.rou||this.gameId==GAME_ID.longfong||this.gameId==GAME_ID.coin){
                arr = this.$root.setPearlData(this.gameId,pearlObj.pearl,obj.column,false);
            }else if(this.gameId==GAME_ID.cor||this.gameId==GAME_ID.dice){
                arr=pearlObj.pearl.slice(-obj.column);
            }
            this.$root.drawPearl(obj,arr,PEARL_TYPE.big_hollow,this.gameId);
        },
        /**
         * 进入台面
         */
        goRoom(id,gameId){
            !this.isReloadData&&this.$emit('go-room',id,gameId);//当前非重新加载台面状态时可进入台面
        },
        /**
         * 根据台面获取台面应显示内容
         */
        getTableShow(room){
            if(room.game==GAME_ID.tiger){
                return room.tablelimit;
            }else if(room.game==GAME_ID.texasauto){
                return "<div class='d-flex flex-column lh-1 py-1 small'>"+
                        "   <div class='mb-1'>"+
                        "       <i class='bi bi-people-fill me-1 text-info'></i>"+room.currnum+"︱"+7+
                        "   </div>"+
                        "   <div>"+
                        "       <i class='bi bi-coin me-1 text-yellow'></i>"+room.carry+
                        "   </div>"+
                        "</div>"
            }
            return "";
        },
        /**
         * 获取台面图片src
         */
        getTableImgSrc(room){
            if(room.game==GAME_ID.tiger){
                return require("@/assets/images/home/fruit.png");
            }else if(room.game==GAME_ID.texasauto){
                return require("@/assets/images/home/texasauto.png");
            }
        }
    },
    watch: {
        /**
         * 监听到要重绘路单台面id的改变
         */
        pearlObj:{
            handler(newVal){
                //重新绘制指定台面的路单
                if(newVal.id!=0){
                    let elem=document.querySelector("[data-id=home_"+newVal.id+"]");
                    if(elem){//如果找得到元素。避免非当前激活的游戏时报错
                        let obj=this.pearlList.find(v=>v.id==newVal.id);
                        if(obj){//列表中已存在
                            obj.pearl=newVal.pearl;
                        }else{
                            this.pearlList.push(newVal);
                        }
                        this.draw(newVal.id,elem,newVal);
                        if(this.pearlList.length==this.roomList.length&&this.isReloadData){//路单数据与台面数据条数一致，并且是重新加载台面数据，则说明组件已就绪
                            this.isReloadData=false;
                            addEventListener("resize",this.setResize);
                            this.$emit("on-ready");
                        }
                    }
                }
            },
            deep:true
        },
        /**
         * 监听列表加载完成时注册窗口改变事件
         */
        roomList(val){
            this.isShowLoad=true;
            if(val&&val.length>0&&this.$root.hasPearl(val[0].game)){
                this.isReloadData=true;//列表一旦被更新说明台面列表需要被重新加载
            }else{
                this.$nextTick(()=>{
                    this.isShowLoad=false;
                    this.isReloadData=false;
                    this.$emit("on-ready");
                });
            }
            this.pearlList=[];//清除旧列表的路单数据
        }
    },
};
</script>