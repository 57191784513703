<!-- 底部导航栏 -->
<template>
    <div class="row align-items-center bg-dark bg-opacity-75 text-light d-sm-none">
        <div class="col text-center py-1 d-flex flex-column" :class="{'text-info':isCheckMenu('chat'),'index-1053':$root.teachIndex==0&&$root.isShowTeach}" @click="goPage('help',this.$root.callInfo,{id:userInfo.guid,name:userInfo.name,type:1,guid:guid})" role="button">
            <i class="bi bi-chat-dots-fill fs-4"></i>
            <span>{{$t("nav.chat")}}</span>
        </div>
        <div class="col text-center py-1 d-flex flex-column" :class="{'text-info':isCheckMenu('home')}" @click="goPage('home')" role="button">
            <i class="bi bi-house-fill fs-4"></i>
            <span>{{$t("nav.home")}}</span>
        </div>
        <div class="col text-center py-1 d-flex flex-column" :class="{'text-info':isCheckMenu('personal'),'index-1053':$root.teachIndex==1&&$root.isShowTeach}" @click="goPage('personal')" role="button">
            <i class="bi bi-gear-fill fs-4"></i>
            <span>{{$t("nav.personal")}}</span>
        </div>
    </div>
</template>
<script>
import {LAYER_TYPE} from "@/assets/js/config.js"
import { useLayer } from '@/assets/js/useLayer';
export default {
    props: {
        userInfo:{
            type:Object,
            default:{
                name:"",//用户名
				guid:"",//用户guid
				realCoin:"",//真人币
				esportsCoin:""//竞技币
            }
        },
    },
    methods: {
        /**
         * 是否选中当前菜单
         */
        isCheckMenu(menuName){
            if(this.$route.name==menuName){
                return true;
            }
            return false;
        },
        /**
         * 跳转页面
         */
        goPage(pageName,params,state){
            if(this.$route.name==pageName){
                useLayer({str:this.$t("nav.at"+pageName.slice(0,1).toUpperCase()+pageName.slice(1).toLowerCase())});
                return;
            }else{
                //if(this.isPageReady){
                    let p=params||{};
                    let s=state||{};
                    p.guid=this.userInfo.guid;
                    this.$router.push({
                        name:pageName,
                        params:p,
                        state:s
                    })
                //}
            }
        },
    },
};
</script>