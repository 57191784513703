<!-- 欧亚国际导航站 -->
<style scoped>
    @media (max-width:575px){
		.logo{
            width: 50% !important;
        }
		.url_img{
            width: 100% !important;
        }
        header{
            text-align: center;
        }
    }
    
</style>
<template>
    <div class="d-flex flex-column h-100 bg-dark bg-opacity-75">
        <header class="p-4">
            <img class="img-fluid w-25 logo" src="@/app_navigation/ouya/images/logo.png" alt="">
        </header>
        <div class="flex-grow-1 p-4">
            <div class="d-flex flex-column flex-sm-row h-100">
                <div class="col-12 col-sm-3 order-2 order-sm-1 d-flex flex-sm-column justify-content-between">
                    <div class="position-relative text-center">
                        <img class="img-fluid w-75" src="@/app_navigation/ouya/images/code_bg.png" alt="">
                        <vue-qr class="img-fluid position-absolute translate-middle top-50 start-50 w-50" :margin="10" :text="appDownloadUrl1"></vue-qr>
                    </div>
                    <div class="position-relative text-center">
                        <img class="img-fluid w-75" src="@/app_navigation/ouya/images/code_bg.png" alt="">
                        <vue-qr class="img-fluid position-absolute translate-middle top-50 start-50 w-50" :margin="10" :text="appDownloadUrl2"></vue-qr>
                    </div>
                </div>
                <div class="flex-grow-1 order-1 order-sm-2">
                    <div class="d-flex flex-column h-100">
                        <div class="flex-fill h-0" role="button">
                            <div class="d-flex justify-content-end">
                                <div class="position-relative w-75 url_img">
                                    <a :href="h5Url">
                                        <img class="img-fluid" src="@/app_navigation/ouya/images/btn_1.png" alt="">
                                        <img class="img-fluid position-absolute translate-middle top-50 start-50 w-50" src="@/app_navigation/ouya/images/btn_txt_1.png" alt="">
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="flex-fill h-0" role="button">
                            <div class="d-flex justify-content-center">
                                <div class="position-relative w-75 url_img">
                                    <a :href="telUrl">
                                        <img class="img-fluid" src="@/app_navigation/ouya/images/btn_2.png" alt="">
                                        <img class="img-fluid position-absolute translate-middle top-50 start-50 w-50" src="@/app_navigation/ouya/images/btn_txt_2.png" alt="">
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="flex-fill h-0" role="button">
                            <div class="d-flex">
                                <div class="position-relative w-75 url_img">
                                    <a :href="systemUrl">
                                        <img class="img-fluid" src="@/app_navigation/ouya/images/btn_3.png" alt="">
                                        <img class="img-fluid position-absolute translate-middle top-50 start-50 w-50" src="@/app_navigation/ouya/images/btn_txt_3.png" alt="">
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 公告模态框 -->
        <div class="modal" :class="{'d-block':isShowModel}" id="modal">
            <div class="modal-dialog modal-dialog-centered w-50 mx-auto">
                <div class="modal-content bg-dark">

                    <!-- 模态框头部 -->
                    <div class="modal-header text-white">
                        <h4 class="modal-title">声明</h4>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" @click="isShowModel=false"></button>
                    </div>

                    <!-- 模态框内容 -->
                    <div class="modal-body pb-2">
                        <span class="text-danger fw-bold fs-5">尊敬的各位贵宾，结账汇款到账时间会延迟，是正常情况，请放心！</span>
                        <div class="text-white">尊敬的贵宾您好：欢迎光临欧亚国际！为了防止点击客户被盗用，点击部提醒各位代理设置代理或会员密码时，密码不能过于简单（密码最好是英文字母和数字组合），如因密码简单导致账户被盗，责任自负。特此通告！</div>
                        <div class="mb-1 fw-bold">
                            <mark>欧亚国际官方网站：</mark>
                        </div>
                        <div class="mb-1" v-for="i in webList" :key="i">
                            <mark>{{i}}</mark>
                        </div>
                        <div class="mb-1 fw-bold">
                            <mark>欧亚点击投诉电话：</mark>
                        </div>
                        <div class="mb-1" v-for="i in telList" :key="i">
                            <mark>{{i}}</mark>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>
<script>
import vueQr from 'vue-qr/src/packages/vue-qr.vue'
export default {
    data() {
        return {
            appDownloadUrl1:"",
            appDownloadUrl2:"",
            h5Url:"",
            telUrl:"",
            systemUrl:"",
            webList:[],
            telList:[],
            isShowModel:true,
        };
    },
    mounted() {
        this.appDownloadUrl1=ouya.appDownloadUrl1;
        this.appDownloadUrl2=ouya.appDownloadUrl2;
        this.h5Url=ouya.h5Url;
        this.telUrl=ouya.telUrl;
        this.systemUrl=ouya.systemUrl;
        this.webList=ouya.webList;
        this.telList=ouya.telList;
    },
    components: { vueQr },
};
</script>