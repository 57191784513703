<template>
    <div class="d-flex flex-column h-100">
        <!-- 横向导航 -->
        <navbar class="order-first d-flex flex-column" :user-info="userInfo" :notice="notice" :video-line-list="videoLineList" :audio="audio" :curr-video-line="currVideoLine" :has-chat-msg="hasChatMsg" :is-page-ready="isPageReady" :is-fullscreen="isFullscreen" @logout="$root.logout" ></navbar>
        <div class="flex-grow-1 card bg-cyan mb-1 mb-sm-0">
            <!-- 联系客服 -->
            <chat v-if="isLoadChat" class="position-absolute start-0 end-0 top-0 index-2 fs-5" :token="token" @close-chat="$router.push({name:'home',params:{guid:userInfo.guid}})" @msg-websocket="msgWebsocket" @send-websocket="sendWebsocket"></chat>
            <div v-else class="d-flex flex-grow-1 justify-content-center align-items-center text-light fs-4 fw-bold">{{ serverTip }}</div>
        </div>
    </div>
</template>
<script>
import { INTERFACE } from "@/assets/js/const.js";
import {IS_DEBUG,COMMAND} from "@/assets/js/config.js"
import Chat from '@/components/public/Chat.vue';
import navbar from '@/components/public/navbar.vue';
import { guid } from "@/assets/js/common.js"
import { nextTick } from '@vue/runtime-core';
export default {
    components:{
        Chat,navbar
    },
    props:{
        userInfo:{
            type:Object,
            default:{
                name:"",//用户名
				guid:"",//用户guid
				realCoin:0,//真人币
				esportsCoin:0//竞技币
            }
        },
        currLang:{
            type:String,
            default:""
        },//当前选中的语言
        audio:{
            type:Object,
            default:{
                startAudio:true,//开始/结束音效开关
                bgAudio:true,//背景音效开关
            }
        },
        videoLineList:{
            type:Array,
            default:[]
        },//视频线路列表
        hasChatMsg:false,//是否有未读的客服消息
        isPageReady:false,//页面是否加载完毕
        currVideoLine:"",//当前视频线路id
        isFullscreen:{
            type:Boolean,
            default:false
        },//当前是否全屏
    },
	data() {
		return {
			isLoadChat:false,//是否已点击过客服组件
            chatGuid:"",
            token:"",
            serverTip:"",
            notice:"",//公告内容
		};
	},
	mounted() {
        this.setWsMessage();
		//if(!this.$root.chatWs){//ws没有连接
            //获取客服连接地址
            this.$emit("ws-send",COMMAND.all_getchat,this.userInfo.name);
        // }else{
        //     this.isLoadChat=true;
            
        // }
        this.$root.hasChatMsg=false;
        //获取公告
        this.$emit('ws-send', COMMAND.all_getnotice, 0, this.userInfo.name, this.userInfo.guid);
	},
	methods:{
        /**
         * 设置websocket响应事件
         */
        setWsMessage(){
            this.$emit("ws-msg",(data)=>{
                switch (data.command){
                    case COMMAND.all_getchat:
                        (!data.str)&&(data.str=config().ws);
                        IS_DEBUG&&(data.str="ws://192.168.1.59:9022?a123");
                        this.serverTip=this.$t("chat.serverCon");
                        if(data.str&&data.str!=""&&(data.str.indexOf("ws")!=-1||data.str.indexOf("wss")!=-1)){//获取到socket地址并且地址包含ws或者wss
                            this.token=data.str.substring(data.str.indexOf('?')+1);
                            this.$root.chatWs=new WebSocket(data.str);
                            this.$root.chatWs.onopen=()=>{
                                this.chatGuid=guid();
                                this.sendWebsocket({
                                    fun:INTERFACE.login,
                                    id:this.userInfo.name,
                                    pwd:"",
                                    name:this.userInfo.name,
                                    type:1,
                                    guid:this.chatGuid,
                                    isvip:1
                                })
                                this.serverTip="";
                                IS_DEBUG&&console.log("客服服务器连接成功");
                            }
                            this.$root.chatWs.onerror=(e)=>{//socket连接错误
                                this.serverTip=this.$t("chat.serverErr");
                            }
                            this.msgWebsocket();
                            // this.$root.chatWs.onmessage=this.wsMsg;
                        }else{//否则
                            this.serverTip=this.$t("chat.serverUrl");
                        }
                        break;
                    case COMMAND.all_getnotice:
                        this.notice=data.str;
                        break;
                }
            });
        },
        msgWebsocket(onmessage){
            if(this.$root.chatWs){
                let invalid="";//无效字符串
                this.$root.chatWs.onmessage=async (e)=>{
                    IS_DEBUG&&console.log("接收："+e.data);
                    let obj = this.$root.setSocketData(e.data,invalid);
                    let arr=obj.arr;
                    invalid=obj.invalid;
    
                    //接口响应
                    for (let i = 0; i < arr.length; i++){
                        let data = arr[i];
                        IS_DEBUG&&console.log("接收【转换】："+JSON.stringify(data));

                        switch (data["fun"]) {
                            case INTERFACE.login:
                                this.isShowLoad=false;
                                let loginResult=data["result"];
                                if(loginResult==1){
                                    history.pushState({
                                        "id":this.userInfo.name,
                                        "name":this.userInfo.name,
                                        "guid":this.chatGuid,
                                        "type":1,
                                        "power":data["power"],
                                        "imgurl":data["iurl"],
                                    },"");
                                    this.isLoadChat=true;

                                    // this.$router.push({
                                    //     name:'home',
                                    //     state:{
                                    //         id:this.userInfo.guid,
                                    //         name:data["name"],
                                    //         type:1,
                                    //         guid:guid(),
                                    //         power:data["power"],
                                    //         imgurl:data["iurl"]
                                    //     }
                                    // },);
                                }else{
                                    useLayer({str:"客服系统登录失败"});
                                }
                                this.isLoginEnable=true;
                                break;
                        }
                        
                        onmessage&&onmessage(data);
                        await nextTick();//加上这个以免因连包导致循环赋值，但因为vue的机制使循环最后一次才能监听到
                    }
    
                }
            }
        },
        sendWebsocket(obj){
            if(this.$root.chatWs){
                console.log("发送："+JSON.stringify(obj));
                this.$root.chatWs.send(JSON.stringify(obj));
            }
        },

	},
}
</script>