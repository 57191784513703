<!-- 导航栏 -->
<style scoped>
    .logo{
        width: 6rem; 
        z-index: 1000;
    }
    .menu-slide:hover>.menu-slide-item,.menu-slide-item:hover {
        display: block;
    }
    .menu-slide-item>span{
        visibility: hidden;
        opacity: 0;
    }
    .menu-slide:hover>.menu-slide-item>span:nth-of-type(1){
        visibility:visible;
        animation: menu-slide 0.2s ease-in-out forwards;
    }
    .menu-slide:hover>.menu-slide-item>span:nth-of-type(2){
        visibility:visible;
        animation: menu-slide 0.2s ease-in-out forwards;
        animation-delay: 0.1s;
    }
    .menu-slide:hover>.menu-slide-item>span:nth-of-type(3){
        visibility:visible;
        animation: menu-slide 0.2s ease-in-out forwards;
        animation-delay: 0.2s;
    }
    .menu-slide:hover>.menu-slide-item>span:nth-of-type(4){
        visibility:visible;
        animation: menu-slide 0.2s ease-in-out forwards;
        animation-delay: 0.3s;
    }
    .menu-slide:hover>.menu-slide-item>span:nth-of-type(5){
        visibility:visible;
        animation: menu-slide 0.2s ease-in-out forwards;
        animation-delay: 0.4s;
    }
    .menu-slide:hover>.menu-slide-item>span:nth-of-type(6){
        visibility:visible;
        animation: menu-slide 0.2s ease-in-out forwards;
        animation-delay: 0.5s;
    }
    @keyframes menu-slide {
        0% {
            opacity: 0;
            transform: scale(1.5);
        }
        100% {
            opacity: 1;
            transform: scale(1);
        }
    }
    @media (min-width: 576px){
        .logo{
            width: 8rem;
        }
    }
</style>
<template>
    <div class="positoin-relative">
        <header class="py-1 px-2 px-sm-3 pt-sm-0 pb-sm-0 position-relative index-2">
            <div class="w-100 d-flex flex-wrap justify-content-between my-sm-1 my-md-2">
                <div class="d-flex flex-column justify-content-center">
                    <img class="d-none d-sm-block logo me-2 me-sm-3" src="logo.png" alt="">
                </div>
                <div class="d-flex flex-grow-1">
                    <div class="w-0 d-flex flex-column flex-grow-1 text-light mt-0 justify-content-between">
                        <!-- 公告 -->
                        <div class="d-flex align-items-center flex-grow-1 position-relative">
                            <img class="d-block d-sm-none logo me-2 me-sm-3" src="logo.png" alt="">
                            <div v-if="$route.name!='share'" class="flex-grow-1 notice-short border border-1 border-warning rounded-pill d-flex flex-column px-2 overflow-hidden" role="button">
                                <div class="w-100 rounded-pill position-relative width-max marquee text-nowrap">
                                    <label>{{notice}}</label>
                                </div>
                            </div>
                            <div class="notice-long position-absolute bg-dark p-2 rounded w-100 index-1 top-100">{{notice}}</div>
                        </div>
                        <!-- 用户信息 -->
                        <div class="d-flex align-content-center mt-1 ms-1 ms-sm-0 position-relative">
                            <div class="d-flex align-items-center me-2 d-sm-none w-6rem fw-bold text-primary" @click="$root.goPage('home')">
                                <i class="bi bi-house-fill me-1"></i>
                                <span>{{$t("nav.home")}}</span>
                            </div>
                            <div class="me-sm-2 d-flex align-items-center flex-fill flex-sm-grow-0">
                                <i class="bi bi-person-circle me-1 text-info"></i>
                                <span class="align-middle">{{userInfo.name}}</span>
                            </div>
                            <div v-if="!($route.name=='share'||$root.gameModel=='tel')" class="me-sm-2 d-flex align-items-center flex-fill flex-sm-grow-0">
                                <i class="bi bi-coin me-1 text-yellow"></i>
                                <span class="me-1 align-middle fw-bold">{{$t("nav.realMoney")}}</span>
                                <span class="align-middle">{{userInfo.realCoin}}</span>
                            </div>
                            <div v-if="!($route.name=='share'||$root.gameModel=='tel')" class="d-flex align-items-center flex-fill flex-sm-grow-0">
                                <i class="bi bi-coin me-1 text-yellow"></i>
                                <span class="me-1 align-middle fw-bold">{{$t("nav.esportsMoney")}}</span>
                                <span class="align-middle">{{userInfo.esportsCoin}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex flex-column justify-content-center">
                    <div class="d-sm-none dropdown menu-slide">
                        <button class="btn btn-outline-light ms-2 px-2 py-1 position-relative" :class="{'index-1053':$root.teachIndex==2&&$root.isShowTeach}" type="button">
                            <i class="bi bi-list"></i>
                        </button>
                        <div class="dropdown-menu bg-cyan p-2 pb-0 text-center rounded-3 min-w-0 shadow menu-slide-item end-0">
                            <span v-show="['bac','cor'].indexOf($route.name)!=-1" class="dropdown-item text-light border border-info bg-cyan mb-2 rounded-3 px-4 py-2 shadow" role="button" @click="$root.goPage('personal')">{{$t("nav.personal")}}</span>
                            <span v-if="$route.name!='share'" class="dropdown-item text-light border border-info bg-cyan mb-2 rounded-3 px-4 py-2 shadow" data-bs-toggle="modal" data-bs-target="#videLineModal" role="button">{{$t("nav.network")}}</span>
                            <span class="dropdown-item text-light border border-info bg-cyan mb-2 rounded-3 px-4 py-2 shadow" data-bs-toggle="modal" data-bs-target="#audioModal" role="button">{{$t("nav.sound")}}</span>
                            <span v-if="$route.name!='share'" class="dropdown-item text-light border border-info bg-cyan mb-2 rounded-3 px-4 py-2 shadow" @click="$root.setFullscreen" role="button">{{isFullscreen?$t("login.unFullscreen"):$t("login.fullscreen")}}</span>
                            <span v-if="$route.name!='share'" class="dropdown-item text-light border border-info bg-cyan mb-2 rounded-3 px-4 py-2 shadow" @click="$emit('logout')" role="button">{{$t("nav.logout")}}</span>
                            <span v-show="['bac','cor'].indexOf($route.name)!=-1" class="dropdown-item text-light border border-info bg-cyan mb-2 rounded-3 px-4 py-2 shadow" role="button" @click="$root.goPage('home')">{{$t("nav.home")}}</span>
                        </div>
                    </div>
                </div>
                <div class="d-sm-flex d-none text-center fw-bold justify-content-end align-items-center">
                    <div class="ps-3" :class="{'index-1053':$root.teachIndex==0&&$root.isShowTeach}">
                        <div class="text-light d-flex flex-column align-items-center align-items-center" role="button" @click="$root.goPage('help',this.$root.callInfo,{id:userInfo.guid,name:userInfo.name,type:1,guid:guid})">
                            <i class="bi bi-chat-dots-fill fs-3 text-info position-relative">
                                <span v-show="hasChatMsg" class="position-absolute top-0 start-100 translate-middle p-1 bg-danger border border-light rounded-circle"></span>
                            </i>
                            <span>{{$t("nav.chat")}}</span>
                        </div>
                    </div>
                    <div class="ps-3" :class="{'index-1053':$root.teachIndex==1&&$root.isShowTeach}">
                        <div class="text-light d-flex flex-column align-items-center align-items-center" role="button" @click="$root.goPage('personal')">
                            <i class="bi bi-x-diamond-fill fs-3 text-info"></i>
                            <span>{{$t("nav.personal")}}</span>
                        </div>
                    </div>
                    <div class="ps-3 dropdown menu-slide" :class="{'index-1053':$root.teachIndex==2&&$root.isShowTeach}">
                        <span class="text-light d-flex flex-column align-items-center align-items-center" role="button">
                            <i class="bi bi-gear-fill fs-3 text-info"></i>
                            <span>{{$t("nav.settings")}}</span>
                        </span>
                        <div class="dropdown-menu bg-cyan p-2 text-center rounded-3 min-w-0 shadow menu-slide-item translate-middle-x">
                            <span class="dropdown-item text-light border border-info bg-cyan mb-2 rounded-3 px-4 py-2 shadow" data-bs-toggle="modal" data-bs-target="#videLineModal" role="button">{{$t("nav.network")}}</span>
                            <span class="dropdown-item text-light border border-info bg-cyan mb-2 rounded-3 px-4 py-2 shadow" data-bs-toggle="modal" data-bs-target="#audioModal" role="button">{{$t("nav.sound")}}</span>
                            <span class="dropdown-item text-light border border-info bg-cyan mb-2 rounded-3 px-4 py-2 shadow" @click="$root.setFullscreen" role="button">{{isFullscreen?$t("login.unFullscreen"):$t("login.fullscreen")}}</span>
                            <span class="dropdown-item text-light border border-info bg-cyan rounded-3 px-4 py-2 shadow" @click="$emit('logout')" role="button">{{$t("nav.logout")}}</span>
                        </div>
                    </div>
                    <div class="ps-3">
                        <span class="text-light d-flex flex-column align-items-center align-items-center" role="button" @click="$root.goPage('home')">
                            <i class="bi bi-house-fill fs-3 text-info"></i>
                            <span>{{$t("nav.home")}}</span>
                        </span>
                    </div>
                </div>
            </div>
        </header>
        <!-- 视频线路模态框 -->
        <div class="modal fade" id="videLineModal">
            <div class="modal-dialog modal-dialog-centered w-50 mx-auto">
                <div class="modal-content bg-cyan">

                    <!-- 模态框头部 -->
                    <div class="modal-header">
                        <h4 class="modal-title">{{$t("nav.network")}}</h4>
                        <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                    </div>

                    <!-- 模态框内容 -->
                    <div class="modal-body pb-2">
                        <div class="row text-center text-light">
                            <div class="col-12 col-sm-6 mb-2" v-for="(item,i) in videoLineList" :key="(item,i)">
                                <div class="bg-cyan-hover bg-cyan border rounded py-2 shadow border-light" :class="(item.id==currVideoLine?'border-info text-info':'')" @click="$root.setCurrVideoLine(item.id)" role="button">{{item.name}}</div>
                            </div>
                        </div>
                    </div>

                    <!-- 模态框底部 -->
                    <div class="modal-footer">
                        <button type="button" class="btn btn-danger text-light" data-bs-dismiss="modal">{{$t("common.close")}}</button>
                    </div>

                </div>
            </div>
        </div>
        <!-- 音效设置模态框 -->
        <div class="modal fade" id="audioModal">
            <div class="modal-dialog modal-dialog-centered w-50 mx-auto">
                <div class="modal-content bg-cyan">

                    <!-- 模态框头部 -->
                    <div class="modal-header">
                        <h4 class="modal-title">{{$t("nav.sound")}}</h4>
                        <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                    </div>

                    <!-- 模态框内容 -->
                    <div class="modal-body pb-2">
                        <div class="d-flex flex-wrap text-center text-light">
                            <div class="d-flex col-12 col-sm-6 mb-2">
                                <div class="form-check form-switch ps-0 col">
                                    <div class="d-flex align-items-center justify-content-between justify-content-sm-start">
                                        <input class="form-check-input fs-4 m-0 me-sm-2" v-model="audio.bgAudio" type="checkbox" role="switch" id="bgAudio">
                                        <label class="form-check-label" for="bgAudio">{{$t("nav.bgAudio")}}</label>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex col-12 col-sm-6 mb-2">
                                <div class="form-check form-switch ps-0 col">
                                    <div class="d-flex align-items-center justify-content-between justify-content-sm-start">
                                        <input class="form-check-input fs-4 m-0 me-sm-2" v-model="audio.startAudio" type="checkbox" role="switch" id="startAndEndAudio">
                                        <label class="form-check-label" for="startAndEndAudio">{{$t("nav.startAudio")}}</label>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex col-12 col-sm-6 mb-2 mb-sm-0">
                                <div class="form-check form-switch ps-0 col">
                                    <div class="d-flex align-items-center justify-content-between justify-content-sm-start">
                                        <input class="form-check-input fs-4 m-0 me-sm-2" v-model="audio.resultAudio" type="checkbox" role="switch" id="resultAudio">
                                        <label class="form-check-label" for="resultAudio">{{$t("nav.resultAudio")}}</label>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex col-12 col-sm-6 mb-2 mb-sm-0">
                                <div class="form-check form-switch ps-0 col">
                                    <div class="d-flex align-items-center justify-content-between justify-content-sm-start">
                                        <input class="form-check-input fs-4 m-0 me-sm-2" v-model="audio.tenAudio" type="checkbox" role="switch" id="tenAudio">
                                        <label class="form-check-label" for="tenAudio">{{$t("nav.tenAudio")}}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- 模态框底部 -->
                    <div class="modal-footer">
                        <button type="button" class="btn btn-danger text-light" data-bs-dismiss="modal">{{$t("common.close")}}</button>
                    </div>

                </div>
            </div>
        </div>

        <audio-player v-show="false" ref="audioPlayer" :isLoop="true" :muted="!audio.bgAudio" :audio-list="bgAudioList.map(elm => elm.url)" />

    </div>
</template>
<script>
import { guid } from "@/assets/js/common.js"
import { useLayer } from '@/assets/js/useLayer'
export default {
    props:{
        isFullscreen:{
            type:Boolean,
            default:false
        },//当前是否全屏
        currVideoLine:"",//当前视频线路id
        userInfo:{
            type:Object,
            default:{
                name:"",//用户名
				guid:"",//用户guid
				realCoin:"",//真人币
				esportsCoin:""//竞技币
            }
        },
        notice:"",//公告内容
        videoLineList:{
            type:Object,
            default:[]
        },//视频线路列表
        audio:{
            type:Object,
            default:{
                bgAudio:true,//背景音效开关
                startAudio:true,//开始/结束音效开关
                resultAudio:true,//开注音效
                tenAudio:true,//最后10秒音效
            }
        },
        hasChatMsg:false,//是否有未读的客服消息
        isPageReady:false,//主页面是否加载完成，用于部分操作需要等待页面加载完毕后的功能
    },
    data(){
        return {
            // currVideoLine:"",//当前视频线路id
            bgAudioList: [
                {
                    url: require('@/assets/media/common/game_bg.mp3')
                },
                {
                    url: require('@/assets/media/common/game_bg_1.mp3')
                },
                {
                    url: require('@/assets/media/common/game_bg_2.mp3')
                },
                {
                    url: require('@/assets/media/common/game_bg_3.mp3')
                },
                {
                    url: require('@/assets/media/common/game_bg_4.mp3')
                }
            ],//背景音效列表
            guid:guid(),
        }
    },
    mounted() {
        this.$refs.audioPlayer.$refs.audio.play();
    },
    methods:{
        /**
         * 修改当前视频线路
         */
        changeVideoLine(lineId){
            this.$emit('set-curr-video-line',lineId);
            // this.currVideoLine=lineId;
            useLayer({str:this.$t("nav.videoChangeSucc")});
        },
        /**
         * 跳转页面
         */
        goPage(pageName,params,state){
            if(this.$route.name==pageName){
                useLayer({str:this.$t("nav.at"+pageName.slice(0,1).toUpperCase()+pageName.slice(1).toLowerCase())});
                return;
            }else{
                // if(pageName=="home"){
                    // }
                this.$emit("before-go-page");
                //if(this.isPageReady){
                    let p=params||{};
                    let s=state||{};
                    p.guid=this.userInfo.guid;
                    this.$router.push({
                        name:pageName,
                        params:p,
                        state:s
                    })
                //}
            }
        },
        
    },
    watch: {
        /**
         * 监听视频线路列表变化
         */
        videoLineList(newVal) {
            // if(newVal.length>0){
            //     let obj = newVal.find(v=>v.type=="IOS");
            //     if(this.$root.getSystem()=="ios"&&obj){//ios系统如果有IOS线路则使用IOS线路
            //         this.currVideoLine=obj.id;
            //     }else{
            //         this.currVideoLine=newVal[0].id;
            //     }
            //     this.$emit('set-curr-video-line',newVal[0].id);
            // }
        },
    },
    directives:{
        /**鼠标移到导航项显示子项的指令 */
        // hover:{
        //     mounted(el){
        //         el.onmouseover=()=>{
        //             for (let elem of el.children) {
        //                 if(el.className.indexOf('dropend')!=-1){//向右展示的导航
        //                     elem.className.indexOf('dropdown-menu')!=-1&&elem.setAttribute('data-bs-popper','none');//加上这个玩意才能向右展示
        //                 }
        //                 elem.classList.add('show');//导航现身
        //             }
        //         }
        //         el.onmouseout=()=>{
        //             for (let elem of el.children) {
        //                 elem.classList.remove('show');//导航隐身
        //             }
        //         }
        //     }
        // }
    }
}
</script>