<!-- 切换台面 -->
<style scoped>
    .change-table{
        transform: translate(100%,0);
        transition: transform .3s cubic-bezier(.25, 1.15, .35, 1.08);
        z-index: 1051;
    }
    .change-table.active{
        transform: translate(0,0);
    }
</style>
<template>
    <div @click.stop :class="{active:isShowTable}" class="d-flex col-11 col-sm-6 col-md-6 col-lg-5 h-100 position-absolute change-table py-2 end-0 bg-dark rounded-start bg-opacity-75">
        <div class="d-flex flex-column">
            <div @click.stop="showTable" class="d-flex position-absolute top-50 text-vertical start-0 bg-danger text-light rounded-start border border-2 border-warning border-end-0 translate-start-x-middle-y lh-sm px-1 py-2" role="button">
                <span>{{$t("room.changeTable")}}</span>
            </div>
            <ul class="nav nav-tabs fw-bold px-2">
                <li v-for="item in gameList" :key="item" class="nav-item" :role="item==activeGame?'':'button'">
                    <span class="nav-link" :class="item==activeGame?'active':'text-light'" @click="getRoomList(item)">{{$root.getGameName(item)}}</span>
                </li>
            </ul>
            <div class="flex-grow-1 h-0 text-light mt-2">
                <room-list :class="[$root.hasPearl(activeGame)?'col-12':'col-3']" :game-id="activeGame" :room-list="roomList" :count-list="countList" :pearl-obj="pearlObj" :table-id="tableId" @go-room="goRoom" @get-room-pearl="getRoomPearl" @on-ready="moduleReady"></room-list>
            </div>
        </div>
    </div>
</template>
<script>
import { GAME_ID,LAYER_TYPE } from '@/assets/js/config';
import roomList from '@/components/public/roomList.vue';
import { useLayer } from '@/assets/js/useLayer';
export default {
    components:{
        roomList
    },
    props: {
        //游戏列表
        gameList:{
            type:Array,
            default:[]
        },
        //台面列表
        roomList:{
            type:Array,
            default:[]
        },
        //路单统计列表
        countList:{
            type:Array,
            default:[]
        },
        activeGame:"",
        userInfo:{
            type:Object,
            default:{
                name:"",//用户名
				guid:"",//用户guid
				realCoin:0,//真人币
				esportsCoin:0//竞技币
            }
        },
        //要重绘路单的对象
        pearlObj:{
            id:0,//台面id
            pearl:[]//路单数据
        },
        tableId:0,
    },
    data() {
        return {
            gameIdList:GAME_ID,
            isShowTable:false,//是否展开切换台面
            isModuleReady:false,//页面内组件是否加载完成
        };
    },
    methods: {
        /**
         * 进入台面
         */
        goRoom(id,game){
            this.$emit('go-room',id,game);
            this.isShowTable=false;
        },
        /**
         * 展开或合上切换台面列表
         */
        showTable(){
            this.isShowTable=this.isShowTable?false:true;
            if(this.isShowTable){//显示状态下点击空白处隐藏
                document.onclick=(e)=>{
                    e.stopPropagation();
                    this.isShowTable=false;
                }
            }
        },
        /**
         * 获取游戏对应台面列表
         */
        getRoomList(gameId){
            if(this.isModuleReady){//当页面内组件加载完成时才能再次点击
                if(Object.values(GAME_ID).indexOf(Number(gameId))!=-1){
                    if(gameId!=this.activeGame){
                        this.isModuleReady=false;
                        this.$emit("change-room-list",gameId);
                    }
                }else{
                    useLayer({str:"游戏正在加紧开发中，敬请期待..."});
                }
            }
        },
        /**
         * 获取台面路单
         * @param {Number} tableId 台面id
         * @param {Number} column 获取的数据列数
         */
        getRoomPearl(tableId,column){
            this.$emit("get-room-pearl",tableId,column);
        },
        /**
         * 组件加载完成
         */
        moduleReady(){
            this.isModuleReady=true;
        }
    },
};
</script>