<style scoped>
    
    i~div:first-of-type>img{
        box-shadow: 0px 3px 2px 0px #000;
    }
    .btn-outline-red {
        color: #fb2401 !important;
        border-color: #fb2401 !important;
    }
    .btn-check:checked+.btn-outline-red,.btn-outline-red:hover {
        color: #fff !important;
        background-color: #fb2401;
        border-color: #fb2401;
    }
    .btn-outline-blue {
        color: #065aa4 !important;
        border-color: #065aa4 !important;
    }
    .btn-check:checked+.btn-outline-blue,.btn-outline-blue:hover {
        color: #fff !important;
        background-color: #065aa4;
        border-color: #065aa4;
    }
    .coruscate{
        animation: coruscate .5s ease infinite alternate;
    }
	@keyframes coruscate {
        0% {
            background: transparent;
        }
        100% {
            background: rgba(13,202,240);
        }
    }
    @media (min-width:576px) {
        .tel-video-h{
            /* 26.917rem */
            height: 23.211rem;
        }
    }
    @media (min-width:768px) {
        .tel-video-h{
            height: 25.523rem;
        }
    }
    @media (min-width: 992px){
        .tel-video-h{
            height: 27.072rem;
        }
    }
    @media (min-width: 1200px){
        .tel-video-h{
            height: 27.194rem;
        }
    }
    @media (min-width: 1400px){
        .tel-video-h{
            height: 28.656rem;
        }
    }
</style>
<template>
    <div :class="{'position-sm-absolute start-0 end-0 top-0':!$root.gameModel}" class="d-flex flex-column h-100 position-xs-relative">
        <!-- 横向导航 -->
        <navbar class="order-first d-flex flex-column d-sm-none" :user-info="userInfo" :notice="notice" :video-line-list="videoLineList" :audio="audio" :curr-video-line="currVideoLine" :has-chat-msg="hasChatMsg" :is-page-ready="isPageReady" :is-fullscreen="isFullscreen" @logout="$root.logout" ></navbar>
        <!-- 视频区 -->
        <div :class="{'flex-grow-0 flex-sm-grow-1 h-sm-0':!$root.gameModel}" class="d-flex flex-column flex-sm-row position-relative">
            <!-- 纵向导航 -->
            <navbar-vertical class="d-none d-sm-flex" :user-info="userInfo" :is-fullscreen="isFullscreen" @show-modal="v=>currModal=v"></navbar-vertical>
            <!-- 电话模式视频 -->
            <div class="d-flex flex-column flex-sm-row flex-grow-1 position-relative tel-video-h" v-if="$root.gameModel">
                <media-video class="border-end border-secondary" :video-index="0" id="firstvideo" :room-info="roomInfo" :video-list="videoList" :countdown="countdown" :room-class="(roomInfo.isAr==1&&!$root.gameModel)?['top-xs-0 top-sm-5rem mt-2 mt-sm-1 ms-2']:['top-xs-0 top-sm-0 m-2']" :time-class="(roomInfo.isAr==1&&!$root.gameModel)?['top-xs-0','top-sm-5rem top-xl-6rem','ms-2']:['top-0','m-2']" :img-class="roomInfo.isAr==1?['h-100','w-100']:['position-absolute translate-middle top-50 start-50']" :notice="notice"></media-video>
                <media-video class="border-start border-secondary" :video-index="1" id="secondvideo" :video-list="videoList" :room-class="['d-none']" :time-class="['d-none']" :img-class="roomInfo.isAr==1?['h-100','w-100']:['position-absolute translate-middle top-50 start-50']" :notice="notice"></media-video>
            </div>
            <!-- 视频区 -->
            <div v-else class="d-flex flex-column flex-grow-1 position-relative">
                <!-- 公告 -->
                <notice :notice="notice"></notice>
                <!-- 视频 -->
                <div class="d-flex h-0 pt-60 position-relative flex-grow-1 pt-sm-0">
                    <media-video class="position-xs-absolute top-0 start-0 position-sm-relative" :class="{'scale-video':isScale}" :room-info="roomInfo" :video-list="videoList" :countdown="countdown" :room-class="roomInfo.isAr==1?['top-xs-0 top-sm-0 mt-2 mt-sm-5 ms-2']:['m-2']" :time-class="roomInfo.isAr==1?['top-0 mt-2 mt-sm-5 ms-2']:['top-0','m-2']" :img-class="roomInfo.isAr==1?['h-100','w-100']:['position-absolute translate-middle top-50 start-50']"></media-video>
                </div>
                <!-- 提交区 -->
                <div class="position-xs-relative position-sm-absolute start-0 end-0 bottom-0 top-0 bg-xs-success">
                    <!-- 操作区 -->
                    <div v-if="!$root.gameModel" class="virtual-container perspective-600 position-xs-relative position-sm-absolute start-50 bottom-0 bg-xs-success index-1" :class="{'scale-submit':isScale==true}">
                        <!-- 牌区 -->
                        <!-- <div v-if="showPokerArr.length>0" class="d-sm-none position-absolute w-100 h-100 top-0 d-flex flex-column index-1054 justify-content-center bg-dark bg-opacity-75">
                            <div v-for="(item,i) in showPokerArr" :key="(item,i)" class="d-flex rounded" style="height: 5.5rem;">
                                <div class="text-light text-vertical text-upright text-center fw-bold border rounded" :style="{'background':i==0?color.red:color.blue}">{{i==0?$t("common.z"):$t("common.x")}}{{i>0?i:''}}</div>
                                <div class="d-flex align-items-center justify-content-center border rounded-3 px-1" v-for="val in item" :key="val" :style="{'background':i==0?color.red:color.blue}">
                                    <div v-if="val" :class="[$root.getPokerByNum(val)['color']]" class="d-flex bg-light rounded p-1 flex-column">
                                        <span class="fw-bold lh-1 fs-5">{{ $root.getPokerByNum(val)["name"] }}</span>
                                        <div class="d-flex flex-grow-1 px-3 pt-2 pb-3">
                                            <i :class="[$root.getPokerByNum(val)['shape']]" class="bi d-flex align-items-center justify-content-center fs-1 flex-grow-1"></i>
                                        </div>
                                    </div>
                                </div>
                                <div v-show="showPokerTxtArr[i]" class="text-light text-vertical text-upright text-center fw-bold border rounded" :style="{'background':i==0?color.red:color.blue}">{{showPokerTxtArr[i]}}</div>
                            </div>
                        </div> -->
                        <div class="rotate3d-x-sm-50 mb-sm-1 p-1 p-sm-0">
                            <!-- 切换区 -->
                            <div class="d-flex justify-content-between justify-content-sm-end justify-content-lg-between mb-1 mb-sm-2">
                                <div class="ms-sm-5 ps-sm-5 d-flex text-light lh-1 fs-sm-5 align-items-center align-items-sm-end d-sm-none d-lg-flex text-nowrap">
                                    <span>{{$t("room.ingBet")}}:{{getAmountCount("submiting")}}</span>
                                    <span class="ms-2">{{$t("room.edBet")}}:{{getAmountCount("submited")}}</span>
                                    <span class="ms-2">{{$t("room.allBet")}}:{{submitedCount+getAmountCount("submiting")}}</span>
                                    <!-- <span class="ms-4">预扣：100</span> -->
                                </div>
                                <div class="d-flex">
                                    <button type="button" class="btn btn-info me-2 text-nowrap" data-bs-toggle="modal" data-bs-target="#oddsModal" @click="isShowTip=true">{{$t("room.oddsExplain")}}<i class="bi bi-question-circle ms-1"></i></button>
                                    <!-- <div class="d-flex justify-content-center align-items-center justify-content-sm-end me-2 text-light" @mouseenter="isShowTip=true" @mouseleave="isShowTip=false" role="button">
                                        赔率说明<i class="bi bi-question-circle ms-1"></i>
                                    </div> -->
                                    <div class="btn-group btn-shadow-right" role="group">
                                        <input type="radio" class="btn-check" name="btnradio" @change="changeModel(0,$event)" value="0" v-model="submitModel" id="submitModelZ" >
                                        <label class="btn btn-outline-red fw-bold" for="submitModelZ">{{$t("common.z")}}</label>
                                        <input type="radio" class="btn-check" name="btnradio" @change="changeModel(1,$event)" value="1" v-model="submitModel" id="submitModelX" >
                                        <label class="btn btn-outline-blue fw-bold" for="submitModelX">{{$t("common.x")}}</label>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex flex-column flex-sm-row">
                                <!-- 选筹区 -->
                                <div class="d-flex flex-sm-column justify-content-between justify-content-sm-around align-items-center text-black order-2 order-sm-1 mb-2 mb-sm-0">
                                    <div class="d-flex justify-content-around justify-content-sm-start col col-sm-auto" v-for="i in 3" :key="i">
                                        <div class="position-relative me-1 chips rounded-circle" :class="{active:amount==currAmountListSort[2*i-2]}" @click="amount=currAmountListSort[2*i-2]" role="button">
                                            <img class="w-3rem rounded-circle chips-shadow" :src="require('@/assets/images/room/chips_'+currAmountListSort[2*i-2]+'.png')" alt="">
                                            <span class="position-absolute start-50 top-50 fw-bold chips-text text-nowrap">{{$root.chipsTxtConvert(currAmountListSort[2*i-2])}}</span>
                                        </div>
                                        <button v-if="i==3" @click.stop="showMoreAmount" class="btn btn-secondary btn-shadow-left text-light">
                                            <i class="bi bi-grid-fill"></i>
                                        </button>
                                        <div v-else class="position-relative me-1 chips rounded-circle" :class="{active:amount==currAmountListSort[2*i-1]}" @click="amount=currAmountListSort[2*i-1]" role="button">
                                            <img class="w-3rem rounded-circle chips-shadow" :src="require('@/assets/images/room/chips_'+currAmountListSort[2*i-1]+'.png')" alt="">
                                            <span class="position-absolute start-50 top-50 fw-bold chips-text text-nowrap">{{$root.chipsTxtConvert(currAmountListSort[2*i-1])}}</span>
                                        </div>
                                    </div>
                                </div>
                                <!-- 提交区 -->
                                <div class="d-flex flex-sm-row fw-bold text-light fs-sm-4 rounded-10 border-solid border-xs-3 border-sm-4 me-sm-1 mb-2 mb-sm-0 position-relative order-1 order-sm-2" :style="{'border-color':submitModel==0?'#ca5a47':'#78a5ce',background:(roomInfo.isAr==1&&roomInfo.rid==73)?'#067360':''}">
                                    <div v-for="i of 3" :key="i" class="flex-fill d-flex flex-column" :class="{'flex-fill d-flex flex-column border-solid border-4 border-top-0 border-bottom-0 border-start-0 border-end-xs-0':i==1,'border-solid border-xs-2 border-sm-4 border-top-0 border-bottom-0 border-start-sm-0 border-top-xs border-end-xs-0 border-start-xs-0':i==2}" :style="{'border-color':submitModel==0?'#ca5a47':'#78a5ce'}">

                                        <div class="border-xs-3 border-sm-3 border-solid border-top-0 border-start-0 border-end-0 text-center px-2 px-sm-1 px-md-2 text-truncate fs-6" :style="{'border-color':submitModel==0?'#ca5a47':'#78a5ce'}">
                                            <span>{{ submitModel==0?$t("common.z"):$t("common.x") }} {{ i }}</span>
                                        </div>

                                        <div class="flex-grow-1 d-flex flex-column flex-sm-row text-center position-relative" role="button">
                                            <div v-for="(item,idx) in [[$t('room.singleCount'),'p'],[$t('room.doubleCount'),'f'],[$t('room.multiplesCount'),'d']]" :key="idx" class="d-flex flex-column justify-content-sm-center flex-fill py-4 p-sm-3 p-md-3 p-lg-4 position-relative" :class="[i==3?'border-end-xs-0':'border-end-xs-style-solid',idx==2?'border-xs-2 border-sm-0 border-end-style-dashed':'border-2 border-bottom-xs-2 border-bottom-xs-style-dashed border-end-style-dashed',(coruscateArr[i-1]==1&&submitModel==1)||(coruscateArr[i-1]==0&&submitModel==0)?'coruscate':'']" @click="addAmount(item[1],i-1)" :style="{'border-color':submitModel==0?'#ca5a47 !important':'#78a5ce !important'}">
                                                <label class="text-sm-vertical small mx-md-1 mx-lg-0 mx-xl-1" :class="[idx==1?'text-warning':idx==2?'text-danger':'']">{{ item[0] }}</label>
                                                <div v-for="j,index in chipsConvert(submited[item[1]][i-1]+submiting[item[1]][i-1])" :key="j" :style="'bottom:'+(2*index)+'px !important'" class="position-absolute start-50 translate-middle-x fs-6 mb-4 text-black">
                                                    <img class="w-2_5rem rounded-circle" :src="require('@/assets/images/room/chips_'+j+'.png')" alt="">
                                                    <span class="position-absolute start-50 top-50 fw-bold chips-text small">{{$root.chipsTxtConvert(j)}}</span>
                                                </div>
                                                <div class="position-absolute start-50 translate-middle-x text-black fw-bold fs-6 bottom-0">
                                                    <span class="small">
                                                        <small>{{submited[item[1]][i-1]+submiting[item[1]][i-1]>0?submited[item[1]][i-1]+submiting[item[1]][i-1]:''}}</small>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- 更多筹区 -->
                                    <div @click.stop v-if="isShowMoreAmount" class="position-absolute h-100 w-100 bg-dark rounded-10 d-flex flex-sm-column bg-opacity-75 flex-wrap justify-content-between align-items-center text-black fs-6 p-3 p-sm-0">
                                        <div v-for="i in amountList" :key="i" :class="{active:currAmountList.indexOf(i)!=-1}" @click="updateAmount(i)" class="position-relative ms-3 me-3 ms-sm-0 me-sm-1 chips rounded-circle" role="button">
                                            <img class="w-3rem rounded-circle chips-shadow" :src="require('@/assets/images/room/chips_'+i+'.png')" alt="">
                                            <span class="position-absolute start-50 top-50 fw-bold chips-text text-nowrap">{{$root.chipsTxtConvert(i)}}</span>
                                        </div>
                                        <div class="position-absolute end-0 top-0">
                                            <i @click="isShowMoreAmount=false" class="d-flex bi bi-x-circle-fill text-light fs-5" role="button"></i>
                                        </div>
                                        <div class="position-absolute bottom-0 start-50 translate-middle-x-end-y bg-dark bg-opacity-75 px-2 py-1 rounded-bottom index-1">
                                            <button type="button" class="btn btn-success btn-sm me-2" @click="saveAmount">{{$t("room.confirm")}}</button>
                                            <button type="button" class="btn btn-secondary btn-sm" @click="isShowMoreAmount=false">{{$t("room.cancel")}}</button>
                                        </div>
                                    </div>
                                </div>
                                <!-- 按钮区 -->
                                <div class="d-flex flex-sm-column justify-content-around text-nowrap order-3 mb-1 mb-sm-0">
                                    <button class="btn btn-warning bg-gradient text-light btn-shadow-right mb-sm-1" :class="{'disabled':!canClear}" :disabled="!canClear" @click="submitEvent('clear')">{{$t("room.clear")}}</button>
                                    <button class="btn btn-danger bg-gradient btn-shadow-right mb-sm-1" :class="{'disabled':!canCancel}" :disabled="!canCancel" @click="submitEvent('cancel')">{{$t("room.cancel")}}</button>
                                    <button class="btn btn-success bg-gradient btn-shadow-right" :class="{'disabled':!canConfirm}" :disabled="!canConfirm" @click="submitEvent('confirm')">{{$t("room.confirm")}}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- 牌区 -->
                    <div v-if="showPokerArr.length>0" class="d-flex position-absolute top-xs-0 top-sm-26 d-flex flex-column ms-sm-2 index-11 w-xs-100 h-xs-100 align-items-center align-items-sm-start justify-content-center justify-content-sm-start bg-xs-dark bg-opacity-75">
                        <div class="d-flex flex-column">
                            <div v-for="(item,i) in showPokerArr" :key="(item,i)" class="d-flex justify-content-between rounded h-4_5rem">
                                <div class="text-light text-vertical text-upright text-center fw-bold border rounded" :style="{'background':i==0?color.red:color.blue}">{{i==0?$t("common.z"):$t("common.x")}}{{i>0?i:''}}</div>
                                <div class="img-thumbnail" v-for="(val,j) in item" :key="val" :style="{'background':i==0?color.red:color.blue}">
                                    <div v-if="val" :class="[$root.getPokerByNum(val)['color'],((i+1)==currPokerXY[0]&&(j+1)==currPokerXY[1])?'rotate':'']" class="d-flex flex-column bg-light rounded p-1 h-100">
                                        <span class="fw-bold lh-1 small">{{ $root.getPokerByNum(val)["name"] }}</span>
                                        <div class="d-flex flex-grow-1 px-2 pb-2">
                                            <i :class="[$root.getPokerByNum(val)['shape']]" class="bi d-flex align-items-center justify-content-center fs-4 flex-grow-1"></i>
                                        </div>
                                    </div>
                                </div>
                                <div v-show="showPokerTxtArr[i]" class="text-light text-vertical text-upright text-center fw-bold border rounded" :style="{'background':i==0?color.red:color.blue}">{{showPokerTxtArr[i]}}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 池额 -->
                <div class="position-absolute top-0 end-0 d-flex flex-column bg-dark text-light bg-opacity-50 px-3 py-1 rounded me-2 mt-2 mt-sm-5 fw-bold">
                    <div class="text-center border-bottom">{{$t("video.pool")}}</div>
                    <div class="d-flex text-end small justify-content-between">
                        <div class="d-flex flex-column justify-content-end ms-2">
                            <i class="bi bi-1-circle"></i>
                            <i class="bi bi-2-circle"></i>
                            <i class="bi bi-3-circle"></i>
                        </div>
                        <div class="d-flex flex-column mx-3" :style="'color:'+color.red">
                            <span>{{$t("common.z")}}</span>
                            <span>{{pool.z[0]}}</span>
                            <span>{{pool.z[1]}}</span>
                            <span>{{pool.z[2]}}</span>
                        </div>
                        <div class="d-flex flex-column me-2" :style="'color:'+color.blue">
                            <span>{{$t("common.x")}}</span>
                            <span>{{pool.x[0]}}</span>
                            <span>{{pool.x[1]}}</span>
                            <span>{{pool.x[2]}}</span>
                        </div>
                    </div>
                    <div v-if="!$root.gameModel" class="border-top d-none d-sm-flex d-lg-none justify-content-between">
                        <div class="d-flex flex-column mx-2">
                            <span>{{$t("room.ingBet")}}</span>
                            <span>{{$t("room.edBet")}}</span>
                            <span>{{$t("room.allBet")}}</span>
                        </div>
                        <div class="d-flex flex-column me-2 text-end">
                            <span>{{getAmountCount("submiting")}}</span>
                            <span>{{getAmountCount("submited")}}</span>
                            <span>{{submitedCount+getAmountCount("submiting")}}</span>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
        <!-- 路单区 -->
        <div class="flex-grow-1 flex-sm-grow-0 h-sm-8rem position-relative">
            <canvas id="pearl" class="bg-white d-block min-w-100 min-h-100 mw-100 mh-100" v-set-size width="0" height="0" @mousemove.stop="showPoker($event,1)"></canvas>
            <div class="bg-dark bg-opacity-25 position-absolute" :style="pearlLayerStyle" @mouseout.stop="showPoker($event,0)"></div>
        </div>
        <!-- 换台区 -->
        <change-room :class="{'me-sm-0':roomInfo.isAr==1}" :game-list="gameList" :room-list="roomList" :count-list="countList" :table-id="roomInfo.id" :active-game="activeGame" :user-info="userInfo" :pearl-obj="pearlObj" @change-room-list="changeRoomList" @go-room="goRoom" @get-room-pearl="getRoomPearl"></change-room>
        <!-- 音效 -->
        <audio-player v-show="false" ref="startAudioPlayer" :isLoop="true" :isAutoPlayNext="false" :muted="!audio.startAudio" :audio-list="startAudioList.map(elm => elm.url)" />
        <audio-player v-show="false" ref="endAudioPlayer" :isLoop="true" :isAutoPlayNext="false" :muted="!audio.startAudio" :audio-list="endAudioList.map(elm => elm.url)" />
        <audio-player v-show="false" ref="tenAudioPlayer" :isLoop="true" :isAutoPlayNext="false" :muted="!audio.tenAudio" :audio-list="tenAudioList.map(elm => elm.url)" />
    
        <!-- 赔率说明模态框 -->
        <div class="modal fade" id="oddsModal">
            <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
                <div class="modal-content bg-cyan">

                    <!-- 模态框头部 -->
                    <div class="modal-header">
                        <h4 class="modal-title">{{$t("room.oddsExplain")}}</h4>
                        <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                    </div>

                    <!-- 模态框内容 -->
                    <div class="modal-body">
                        <div class="table-responsive">
                            <table class="table table-striped table-borderless table-hover mb-0">
                                <thead class="text-light bg-primary bg-opacity-50">
                                    <tr>
                                        <th>{{$t("room.oddsName")}}</th>
                                        <th>{{$t("room.oddsWin")}}</th>
                                        <th>{{$t("room.oddsLose")}}</th>
                                    </tr>
                                </thead>
                                <tbody class=" bg-primary bg-opacity-25">
                                    <tr>
                                        <td class="text-light fw-bold">{{ $t('room.singleCount') }}</td>
                                        <td class="text-light">1：{{odds.p}}</td>
                                        <td class="text-light">1：{{Math.ceil(odds.p)}}</td>
                                    </tr>
                                    <tr v-for="item,i of odds.f" :key="i" class="text-nowrap">
                                        <td class="text-light fw-bold">{{ $t('room.doubleCount') }}{{oddsFName[i]}}</td>
                                        <td class="text-light">1：{{ item }}</td>
                                        <td class="text-light">1：{{ Math.ceil(item) }}</td>
                                    </tr>
                                    <tr>
                                        <td class="text-light fw-bold">{{ $t('room.multiplesCount') }}</td>
                                        <td class="text-light" colspan="2">{{ $t("room.multiplesExplain") }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        
                    </div>

                    <!-- 模态框底部 -->
                    <div class="modal-footer">
                        <button type="button" class="btn btn-danger" data-bs-dismiss="modal">{{$t("common.close")}}</button>
                    </div>

                </div>
            </div>
        </div>
        <!-- 弹出框 -->
        <modal :user-info="userInfo" :audio="audio" :curr-modal="currModal" :video-line-list="videoLineList" 
            :curr-video-line="currVideoLine" :recharge-funds-type="rechargeFundsType" :currency-list="currencyList" :recharge-result="rechargeResult"
            :withdraw-funds-type="withdrawFundsType" :source-alipay-list="sourceAlipayList" :source-bank-list="sourceBankList" :source-coin-list="sourceCoinList"
            :withdraw-limit="withdrawLimit" :withdraw-result="withdrawResult" :updatepwd-result="updatepwdResult" :qr-text="qrText" :problem-list="problemList"
            :addquestion-result="addquestionResult" :setuserchannel-result="setuserchannelResult" :deluserchannel-result="deluserchannelResult"
            :getbetlog-data="getbetlogData" :getrecharge-data="getrechargeData" :getwithdraw-data="getwithdrawData"
            @close-modal="currModal=''" @set-user-info="v=>$emit('set-user-info',v)"></modal>
    </div>
</template>
<script>
import {COMMAND,GAME_ROW,COLOR,LAYER_TYPE,GAME_ID,RESULT,SUBMIT_RESULT,CANCEL_RESULT,RATIO,AMOUTN_ARR} from "@/assets/js/config.js"
import mediaVideo from "@/components/public/video.vue";
import navbarVertical from '@/components/public/navbarVertical.vue';
import navbar from '@/components/public/navbar.vue';
import modal from '@/components/public/modal.vue';
import notice from '@/components/public/notice.vue';
import changeRoom from '@/components/public/changeRoom.vue'
import { useLayer } from '@/assets/js/useLayer';
import { getDeviceType,formatDate } from '@/assets/js/common.js'
export default {
    components:{
        mediaVideo,
        changeRoom,
        navbarVertical,
        navbar,
        modal,
        notice
    },
    props:{
        currVideoLine:"",//当前视频线路id
        userInfo:{
            type:Object,
            default:{
                name:"",//用户名
				guid:"",//用户guid
				realCoin:0,//真人币
				esportsCoin:0//竞技币
            }
        },
        audio:{
            type:Object,
            default:{
                bgAudio:true,//背景音效开关
                startAudio:true,//开始/结束音效开关
                resultAudio:true,//开注音效
                tenAudio:true,//最后10秒音效
            }
        },
        videoLineList:{
            type:Array,
            default:[]
        },//视频线路列表
        hasChatMsg:false,//是否有未读的客服消息
        isPageReady:false,//页面是否加载完毕
        isFullscreen:{
            type:Boolean,
            default:false
        },//当前是否全屏
    },
    data() {
        return {
            color:COLOR,//获取颜色配置
            roomInfo:JSON.parse(this.$route.params.roomInfo),//台面信息
            countdown:0,//倒计时
            videoList:[],//视频线路地址，当前支持最多两个
            roomList:[],//切换台面的台面列表
            pearlArr:[],//台面路单列表
            pool:{z:[0,0,0],x:[0,0,0]},//池
            limitred:{p:[0,0],d:[0,0],f:[0,0]},//限红
            gameList:this.$route.params.gameList,//游戏列表
            countList:[],//路单统计列表
            currentGame:this.$route.params.enterGame,//当前游戏
            activeGame:this.$route.params.enterGame,//当前游戏列表选中项
            enterGame:0,//当前将要进入的游戏
            pearlObj: {
                id:0,//台面id
                pearl:[]//路单数据
            },//要重绘路单的对象
            submitModel:1,//提交模式(0:z 1:x)
            amountList:AMOUTN_ARR,//全部额度列表
            currAmountList:[10,100,1000,10000,100000],//选中的额度列表
            currAmountListSort:[10,100,1000,10000,100000],//排序后的选中额度列表
            amount:10,//选中额度
            submited:{
                p:[0,0,0],//平
                f:[0,0,0],//翻
                d:[0,0,0],//多
            },//已提交的额度列表
            submiting:{
                p:[0,0,0],//平
                f:[0,0,0],//翻
                d:[0,0,0],//多
            },//将要提交的额度列表
            submitedCount:0,//全部模式已提交的总额
            canClear:false,//是否可以点击清除
            canCancel:false,//是否可以点击取消
            canConfirm:false,//是否可以点击确定
            startAudioList:[
                {
                    url: require('@/assets/media/common/bet_start_1.mp3')
                },
                {
                    url: require('@/assets/media/common/bet_start_2.mp3')
                },
                {
                    url: require('@/assets/media/common/bet_start_3.mp3')
                },
                {
                    url: require('@/assets/media/common/bet_start_4.mp3')
                },
                {
                    url: require('@/assets/media/common/bet_start_5.mp3')
                },
                {
                    url: require('@/assets/media/common/bet_start_6.mp3')
                },
            ],//开始音效
            endAudioList:[
                {
                    url:require('@/assets/media/common/bet_stop.mp3')
                }
            ],//结束音效
            tenAudioList:[
                {
                    url:require('@/assets/media/common/time_ten_1.mp3')
                },
                {
                    url:require('@/assets/media/common/time_ten_2.mp3')
                },
                {
                    url:require('@/assets/media/common/time_ten_3.mp3')
                }
            ],//最后10秒音效
            coruscateArr:[],//x闪烁位集合，z相反，存放将要闪烁的位置，值为1时闪
            pokerArr:[],//当前台面牌组
            showPokerArr:[],//要显示的牌组
            showPokerTxtArr:[],//要显示的牌组文本
            currPokerXY:[0,0],//当前要翻转的扑克
            isShowMoreAmount:false,//是否显示更多筹码
            pearlLayerStyle:{
                width:0,
                height:0,
                left:0,
                top:0,
                display:'none'
            },//鼠标移上路单的遮罩层
            point:[0,0],//鼠标在路单上的坐标点
            isScale:false,//是否放大提交区和视频区
            isShowPoker:true,//鼠标移入画布是否显示牌
            showOdds:"",//要显示的赔率
            odds:{p:"0",f:[0,0,0,0,0]},//赔率
            canvasObj:null,//画布对象
            isShowTip:false,//是否显示赔率说明
            oddsFName:{
                6:this.$t("room.cardNormal"),
                7:"("+this.$t("app.great")+this.$t("common.seven")+")",
                8:"("+this.$t("app.great")+this.$t("common.eight")+")",
                9:"("+this.$t("app.great")+this.$t("common.nine")+")",
                10:"("+this.$t("room.resultGreat")+")",
                "silver":"("+this.$t("room.resultSilver")+")",
                "gold":"("+this.$t("room.resultGold")+")",
                "boom":"("+this.$t("room.resultBomb")+")"
            },//赔率翻名称
            notice:"",//公告内容
            currModal:"",//当前显示模态框

            rechargeFundsType:[],//充值支付方式列表
            currencyList:[],//充值币种列表
            rechargeResult:{},//充值返回对象
            withdrawFundsType:[],//提现提现方式列表
            sourceAlipayList:[],//支付宝资金渠道
            sourceBankList:[],//银联资金渠道
            sourceCoinList:[],//虚拟币资金渠道
            withdrawLimit:{
                min:0,
                max:0
            },//提现限额
            withdrawResult:{},//提现返回对象
            updatepwdResult:{},//修改密码返回对象
            qrText:"",//分享二维码文本
            problemList:[],//问题反馈列表
            addquestionResult:{},//问题反馈返回对象
            setuserchannelResult:{},//操作用户资金方式返回对象
            deluserchannelResult:{},//删除用户资金方式返回对象
            getbetlogData:{},//获取提交记录返回数据
            getrechargeData:{},//获取充值记录返回数据
            getwithdrawData:{},//获取提现记录返回数据
        };
    },
    directives: {
        'set-size': {
            mounted(elem,binding){
                let _this=binding.instance;
                //设置画布大小
                _this.$root.setCanvasSize(elem);
                //绘制画布表格
                _this.canvasObj = _this.$root.drawCanvasTable(elem,Object.keys(GAME_ROW).find(key=>GAME_ROW[key].indexOf(Number(_this.currentGame))!=-1),true,1.5);
                //请求数据
                _this.canvasObj&&_this.$emit("ws-send",COMMAND.all_getroompearl, _this.currentGame,_this.roomInfo.id,_this.canvasObj.column,"table", _this.userInfo.name, _this.userInfo.guid);
                //获取台面扑克
                _this.canvasObj&&_this.$emit("ws-send",COMMAND.cor_getroompoker, _this.roomInfo.id,_this.roomInfo.boot,_this.canvasObj.column,_this.userInfo.name, _this.userInfo.guid);
            }
        },
    },
    mounted() {
        //获取公告
        this.$emit('ws-send', COMMAND.all_getnotice, this.currentGame, this.userInfo.name, this.userInfo.guid);
        //获取台面视频
        if(this.currVideoLine==""){
            useLayer({str:this.$t("video.noVideoLine")});
        }else{
            this.$emit("ws-send",COMMAND.all_getvideo,this.roomInfo.id,this.currVideoLine,this.userInfo.name,this.userInfo.guid);
        }
        //获取倒计时
        this.$emit("ws-send",COMMAND.all_getcountdown,this.currentGame,this.roomInfo.id,this.userInfo.name,this.userInfo.guid);
        //获取赔率
        this.$emit("ws-send",COMMAND.cor_getodds,this.roomInfo.id,this.userInfo.name,this.userInfo.guid);
        //获取台面列表
        this.$emit("ws-send",COMMAND.all_getroomlist, this.currentGame,this.userInfo.name, this.userInfo.guid);
        //获取限红
        this.$emit("ws-send",COMMAND.cor_getlimitred,this.userInfo.name, this.userInfo.guid);
        //获取各位置已提交额度
        this.$emit("ws-send",COMMAND.cor_getbetdetail,this.roomInfo.id,this.roomInfo.boot,this.roomInfo.innings,this.userInfo.name,this.userInfo.guid);
        
        //获取分享地址
        this.$emit("ws-send",COMMAND.all_getshareurl,this.userInfo.name,this.userInfo.guid);
        //获取用户资金账号
        this.$emit("ws-send",COMMAND.all_getuserchannel,"list",this.userInfo.name,this.userInfo.guid);
        //获取资金方式(充值)
        this.$emit("ws-send",COMMAND.all_getbankrollway,1,this.userInfo.name,this.userInfo.guid);
        //获取资金方式(提现)
        this.$emit("ws-send",COMMAND.all_getbankrollway,2,this.userInfo.name,this.userInfo.guid);
        
        //获取问题反馈
        this.$emit("ws-send",COMMAND.all_getquestion,1,100,this.userInfo.name,this.userInfo.guid);
        //获取提现限额
        this.$emit("ws-send",COMMAND.all_getwithdrawlimit,this.userInfo.name,this.userInfo.guid);

        this.setWsMessage();
        addEventListener("resize",this.setResize);
    },
    unmounted() {
        removeEventListener("resize",this.setResize);
    },
    methods: {
        /**
         * 设置websocket响应事件
         */
        setWsMessage(){
            this.$emit("ws-msg",(data)=>{
                switch (data.command) {
                    case COMMAND.all_getroompearl:
                        if(data.id==this.roomInfo.id&&data.useto=="table"){//获取的是当前台面的数据
                            this.pearlArr=data.pearl;
                        }else{//用于切换台面获取数据
                            this.pearlObj={
                                id:data.id,
                                pearl:data.pearl
                            }
                            this.countList[data.id]=data.count;
                        }
                        break;
                    case COMMAND.all_getvideo:
                        let videoArr=data.video;
                        let list=[];//拼装好的视频地址数组
                        for (let i = 0; i < videoArr.length; i++) {
                            list.push(data.flvline+'/'+videoArr[i]+'.flv');
                        }
                        this.videoList=list;
                        break;
                    case COMMAND.cor_getodds:
                        this.odds=data.odds;
                        break;
                    case COMMAND.cor_getlimitred:
                        this.limitred=data.limitred;
                        //设置额度，优先取与限红一致的额度，个数超出则去除，不够则补足
                        let limitredArr=this.limitred.p.concat(this.limitred.f).concat(this.limitred.d);
                        let noRepeatArr=[];//去掉重复项后的限红数组
                        let chooseArr=[];//筛选出的额度数组
                        for (let i = 0; i < limitredArr.length; i++) {//限红去重
                            noRepeatArr.indexOf(limitredArr[i])==-1&&noRepeatArr.push(limitredArr[i]);
                        }
                        for (let i = 0; i < this.amountList.length; i++) {//挑选与限红一样的额度
                            noRepeatArr.indexOf(this.amountList[i])!=-1&&chooseArr.push(this.amountList[i]);
                        }
                        if(chooseArr.length>5){//多于5个
                            this.currAmountList=chooseArr.slice(0,5);//取前5个
                        }else if(chooseArr.length<5){//少于5个，等于5个不考虑
                            for (let i = 0; i < this.amountList.length; i++) {//用额度列表中的额度补足
                                chooseArr.indexOf(this.amountList[i])==-1&&chooseArr.push(this.amountList[i]);
                                if(chooseArr.length==5){
                                    break;
                                }
                            }
                            this.currAmountList=chooseArr;
                        }
                        this.currAmountListSort=this.currAmountList.slice();
                        this.currAmountListSort.sort((a,b)=>a-b);
                        break;
                    case COMMAND.all_getcountdown:
                        this.countdown=data.countdown;
                        break;
                    case COMMAND.all_startgame:
                        if(data.id==this.roomInfo.id){
                            this.countdown=data.countdown;
                            this.roomInfo.boot=data.boot;
                            this.roomInfo.innings=data.innings;
                            this.$refs.startAudioPlayer.playNext();
                        }
                        let item=this.roomList.find(v=>v.id==data.id);//在台面列表中找到与当前开始的游戏匹配的项
                        if(typeof item!="undefined"){//台面存在且可见
                            if(item.id==data.id){//当前开始计时的游戏存在于台面列表中
                                this.listCountdown(data.id,data.countdown);
                                item.boot=data.boot;
                                item.innings=data.innings;
                                item.workerimg=data.workerimg;
                                item.worker=data.worker;
                            }
                        }
                        break;
                    case COMMAND.all_endgame:
                        if(data.id==this.roomInfo.id){
                            this.resetAmount("submiting");
                            this.countdown=0;
                            this.$refs.endAudioPlayer.playNext();
                        }else{
                            let roomItem=this.roomList.find(v=>v.id==data.id);
                            typeof roomItem!="undefined"&&(roomItem.countdown=0);
                        }
                        break;
                    case COMMAND.cor_gameresult:
                        if(data.id==this.roomInfo.id){
                            this.currPokerXY=[0,0];
                            this.pearlArr.push(data.pearl);
                            this.setCoruscate(data.pearl);
                            if(data.poker.length>0){//有牌
                                this.showPokerArr=data.poker;
                                this.showPokerTxtArr=this.resultToTxt(data.pearl);
                                this.pokerArr.push(data.poker);
                                setTimeout(() => {
                                    this.showPokerArr=[];
                                    this.showPokerTxtArr=[];
                                }, 8000);
                            }
                        }
                        typeof this.roomList.find(v=>v.id==data.id)!="undefined"&&this.getRoomPearl(data.id,this.$root.columnList[data.id]);//台面存在且可见
                        break;
                    case COMMAND.all_getcurrpoker:
                        if(data.id==this.roomInfo.id){
                            this.isShowPoker=false;
                            this.showPokerArr=data.poker;
                            this.currPokerXY=data.index;
                        }
                        break;
                    case COMMAND.all_settlement:
                        if(data.id==this.roomInfo.id){
                            this.isShowPoker=true;
                            if(this.submitedCount>0){
                                this.submitedCount>0&&this.$emit("ws-send",COMMAND.all_getgain,this.currentGame,this.roomInfo.id,this.roomInfo.boot,this.roomInfo.innings,this.userInfo.name,this.userInfo.guid);//有提交过，则获取输赢
                                this.resetAmount("all");
                                this.submitedCount=0;
                            }
                        }
                        break;
                    case COMMAND.all_getgain:
                        useLayer({str:(data.win > Math.abs(data.lose)? this.$t("common.win"):this.$t("common.lose"))+Math.abs(data.win - data.lose)});
                        this.$emit('set-user-info', {
                            realCoin:data.realmoney
                        });
                        break;
                    case COMMAND.cor_receivepool:
                        data.id==this.roomInfo.id&&(this.pool=data.pool);
                        break;
                    case COMMAND.cor_submitbet:
                        switch (data.result) {
                            case SUBMIT_RESULT.success:
                                useLayer({str:this.$t("common.subSuccess")});
                                this.$emit('set-user-info', {
                                    realCoin:data.realmoney
                                });
                                for(let i in this.submited){
                                    for (let j = 0; j < this.submited[i].length; j++) {
                                        this.submited[i][j]+=this.submiting[i][j];
                                        this.submitedCount+=this.submiting[i][j];
                                    }
                                }
                                this.resetAmount("submiting");//清空将提交额度列表
                                break;
                            case SUBMIT_RESULT.ban:
                                useLayer({str:this.$t("common.subBan")});
                                break;
                            case SUBMIT_RESULT.unmoney:
                                useLayer({str:this.$t("common.unMoney")});
                                break;
                            case SUBMIT_RESULT.countdown:
                                useLayer({str:this.$t("common.subCountdown")});
                                break;
                            case SUBMIT_RESULT.service:
                                useLayer({str:this.$t("common.service")});
                                break;
                            default:
                                useLayer({str:this.$t("common.subErr")});
                                break;
                        }
                        break;
                    case COMMAND.all_cancelbet:
                        if(data.result==CANCEL_RESULT.success){
                            this.resetAmount("all");
                            this.submitedCount=0;
                            this.$emit('set-user-info', {
                                realCoin:data.realmoney
                            });
                            useLayer({str:this.$t("common.cancelSuccess")});
                        }else{
                            useLayer({str:this.$t("common.cancelErr")});
                        }
                        break;
                    case COMMAND.all_changeboot:
                        if(data.id==this.roomInfo.id){
                            this.pearlArr=[];
                            this.pokerArr=[];
                            this.roomInfo.boot=data.boot;
                            this.roomInfo.innings=data.innings;
                        }
                        break;
                    case COMMAND.cor_getbetdetail:
                        let model;
                        if(this.submitModel==0){
                            model="z";
                        }else if(this.submitModel==1){
                            model="x";
                        }
                        this.submited={
                            p:[data.detail[model][0],data.detail[model][3],data.detail[model][6]],//平
                            f:[data.detail[model][1],data.detail[model][4],data.detail[model][7]],//翻
                            d:[data.detail[model][2],data.detail[model][5],data.detail[model][8]],//多
                        }
                        this.submitedCount= data.detail.z.concat(data.detail.x).reduce((prev,val)=>prev+val);
                        break;
                    case COMMAND.all_getroomlist:
                        if(this.$root.isTryUser){
                            this.roomList=data.list.filter(v=>window[USER].tryUserUnTableId.indexOf(Number(v.id))==-1);
                        }else{
                            this.roomList=data.list;
                        }
                        for (let item of this.roomList) {
                            this.listCountdown(item.id,item.countdown);
                        }
                        break;
                    case COMMAND.all_entertable:
                        if(data.result!=RESULT.enterTable.success){
                            useLayer({str:this.$t("home.goRoomErr")});
                            return;
                        }
                        if(this.activeGame==GAME_ID.cor){//与当前游戏一致则只修改参数
                            this.$refs.startAudioPlayer.pause();
                            this.$refs.endAudioPlayer.pause();
                            this.$refs.tenAudioPlayer.pause();
                            //更改台面信息
                            this.roomInfo.id=data.id;
                            this.roomInfo.rid=data.rid;
                            this.roomInfo.room=data.room;
                            this.roomInfo.table=data.table;
                            this.roomInfo.boot=data.boot;
                            this.roomInfo.innings=data.innings;
                            this.roomInfo.isRebate=data.iscommission;
                            this.roomInfo.second=this.roomList.find(v=>v.id==data.id).second;
                            this.roomInfo.isAr=this.roomList.find(v=>v.id==data.id).isar;
                            this.countdown=this.roomList.find(v=>v.id==data.id).countdown;
                            this.currentGame=this.enterGame;
                            this.showPokerArr=[];
                            this.showPokerTxtArr=[];
                            this.coruscateArr=[];
                            this.pokerArr=[];
                            //获取台面路单
                            this.$emit("ws-send",COMMAND.all_getroompearl, this.enterGame,data.id,this.canvasObj.column,"table", this.userInfo.name, this.userInfo.guid);
                            //获取台面视频
                            if(this.currVideoLine==""){
                                useLayer({str:this.$t("video.noVideoLine")});
                            }else{
                                this.$emit("ws-send",COMMAND.all_getvideo,this.roomInfo.id,this.currVideoLine,this.userInfo.name,this.userInfo.guid);
                            }
                            //获取各位置已提交额度
                            this.$emit("ws-send",COMMAND.cor_getbetdetail,this.roomInfo.id,this.roomInfo.boot,this.roomInfo.innings,this.userInfo.name,this.userInfo.guid);
                            //获取赔率
                            this.$emit("ws-send",COMMAND.cor_getodds,this.roomInfo.id,this.userInfo.name,this.userInfo.guid);
                            //获取台面扑克
                            this.$emit("ws-send",COMMAND.cor_getroompoker, this.roomInfo.id,this.roomInfo.boot,this.canvasObj.column,this.userInfo.name, this.userInfo.guid);
                        }else{//不一致则进行页面跳转
                            let room=this.roomList.find(v=>v.id==data.id);
                            this.$root.enterTable(this.activeGame,{
                                guid:this.guid,
                                roomInfo:JSON.stringify({
                                    id:data.id,
                                    rid:data.rid,
                                    room:data.room,
                                    table:data.table,
                                    boot:data.boot,
                                    innings:data.innings,
                                    isRebate:data.iscommission,
                                    second:room.second,
                                    isAr:room.isar,
                                    carry:room.carry,
                                }),
                                enterGame:this.enterGame,
                                gameList:this.gameList,
                            })
                        }
                        break;
                    case COMMAND.cor_getroompoker:
                        let arr=data.list;
                        for (let i = 0; i < arr.length; i++) {
                            arr[i].xp.unshift(arr[i].zp);
                            this.pokerArr.push(arr[i].xp);
                        }
                        break;
                    case COMMAND.all_refresh:
                        data.id==this.roomInfo.id&&this.$emit("ws-send",COMMAND.all_getroompearl, data.game,data.id,this.canvasObj.column,"table", this.userInfo.name, this.userInfo.guid);
                        break;
                    case COMMAND.all_getnotice:
                        this.notice=data.str;
                        break;
                    case COMMAND.bac_gameresult:
                        //处理当前cor台但切换台面为bac台时数据不更新问题，临时解决
                        this.activeGame==GAME_ID.bac&&this.$emit("ws-send",COMMAND.all_getroompearl, this.activeGame,data.id,200,"list", this.userInfo.name, this.userInfo.guid);
                        break;
                    case COMMAND.all_roomstatechange:
                        let r=this.roomList.find(v=>v.id==data.roomid);
                        if(typeof r!="undefined"){//台面存在且可见
                            r.rstate=data.state;
                        }
                        break;
                    case COMMAND.all_workerupdate:
                        let room=this.roomList.find(v=>v.id==data.table);//在台面列表中找到与当前开始的游戏匹配的项
                        if(typeof room!="undefined"){//台面存在且可见
                            if(room.id==data.table){//当前变更工作者的台面存在于台面列表中
                                room.workerimg=data.img;
                                room.worker=data.name;
                            }
                        }
                        break;
                        case COMMAND.all_getbankrollway:
                        if(data.type==1){//充值
                            this.rechargeFundsType=data.list;
                        }else if(data.type==2){//提现
                            this.withdrawFundsType=data.list;
                        }
                        break;
                    case COMMAND.all_getcoin:
                        this.currencyList=data.list;
                        break;
                    case COMMAND.all_recharge:
                        this.rechargeResult=data;
                        break;
                    case COMMAND.all_getuserchannel:
                        this.sourceAlipayList=data.alipay;
                        this.sourceBankList=data.bank;
                        this.sourceCoinList=data.coin;
                        break;
                    case COMMAND.all_getwithdrawlimit:
                        this.withdrawLimit.min=data.min;
                        this.withdrawLimit.max=data.max;
                        break;
                    case COMMAND.all_withdraw:
                        this.withdrawResult=data;
                        break;
                    case COMMAND.all_updatepwd:
                        this.updatepwdResult=data;
                        break;
                    case COMMAND.all_getshareurl:
                        this.qrText=data.url;
                        break;
                    case COMMAND.all_getquestion:
                        this.problemList=data.list.reverse();
                        break;
                    case COMMAND.all_addquestion:
                        this.addquestionResult=data;
                        break;
                    case COMMAND.all_setuserchannel:
                        this.setuserchannelResult=data;
                        break;
                    case COMMAND.all_deluserchannel:
                        this.deluserchannelResult=data;
                        break;
                    case COMMAND.all_getbetlog:
                        this.getbetlogData=data;
                        break;
                    case COMMAND.all_getrecharge:
                        this.getrechargeData=data;
                        break;
                    case COMMAND.all_getwithdraw:
                        this.getwithdrawData=data;
                        break;
                }
            })
        },
        /**
         * 倒计时
         * @param {Number} tableId 要计时的台面id
         * @param {Number} countdown 计时起始值
         */
        listCountdown(tableId,countdown){
            let room=this.roomList.find(v=>v.id==tableId);
            room.countdown=countdown;
            var timer = setInterval(()=>{
                if(room.countdown!=countdown){//两个值不一样说明台面计时被重新赋值了，应当停掉定时器
                    clearInterval(timer);
                    return;
                }
                if(room.countdown <= 0){
                    room.countdown=0;
                    clearInterval(timer);
                    return;
                }
                room.countdown--;
                countdown--;
            }, 1000);
        },
        /**
         * 进入台面
         */
        goRoom(id,gameId){
            if(id==this.roomInfo.id){
                useLayer({str:this.$t("roomlist.hearTip")});
                return;
            }else{
                //进入房间
                this.enterGame=gameId;
                this.$emit("ws-send",COMMAND.all_entertable, gameId, id,this.userInfo.name, this.userInfo.guid);
            }
        },
        /**
         * 为位置追加将要提交的额度
         * @param {String} type 位置类型(z、x、h、zd、xd)
         * @param {Int32Array} i 下标
         */
        addAmount(type,i){
            let limitred=this.limitred;//获取限红
            let amount=this.amount;
            if(amount==Infinity){//如果选择allin
                amount=this.userInfo.realCoin-this.getAmountCount("submiting");
            }
            if(this.countdown<=0){
                useLayer({str:this.$t("room.noStartGame")});
                return;
            }else if(this.userInfo.realCoin<this.getAmountCount("submiting")+amount){//余额不足
                useLayer({str:this.$t("common.unMoney")});
                return;
            }
            if(amount!=Infinity){//非满额度
                if(amount>limitred[type][1]){//单次高于上限
                    useLayer({str:this.$t("room.higherLimitred")+limitred[type][1]});
                    return;
                }else if(this.submiting[type][i]+this.submited[type][i]+amount>limitred[type][1]){//多次并高于上限
                    useLayer({str:this.$t("room.laveLimitred")+(limitred[type][1]-this.submiting[type][i]-this.submited[type][i])});
                    return;
                }
            }else{//满额度
                amount=limitred[type][1]-this.submiting[type][i]-this.submited[type][i];
                if(amount==0){
                    useLayer({str:this.$t("room.fullAmount")});
                    return;
                }
            }
            this.submiting[type][i]=this.submiting[type][i]+amount;
        },
        /**
         * 额度换算
         */
        chipsConvert(amount){
            let arr=[];//最终转换成的额度
            let amountArr = this.amountList.slice(0,this.amountList.length-1);//取除了梭哈之外的额度
            for (let i = amountArr.length-1; i >= 0; i--) {
                if(amount>=amountArr[i]){
                    let num=Math.floor(amount/amountArr[i]);//获取要换算的额度最多能换到多少个当前遍历到的额度
                    for (let j = 0; j < num; j++) {
                        arr.push(amountArr[i]);
                    }
                    amount-=num*amountArr[i];
                }
            }
            return arr;
        },
        /**
         * 提交事件
         */
        submitEvent(event){
            switch (event) {
                case "clear"://点击清除
                    this.canClear&&(this.submiting={
                        p:[0,0,0],//平
                        f:[0,0,0],//翻
                        d:[0,0,0],//多
                    });//将要提交的额度列表;
                    break;
                case "cancel"://点击取消
                    if(this.canCancel){
                        useLayer({
                            type:LAYER_TYPE.confirm,
                            str:this.$t("common.isCancel"),
                            btn:[this.$t("common.yes"),this.$t("common.no")],
                            yesFn:(i)=>{
                                layer.close(i);
                                this.$emit("ws-send",COMMAND.all_cancelbet,this.currentGame,this.roomInfo.id,this.roomInfo.boot,this.roomInfo.innings,this.userInfo.name,this.userInfo.guid);
                            }
                        })
                    }
                    break;
                case "confirm"://点击确定
                    if(this.canConfirm){
                        this.$emit("ws-send",COMMAND.cor_submitbet,this.currentGame,this.roomInfo.id,this.roomInfo.boot,this.roomInfo.innings,
                            this.getAmountCount("submiting"),this.getAmountCount("submiting")+this.submitedCount,this.submitModel==0?'z':'x',
                            String([this.submiting.p[0],this.submiting.f[0],this.submiting.d[0],this.submiting.p[1],this.submiting.f[1],this.submiting.d[1],this.submiting.p[2],this.submiting.f[2],this.submiting.d[2],]),
                            this.userInfo.name,this.$root.ip.cip,getDeviceType(),this.userInfo.guid);
                    }
                    break;
            }
        },
        /**
         * 重置额度
         */
        resetAmount(type){
            if(type=="submiting"||type=="all"){//预投或总投
                for (let i in this.submiting) {
                    for(let j in this.submiting[i]){
                        this.submiting[i][j]=0;
                    }
                }
            }
            if(type=="submited"||type=="all"){//已投或总投
                for (let i in this.submited) {
                    for(let j in this.submited[i]){
                        this.submited[i][j]=0;
                    }
                }
            }
        },
        /**
         * 获取额度
         * @param {String} type 额度类型(submited、submiting、all)
         */
        getAmountCount(type){
            let count=0;
            if(type=="submiting"||type=="all"){//预投或总投
                count+=Object.values(this.submiting).flat().reduce((prev,val)=>prev+val);
            }
            if(type=="submited"||type=="all"){//已投或总投
                count+=Object.values(this.submited).flat().reduce((prev,val)=>prev+val);
            }
            return count;
        },
        /**
         * 设置闪烁位
         */
        setCoruscate(pearl,fn){
            let arr=[0,0,0];
            pearl[1]>0&&(arr[0]=1);
            pearl[2]>0&&(arr[1]=1);
            pearl[3]>0&&(arr[2]=1);
            this.coruscateArr=arr;
            setTimeout(() => {
                this.coruscateArr=[];
                fn&&fn();
            }, 3000);
        },
        /**
         * 设置窗口大小改变
         */
        setResize(){
            this.drawPearl(this.pearlArr);
        },
        /**
         * 切换提交模式
         */
        changeModel(model,e){
            let fn=()=>{
                this.submitModel=model;
                this.resetAmount("submiting");
                //获取各位置已提交额度
                this.$emit("ws-send",COMMAND.cor_getbetdetail,this.roomInfo.id,this.roomInfo.boot,this.roomInfo.innings,this.userInfo.name,this.userInfo.guid);
            }
            if(this.getAmountCount("submiting")!=0){
                this.submitModel=model==1?0:1;
                useLayer({
                    type:LAYER_TYPE.confirm,
                    str:this.$t("room.hasUnSubmit"),
                    btn:[this.$t("common.yes"),this.$t("common.no")],
                    yesFn:(i)=>{
                        fn();
                        layer.close(i);
                    }
                })
            }else{
                fn();
            }
        },
        /**
         * 改变台面列表
         */
        changeRoomList(gameId){
            this.activeGame=gameId;
            //获取台面列表
            this.$emit("ws-send",COMMAND.all_getroomlist, gameId,this.userInfo.name, this.userInfo.guid);
        },
        /**
         * 修改常用额度
         */
        updateAmount(amount){
            // let index=this.currAmountList.indexOf(amount);
            // if(index==-1){//当前点击额度未选中
            //     this.currAmountList.shift();//去掉最先加入的额度
            //     this.currAmountList.push(amount);//加入点击项
            // }
            // this.currAmountListSort=this.currAmountList.slice();
            // this.currAmountListSort.sort((a,b)=>a-b);
            // this.amount=this.currAmountListSort[0];//设置额度为排序后的额度的第一项
            let index=this.currAmountList.indexOf(amount);//获取当前额度在选中列表中的位置
            if(index!=-1){//当前点击额度已选中
                this.currAmountList.splice(index,1);//取消选中
            }else{//未选中
                if(this.roomInfo.isAr==1){
                    if(this.currAmountList.length==5){
                        useLayer({str:this.$t("room.chipsFull")});
                        return;
                    }
                }else{
                    if(this.currAmountList.length==7){//个数超出则提示
                        useLayer({str:this.$t("room.chipsFull")});
                        return;
                    }
                }
                this.currAmountList.push(amount);//加入点击项
            }
        },
         /**
         * 保存常用额度
         */
        saveAmount(){
            if(this.roomInfo.isAr==1){
                if(this.currAmountList.length>5||this.currAmountList.length<5){
                    useLayer({str:this.$t("room.pleaseSelect")+" 5 "+this.$t("room.chips")});
                    return;
                }
            }
            this.currAmountListSort=this.currAmountList.slice();
            this.currAmountListSort.sort((a,b)=>a-b);
            this.currAmountList=[];
            this.isShowMoreAmount=false;
        },
        /**
         * 将结果转换为文本
         */
        resultToTxt(pearlArr){
            let arr=[];
            let numArr=[this.$t("common.one"),this.$t("common.two"),this.$t("common.three"),this.$t("common.four"),this.$t("common.five"),this.$t("common.six"),this.$t("common.seven"),this.$t("common.eight"),this.$t("common.nine")];
            for (let i = 0; i < pearlArr.length; i++) {
                var text = Math.abs(pearlArr[i]);
                if (text == '10') {
                    text = this.$t("room.resultGreat");
                } else if (text == '20') {
                    text = this.$t("room.resultSilver");
                } else if (text == '30') {
                    text = this.$t("room.resultGold");
                } else if (text == '40') {
                    text = this.$t("room.resultBomb");
                } else if (text == '11'||text=='0') {
                    text = this.$t("room.resultNothing");
                }else{
                    text=this.$t("app.great")+numArr[text-1];
                }
                arr.push(text);
            }
            return arr;
        },
        /**
         * 显示牌
         * @param {Number} type 移入或移出
         */
        showPoker(e,type){
            if(type==1){//移入
                if(this.pearlLayerStyle.width!=0){
                    let cellWidth=this.pearlLayerStyle.width.replace('px','');
                    let cellHeight=this.pearlLayerStyle.height.replace('px','');
                    let column=(getComputedStyle(e.target).width.replace('px','')/cellWidth).toFixed()-1;//获取总列数减去一列标题列
                    let x = Math.ceil(e.offsetX / cellWidth)-1;//列
                    let y = Math.ceil(e.offsetY / cellHeight);//行
                    let pokerArr=this.pokerArr.slice(-column);
                    let poker=pokerArr[x-1];//获取对应位置的扑克
                    if((x!=this.point[0])&&y!=0&&x>0&&x<=column&&poker&&this.isShowPoker){//坐标点相比于上次有不同并且大于0行0列并且对应位置有扑克数据
                        this.point=[x,y];
                        this.$nextTick(()=>{
                            this.pearlLayerStyle.display='block';
                            this.pearlLayerStyle.left=x*cellWidth+'px';
                            this.pearlLayerStyle.top=(y-1)*cellHeight+'px';
                            this.showPokerArr=poker;
                            this.showPokerTxtArr=this.resultToTxt(this.pearlArr.slice(-column)[x-1]);
                        })
                    }
                }
            }else{//移出
                this.showPokerArr=[];
                this.showPokerTxtArr=[];
                this.pearlLayerStyle.display='none';
                this.point=[0,0];
            }
        },
        /**
         * 绘制路单
         */
        drawPearl(pearlArr){
            let elem=document.getElementById("pearl");
            //设置画布大小
            this.$root.setCanvasSize(elem);
            //绘制画布表格
            let obj = this.$root.drawCanvasTable(elem,Object.keys(GAME_ROW).find(key=>GAME_ROW[key].indexOf(Number(this.currentGame))!=-1),true,1.5);
            if(obj){//判断对象是否存在，防止视口被拉小到没有的时候找不到而报错
                this.pearlLayerStyle.width=obj.cellWidth/RATIO+'px';
                this.pearlLayerStyle.height=(obj.cellHeight/RATIO)*4+'px';
                this.pearlLayerStyle.left=obj.cellWidth/RATIO+'px';
                this.$root.drawPearl(obj,pearlArr.slice(-obj.column),null,this.currentGame);
            }
        },
        /**
         * 获取台面路单
         * @param {Number} tableId 台面id
         * @param {Number} column 获取的数据列数
         */
        getRoomPearl(tableId,column){
            this.$emit("ws-send",COMMAND.all_getroompearl, this.activeGame,tableId,this.activeGame==GAME_ID.bac?200:column,"list", this.userInfo.name, this.userInfo.guid);
        },
        /**
         * 显示更多额度
         */
        showMoreAmount(){
            this.isShowMoreAmount=!this.isShowMoreAmount;
            if(this.isShowMoreAmount){//显示状态下点击空白处隐藏
                document.onclick=(e)=>{
                    e.stopPropagation();
                    this.isShowMoreAmount=false;
                }
            }
        },
    },
    watch: {
        /**
         * 监听路单数据变化
         */
        pearlArr:{
            handler(newVal){
                this.drawPearl(newVal);
            },
            deep:true//深度监听
        },
        /**
         * 监听视频线路id改变
         */
        currVideoLine(newVal){
            if(newVal!=""){
                //获取台面视频
                this.$emit("ws-send",COMMAND.all_getvideo,this.roomInfo.id,newVal,this.userInfo.name,this.userInfo.guid);
            }else{
                this.videoList=null;
            }
        },
        /**
         * 监听倒计时改变
         */
        countdown(newVal){
            newVal>=0&&this.isScale==false&&(this.isScale=true);
            //一旦倒计时大于0，则每秒减1
            newVal==10&&this.$refs.tenAudioPlayer.playNext();
            if(newVal>0){
                setTimeout(()=>{
                    this.countdown--;
                    if(this.countdown<=0){
                        this.countdown=0;
                        this.$nextTick(()=>{
                            this.isScale=false;
                        })
                    }
                }, 1000);
            }
        },
        /**
         * 监听将要提交的额度变化
         */
        submiting:{
            handler(){
                if(this.getAmountCount("submiting")<=0){
                    this.canClear=false;
                    this.canConfirm=false;
                }else{
                    this.canClear=true;
                    this.canConfirm=true;
                }
            },
            deep:true
        },
        /**
         * 监听已提交过的额度变化
         */
        submited:{
            handler(){
                if(this.getAmountCount("submited")<=0){
                    this.canCancel=false;
                }else{
                    this.canCancel=true;
                }
            },
            deep:true
        },
    },
};
</script>