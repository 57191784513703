<style>
	@font-face {
		font-family: 'UnidreamLED';
		src: url('@/assets/font/UnidreamLED.eot');/* 兼容ie9 */
		src:url('@/assets/font/UnidreamLED.eot?#iefix')format('embedded-opentype'),/* 兼容ie6-8 */
		url('@/assets/font/UnidreamLED.woff') format('woff'),
		local('UnidreamLED'),url('@/assets/font/UnidreamLED.woff')
	}
	.vjs-has-started .vjs-control-bar{
        display: none !important;
    }
	*{
		cursor: url('@/assets/images/common/cursor.ico'), auto !important;
	}
   	.app{
		-webkit-user-select: none;
		-moz-user-select: none;
		-o-user-select: none;
		user-select: none;
	}
	.bg{
		/* background: linear-gradient(90deg, #081118 0, #173247 50%, #081118 100%); */
		background: linear-gradient(90deg, #000521 0, #004c86 50%, #000521 100%);
		background-size: 100% 100%;
	}
	.bg-cyan{
		  background: linear-gradient(180deg,rgba(108,171,163,1) 0%,rgba(128,213,209,1) 20%, rgba(72,118,157,1) 100%);
		/* background: linear-gradient(180deg,rgba(30,30,30,.8) 0%,rgba(255,222,125,.8) 10%,rgba(255,255,255,.8) 20%,rgba(255,222,125,.5) 40%, rgba(30,30,30,.5) 60%); */
	}
	.bg-cyan-hover:hover{
		background-color: #e9ecef;
	}
	.bg-yellow{
		  background-color: #fff110 !important;
	}
	.bg-silver{
		background-color: silver !important;
	}
	.bg-coppery{
		background-color:Chocolate  !important;
	}
	.bg-highlight{
		background-image: linear-gradient(90deg, rgba(0, 0, 0, .2), rgba(255, 255, 255, .3), rgba(0, 0, 0, .2)) !important;
	}
	.border-_3{
		border-width: 0.3rem;
	}
	.border-style-outset{
		border-style: outset;
	}
	.border-dashed{
		border-style: dashed !important;
	}
	.border-double{
		border-style: double;
	}
    .h-0{
      	height: 0;
    }
	.cursor-grab{
		cursor: grab;
	}
	.cursor-grabbing{
		cursor: grabbing;
	}
	.scroll-none::-webkit-scrollbar{
		display: none;
	}
    .min-w-0{
		min-width: 0 !important;
    }
    .min-w-100{
		min-width: 100%;
    }
    .min-h-100{
		min-height:100%;
    }
	.w-0{
		  width:0;
	}
	.w-05rem{
		width: .5rem !important;
	}
    .w-1rem{
		width: 1rem !important;
    }
	.w-1_2rem{
		width: 1.2rem;
	}
	.w-1_5rem{
		width: 1.5rem;
	}
	.w-2rem{
		width: 2rem !important;
	}
	.w-2_5rem{
		width: 2.5rem !important;
	}
	.w-3rem{
		width:3rem;
	}
	.w-3_5rem{
		width:3.5rem;
	}
	.w-4rem{
		width: 4rem;
	}
	.w-4_6rem{
		width: 4.6rem;
	}
	.w-5rem{
		width: 5rem;
	}
	.w-6rem{
		width: 6rem;
	}
	.w-7rem{
		width: 7rem;
	}
	.w-8rem{
		width: 8rem;
	}
	.w-9rem{
		width: 9rem;
	}
	.w-10rem{
		width: 10rem;
	}
	.w-15rem{
		width: 15rem;
	}
	.w-30rem{
		width:30rem;
	}
	.w-50rem{
		width: 50rem;
	}
	.w-70{
		width: 70%;
	}
	.w-80{
		width: 80%;
	}
	.w-90{
		width: 90%;
	}
    .h-1rem{
		height:1rem;
    }
	.h-3rem{
		height:3rem;
	}
	.h-3_5rem{
		height: 3.5rem;
	}
	.h-4rem{
		height: 4rem;
	}
	.h-4_5rem{
		height: 4.5rem;
	}
	.h-8rem{
		height: 8rem;
	}
	.h-9_5rem{
		height: 9.5rem;
	}
	.h-10rem{
		height: 10rem;
	}
	.h-11rem{
		height: 11rem;
	}
	.h-20rem{
		height:20rem;
	}
	.h-30rem{
		height:30rem;
	}
	.h-80{
		height: 80%;
	}
	.h-90{
		height: 90%;
	}
	.pt-6_2rem{
		padding-top: 6.2rem;
	}
	.pt-9rem{
		padding-top: 9rem;
	}
	.pt-60{
		padding-top: 60%;
	}
	.pt-10{
		padding-top: 10%;
	}
    .rotate-30{
      	transform: rotate(30deg);
    }
	.rotate-45{
      	transform: rotate(45deg);
    }
	.rotate-90{
		transform: rotate(90deg);
	}
	.init-rotate-345{
		rotate: 345deg;
	}
	.init-translate-middle{
		translate: -50% -50%;
	}
    .top-2rem{
      	top:2rem;
    }
	.top-8{
		top:8%;
	}
	.top-13{
		top: 13%;
	}
	.top-4_5rem{
		top:4.5rem;
	}
	.top-26{
		top:26%;
	}
	.top-28{
		top:28%;
	}
	.top-40{
		top:40%;
	}
	.top-55{
		top:55%;
	}
	.top-70{
		top:70%;
	}
	.transform-3d{
		transform-style: preserve-3d;
	}
    .start-2rem{
      	left:2rem;
    }
	.start-110{
		left: 110%;
	}
	.end--110{
		right: -110%;
	}
	.end-2rem{
		right: 2rem;
	}
    .vertical-middle{
      	vertical-align: middle;
    }
    .border-solid{
      	border-style: solid;
    }
	.disabled{
		cursor: no-drop !important;
		pointer-events:auto !important;
	}
	.index-0{
		z-index: 0;
	}
	.index-1{
		z-index:1;
	}
	.index-2{
		z-index: 2;
	}
	.index-11{
		z-index:11;
	}
	.index-1051{
		z-index:1051;
	}
	.index-1052{
		z-index:1052;
	}
	.index-1053{
		z-index: 1053;
	}
	.index-1054{
		z-index: 1054;
	}
	.translate-start-x-middle-y{
		transform:translate(-100%,-50%);
	}
	.translate-middle-x-end-y{
		transform:translate(-50%,100%);
	}
	.translate-end-y{
		transform: translate(0,-100%);
	}
	.translate-start-y{
		transform: translate(0,100%);
	}
	.translate-middle-y{
		transform: translate(0,-50%);
	}
	.border-cyan{
        border-color: #64a7c4 !important;
		/* border-color: #ffb338 !important; */
		border-style:solid;
    }
    .rounded-5{
        border-radius: 0.5rem!important;
    }
	.rounded-10{
		border-radius: 1rem!important;
	}
	.rounded-start-top-10{
		border-top-left-radius: 1rem;
	}
	.rounded-start-bottom-10{
		border-bottom-left-radius: 1rem;
	}
	.rounded-end-top-10{
		border-top-right-radius: 1rem;
	}
	.rounded-end-bottom-10{
		border-bottom-right-radius: 1rem;
	}
    .text-yellow{
        color: #fff110 !important;
		/* color:rgba(190,179,0) !important; */
    }
	.text-silver{
		color: silver !important;
	}
	.text-coppery{
		color:Chocolate  !important;
	}
	.text-saddlebrown{
		color: saddlebrown;
	}
	.text-transparent{
		color:transparent;
	}
    .border-yellow{
        border-color: #fff110 !important;
		border-style:solid;
    }
	.border-jasper{
		border-color: #035522 !important;
		border-style:solid;
	}
	.border-start-jasper{
		border-left: 1px solid #035522;
	}
	.border-end-jasper{
		border-right: 1px solid #035522;
	}
	.overflow-x-scroll{
        overflow-x: scroll;
    }
	.overflow-y-scroll{
        overflow-y: scroll;
    }
	.border-solid{
		border-style: solid;
	}
	.border-transparent{
		border-color: transparent !important;
		border-style: solid;
	}
	.border-end-style-double{
		border-right-style: double !important;
	}
	.border-end-style-dashed{
		border-right-style: dashed !important;
	}
	video{
        object-fit: fill !important;
    }
	.container-xxxl{
		width: 100%;
		/* padding-right: var(--bs-gutter-x, 0.75rem);
		padding-left: var(--bs-gutter-x, 0.75rem); */
		margin-right: auto;
		margin-left: auto;
	}
	.width-max{
		width: max-content;
	}
	.text-shadow-white{
        text-shadow:var(--bs-light) 1px 0 1px, var(--bs-light) 0 1px 1px, var(--bs-light) -1px 0 1px, var(--bs-light) 0 -1px 1px;
    }
	.text-spacing-_1{
        letter-spacing: .1rem;
    }
	.text-spacing-_3{
		letter-spacing: .3rem;
	}
	/* 文本竖直显示 */
	.text-vertical{
		writing-mode: vertical-lr;
	}
	.text-upright{
		text-orientation: upright;
	}
	.textarea-noresize{
        resize:none;
    }
	.lh-2-4{
        line-height: 2.4 !important;
    }
    .lh-3{
        line-height: 3;
    }
	.lh-4{
		line-height: 4;
	}
	.rotate{
        transform: rotateY(90deg);
        animation: rotate .8s ease .1s forwards;
    }
	.perspective-600{
        perspective: 600px;
    }
	.btn-navy{
        background:linear-gradient(0deg,#00345b 10%,#005ea6 80%); 
        box-shadow:0 0.1rem 0.1rem 0.05rem #0e0e0e, inset 0 0.05rem 0px 0px rgba(255, 255, 255, 0.7);
    }
    .btn-navy:hover{
        background:linear-gradient(0deg,#00345b 10%,#005ea6 50%); 
    }
    .btn-navy:active{
        box-shadow: 0 0 0 0.05rem #0e0e0e !important;
        transform: translateY(.1rem);
    }
    .btn-shadow-right{
        box-shadow: -1px 3px 2px 0px #000;
    }
    .btn-shadow-left{
        box-shadow: 1px 3px 2px 0px #000;
    }
    .chips-shadow{
        box-shadow: 1px 3px 2px 0px #000;
    }
    .chips.active{
        top:-2px;
        box-shadow: 1px 3px 3px 3px yellow;
    }
    .chips:hover{
        top:-2px;
        transition:top 10s linear;
    }
    .chips:not(.active):hover>.chips-shadow{
        box-shadow: 1px 5px 2px 0px #000;
    }
    .chips-text{
        transform: translate(-50%,-50%) rotateX(35deg) !important;
        transform-origin: top;
        /* text-shadow: -1px 1px 0px #fff; */
        text-shadow: 1px 1px 0px #fff;
    }
	.virtual-container{
        transform:translateX(-50%);
    }
	.bg-jasper{
		background: #035522;
	}
	.shadow-hover{
		transition: box-shadow .15s linear;
	}
	.shadow-hover:hover{
		box-shadow: .1em .1em .3em .1em #555;
	}
	.scale-hover{
		transition: transform .1s linear;
	}
	.scale-hover:hover{
		/* transform: scale(1.008); */
		transform: scale(1.015);
	}
	.scale-_7{
		transform: scale(.7);
	}
	.font-led{
		font-family: 'UnidreamLED';
	}
	.zoom-_7{
		zoom: .7;
	}
	.zoom-_8{
		zoom: .8;
	}
    @keyframes rotate {
        0% {
            transform: rotateY(90deg);
        }
        100% {
            transform: rotateY(0deg);
        }
    }
	.marquee{
        animation: marquee 15s linear infinite;
    }
    .notice-short:hover~.notice-long{
        display: block;
    }
    .notice-long{
        display: none;
        margin-top: -0.25rem;
    }
    @keyframes marquee {
        0%{
            left:100%;
        }
        100%{
            left: -100%;
        }
    }
    @media (min-width: 576px){
		.col-sm-0{
			flex: 0 0 auto;
			width: 0 !important;
		}
		.h-sm-0{
			height: 0 !important;
		}
		.h-sm-50{
			height: 50% !important;
		}
		.h-sm-100{
			height:100% !important;
		}
		.h-sm-6rem{
			height: 6rem;
		}
		.h-sm-8rem{
			height: 8rem;
		}
		.h-sm-4_5rem{
			height: 4.5rem;
		}
		.h1-sm{
    		font-size: calc(1.375rem + 1.5vw);
		}
		.w-sm-7rem{
			width: 7rem;
		}
		.w-sm-9rem{
			width: 9rem;
		}
		.lh-sm-1{
			line-height: 1 !important;
		}
		.lh-sm-base {
			line-height: 1.5 !important;
		}
		.lh-sm-2{
			line-height: 2 !important;
		}
		.position-sm-absolute {
			position: absolute !important;
		}
		.position-sm-relative{
			position: relative !important;
		}
		.top-sm-5rem{
			top:5rem;
		}
		.top-sm-0{
			top:0;
		}
		.top-sm-4_5rem{
			top:4.5rem !important;
		}
		.bottom-sm-0{
			bottom: 0;
		}
		.start-sm-0{
			left: 0;
		}
		.start-sm-3_75{
			left: 3.75rem;
		}
		.border-top-sm {
			border-top: 1px solid #dee2e6 !important;
		}
		.border-bottom-sm {
			border-bottom: 1px solid #dee2e6 !important;
		}
		.border-start-sm {
			border-left: 1px solid #dee2e6 !important;
		}
		.border-end-sm {
			border-right: 1px solid #dee2e6 !important;
		}
		.border-top-sm-0{
			border-top: 0!important;
		}
		.border-bottom-sm-0{
			border-bottom: 0!important;
		}
		.border-start-sm-0{
			border-left: 0!important;
		}
		.border-sm {
			border: 1px solid #dee2e6!important;
		}
		.border-sm-0{
			border-width: 0px!important;
		}
		.border-sm-2{
			border-width: 2px!important;
		}
		.border-sm-3 {
			border-width: 3px!important;
		}
		.border-sm-4{
			border-width: 4px!important;
		}
		.img-sm-thumbnail{
			padding: 0.25rem;
			background-color: #fff;
			border: 1px solid #dee2e6;
			border-radius: 0.25rem;
			max-width: 100%;
			height: auto;
		}
		.fs-sm-2{
			font-size: calc(1.325rem + .9vw)!important;
		}
		.fs-sm-4{
			font-size: calc(1.275rem + .3vw)!important;
		}
		.fs-sm-5{
			font-size: 1.25rem!important;
		}
		.top-sm-26{
			top:26%;
		}
		.w-sm-3_5rem{
			width: 3.5rem;
		}
		.rounded-sm-10{
			border-radius: 1rem!important;
		}
		.rotate3d-x-sm-50{
            transform:rotate3d(1,0,0,50deg);
            transform-origin: bottom;
        }
		.teach{

		}
		.teach>div:first-child{
			width:2rem;
			height:2rem;
			transform: translate(50%, 1rem) rotate(45deg);
		}
		.teach>div:nth-child(2){
			transform: rotateX(-180deg) translate(-100%,60%);
		}
		.teach-chat{
			width:15rem;
			height:15rem;
			border-right: 3px solid #fff;
			border-top-right-radius: 100%;
			transform: rotateX(180deg);
			right:11.5rem;
			top:7rem;
		}
		.teach-personal{
			width:18rem;
			height:15rem;
			border-right: 3px solid #fff;
			border-top-right-radius: 100%;
			transform: rotateX(180deg);
			right:8rem;
			top:7rem;
		}
		.teach-settings{
			width:18rem;
			height:15rem;
			border-right: 3px solid #fff;
			border-top-right-radius: 100%;
			transform: rotateX(180deg);
			right:5rem;
			top:7rem;
		}
		.text-sm-vertical{
			writing-mode: vertical-lr;
		}
    }
    @media (min-width: 768px){
		.lh-md-1{
			line-height: 1 !important;
		}
		.lh-md-2{
			line-height: 2 !important;
		}
		.w-md-4rem{
			width:4rem;
		}
		.border-md-3{
			border-width: 3px!important;
		}
    }
    @media (min-width: 992px){
		.border-lg-4{
			border-width: 4px!important;
		}
		.start-lg-0{
			left:0;
		}
		.bottom-lg-0{
			bottom:0;
		}
		.top-lg-auto{
			top:auto !important;
		}
    }
	@media (min-width: 1200px){
		.lh-xl-2{
			line-height: 2 !important;
		}
		.lh-xl-4{
			line-height: 4 !important;
		}
		.top-xl-6rem{
			top:6rem;
		}
    }
    @media (max-width: 575px){
		.fs-xs-1{
			font-size: calc(1.375rem + 1.5vw)!important;
		}
		.fs-xs-2{
			font-size: calc(1.325rem + .9vw)!important;
		}
		.fs-xs-5{
			font-size: 1.25rem !important;
		}
		.small-xs{
			font-size: 0.875em;
		}
		.h-xs-50{
			height:50% !important;
		}
		.h-xs-100{
			height: 100% !important;
		}
		.h-xs-33{
			height: 33.3333333333%;
		}
		.h-xs-3rem{
			height: 3rem;
		}
		.lh-xs-2{
			line-height: 2;
		}
		.lh-xs-3{
			line-height: 3 !important;
		}
		.d-xs-block{
			display: block !important;
		}
		.bg-xs-transparent{
			background: transparent !important;
		}
		.bg-xs-dark{
			--bs-bg-opacity: 1;
  			background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
		}
		.rounded-xs {
			border-radius: 0.25rem !important;
		}
		.border-xs{
			border: 1px solid #dee2e6!important;
		}
		.border-xs-2{
			border-width: 2px!important;
		}
		.border-xs-3{
			border-width: 3px!important;
		}
		.rounded-xs {
			border-radius: 0.25rem!important;
		}
		.position-xs-absolute {
			position: absolute !important;
		}
		.position-xs-relative {
			position: relative !important;
		}
		.pt-xs-60{
			padding-top: 60%;
		}
		.top-xs-0{
			top:0 !important;
		}
		.top-xs-2_25{
			top: 2.25rem;
		}
		.start-xs-3_75{
			left:3.75rem;
		}
		.end-xs-0{
			right: 0;
		}
		.bg-xs-success{
			background-color: rgba(25,135,84,1) !important;
		}
		.border-top-xs{
			border-style: solid !important;
		}
		.border-start-xs-0{
			border-left: 0 !important;
		}
		.border-end-xs-0{
			border-right: 0 !important;
		}
		.border-bottom-xs-0{
			border-bottom: 0 !important;
		}
		.border-bottom-xs-2{
			border-bottom: 2px !important;
		}
		.border-bottom-xs-style-dashed{
			border-bottom-style: dashed !important;
		}
		.border-end-xs-style-solid{
			border-right-style: solid !important;
		}
		.top-xs-3_5rem{
			top:3.5rem;
		}
		.w-xs-100{
			width: 100%;
		}
		.w-xs-2_5rem{
			width: 2.5rem;
		}
		.teach-chat>div:first-child{
			width:2rem;
			height:2rem;
			left: 0;
    		transform: translate(-50%, 1rem) rotate(45deg);
		}
		.teach-chat>div:nth-child(2){
			transform: translate(50%,-100%);
		}
		.teach-personal>div:first-child{
			width:2rem;
			height:2rem;
			right: 0;
    		transform: translate(50%, 1rem) rotate(45deg);
		}
		.teach-personal>div:nth-child(2){
			transform: translate(-50%,-100%);
		}
		.teach-settings>div:first-child{
			width:2rem;
			height:2rem;
			right: 0;
    		transform: translate(50%, 1rem) rotate(45deg);
		}
		.teach-settings>div:nth-child(2){
			transform: translate(-50%,-100%) rotateX(180deg);
		}
		.teach-chat{
			width:11rem;
			height:15rem;
			border-left: 3px solid #fff;
			border-top-left-radius: 100%;
			/* transform: rotateX(180deg); */
			left:5rem;
			bottom:6rem;
		}
		.teach-personal{
			width:11rem;
			height:15rem;
			border-right: 3px solid #fff;
			border-top-right-radius: 100%;
			/* transform: rotateX(180deg); */
			right:5rem;
			bottom:6rem;
		}
		.teach-settings{
			width:14rem;
			height:15rem;
			border-right: 3px solid #fff;
			border-top-right-radius: 100%;
			transform: rotateX(180deg);
			right:2rem;
			top:6rem;
		}
		.scale-top-sm-1_2{
            transform:scale(1.2);
            transform-origin: top;
        }
		.index-xs-1{
			z-index: 1;
		}
    }
    @media (min-width: 576px) and (max-width:767px){
		.small-sm{
			font-size: 0.875em;
		}
		.scale-submit{
            transform-origin: 50% 100%;
            transform: translateX(-50%) scale(1.3) !important;
            transition: transform .15s linear;
        }
    }
    @media (min-width: 768px) and (max-width: 991px){
		.small-md{
			font-size: 0.875em;
		}
    }
	@media (min-width:1700px) {
		.container-xxxl{
			/* max-width: 1700px; */
		}
	}

	:root{
		/* --outer-red:#bd3a5d;
        --inside-red:#cf8186; */
        /* --inside-blue:#64a7c4;
        --outer-blue:#48769d; */
		--outer-red:#00304C;
        --inside-red:#004f7d;
		--inside-blue:#00304C;
        --outer-blue:#001e31;
		--start:0%;
	}
	/* 深色模式 */
	.darkness{
		--bs-success-rgb:16,86,53;
		--bs-secondary-rgb:64,70,74;
		--bs-warning-rgb: 207,157,6;
		--bs-light-rgb: 202,202,202;
		--bs-primary-rgb: 10,87,201;
		--bs-info-rgb:10,159,189;
		--bs-danger-rgb: 172,41,54;

		--bs-success:#105635;
		--bs-secondary: #40464a;
		--bs-warning: #cf9d06;
		--bs-light:#cacaca;
		--bs-primary:#0a57c9;
		--bs-info:#0a9fbd;
		--bs-danger: #ac2936;

		--outer-red:#a43251 !important;
        --inside-red:#b77277 !important;
        --inside-blue:#5893ac !important;
        --outer-blue:#3d6485 !important;
	}
	.darkness .border-bottom{
		border-bottom: 1px solid var(--bs-gray-400)!important;
	}
	.darkness .border-warning{
		border-color: var(--bs-warning) !important;
	}
	.darkness .border-info{
		border-color:var(--bs-info) !important;
	}
	.darkness .border-light{
		border-color: var(--bs-light) !important;
	}
	.darkness .border-primary{
		border-color:var(--bs-primary) !important;
	}
	.darkness .btn-warning{
		background-color: var(--bs-warning) !important;
    	border-color: var(--bs-warning) !important;
	}
	.darkness .btn-primary{
		background-color:var(--bs-primary);
		border-color:var(--bs-primary);
	}
	.darkness .btn-outline-light{
		border-color:var(--bs-gray-300);
		color:var(--bs-gray-300);
	}
	.darkness .form-check-input:checked {
		background-color: var(--bs-primary) !important;
		border-color: var(--bs-primary) !important;
	}
	.darkness .form-check-input{
		background-color: var(--bs-light);
	}
	.darkness .img-thumbnail{
		background-color: var(--bs-light);
	}
	.darkness .bg-cyan{
		background:linear-gradient(180deg,rgba(76,119,114,1) 0%,rgba(98,164,161,1) 20%, rgba(48,78,104,1) 100%);
	}
	.darkness .nav-tabs{
		border-bottom: 1px solid var(--bs-gray-400)!important;
	}
	.darkness .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active{
		background-color:var(--bs-light) !important;
		border-color: var(--bs-gray-400) var(--bs-gray-400) var(--bs-light) !important;
	}
	.darkness .chips.active{
		box-shadow: 1px 3px 3px 3px #cdcd00;
	}
	.darkness .text-yellow{
		color: #cdcd00 !important;
	}
	.darkness .btn-check:checked+.btn-outline-blue,.btn-outline-blue:hover {
        color: var(--bs-light) !important;
    }
	.darkness .page-item.disabled .page-link{
		background-color: var(--bs-light);
		border-color:var(--bs-gray-400);
		color:var(--bs-gray-700);
	}
	.darkness .page-item.active .page-link{
		color:var(--bs-light);
		background-color:var(--bs-primary);
		border-color:var(--bs-primary);
	}
	.darkness .list-group-item-primary{
		color: #052d67;
    	background-color: #aab9d0;
	}
	.darkness .list-group-item-dark{
		background-color:#a0a0a1;
	}
	.darkness .list-group-item-info{
		color: #03282f;
		background-color:#a7c5cb;
	}
	.darkness .modal-footer{
		border-top:1px solid var(--bs-gray-400);
	}
	.darkness .modal-header{
		border-bottom:1px solid var(--bs-gray-400);
	}
	.darkness .drag-progress,.darkness .verify-msg{
        background-color: #5b912c;
    }

	/* .darkness .border-cyan{
		border-color: #949494 !important;
	}
	.darkness .border-yellow{
		border-color: rgb(231, 220, 0) !important;
	}
	.darkness.bg{
		background: #000 !important;
	}
	.darkness .game-list-item.active::before {
		content: "";
		display: block;
		background: rgba(0,0,0,.1);
		width: 100%;
		height: 100%;
		position: absolute;
	} */

</style>
<template>
	<span class="d-none text-light justify-content-center" :class="{'d-sm-flex':$route.name=='share'}" style="font-size:24px">{{$t("app.useVertical")}}</span>
	<div :class="[skin]" class="bg w-100 h-100 d-flex align-items-center">
		<div id="wrapper" class="d-flex flex-column container-xxxl overflow-hidden position-relative" :class="{'bg-gradient':showNav,'d-sm-none':$route.name=='share'}">
			<div class="flex-grow-1 h-0 order-last">
				<router-view
				@ws-send="wsSend" 
				@ws-msg="wsMsg" 
				@set-notice="v=>notice=v" 
				@set-user-info="setUserInfo" 
				@set-video-line-list="arr=>videoLineList=arr"
				@set-active-game="game=>activeGame=game"
				@set-lang="lang=>currLang=lang"
				@set-page-ready-state="isReady=>isPageReady=isReady"
				@set-curr-video-line="setCurrVideoLine"
				@set-skin="v=>skin=v"
				@set-curr-amount-list="v=>currAmountList=v"
				@set-before-gopage-fn="v=>beforeGoPageFn=v"
				@set-istryuser="v=>isTryUser=v"
				:user-info="userInfo"
				:active-game="activeGame"
				:video-line-list="videoLineList"
				:curr-video-line="currVideoLine" 
				:audio="audio"
				:curr-lang="currLang"
				:curr-amount-list="currAmountList"
				:is-fullscreen="isFullscreen"
				:has-chat-msg="hasChatMsg"
				:is-try-user="isTryUser" />
			</div>
			<!-- 客服消息提示框 -->
			<div class="position-fixed bottom-0 end-0 p-3 index-11">
				<div id="liveToast" class="toast" :class="{'show':fastChatMsg.msg!=''}" role="alert" aria-live="assertive" aria-atomic="true">
					<div class="toast-header">
						<strong class="me-auto">{{fastChatMsg.serverName}}</strong>
						<button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
					</div>
					<div class="toast-body">{{fastChatMsg.msg}}</div>
				</div>
			</div>
			<!-- 引导 -->
			<div v-if="showNav&&isShowTeach" class="position-absolute top-0 start-0 bottom-0 end-0 bg-black bg-opacity-75 index-1052"></div>
			<div v-if="showNav&&isShowTeach" class="position-absolute top-0 start-0 bottom-0 end-0 index-1054">
				<div class="position-absolute" :class="teachList[teachIndex].class">
					<div class="position-absolute border border-3 border-white border-start-0 border-top-0 bottom-0 end-0"></div>
					<div class="position-absolute text-light fw-bold fs-5">
						<div class="mb-2">{{teachList[teachIndex].text}}</div>
						<div class="d-flex justify-content-center">
							<button type="button" class="btn btn-link text-muted me-2" @click="teachIndex>0&&(teachIndex--)">{{$t("app.lastStep")}}</button>
							<button v-if="teachIndex==teachList.length-1" type="button" class="btn btn-outline-light" @click="closeTeach">{{$t("app.finish")}}</button>
							<button v-else type="button" class="btn btn-outline-light" @click="teachIndex<teachList.length-1&&(teachIndex++)">{{$t("app.nextStep")}}</button>
						</div>
						<div class="text-center fs-6 pt-2">
							<div class="btn btn-link" @click="closeTeach">{{$t("app.passHelp")}}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import navbar from "@/components/public/navbar.vue";
import {IS_DEBUG,RATIO,COLOR,LAYER_TYPE,COMMAND,GAME_ID,PEARL_CODE,PEARL_TYPE,GAME_ROW,GAME_CODE} from "@/assets/js/config.js"
import { fullScreen } from '@/assets/js/common.js'
import { nextTick } from '@vue/runtime-core';
import { useLayer } from '@/assets/js/useLayer';
export default {
  	components: { navbar },
	data() {
		return {
			ws:null,//websocket连接协议
			showNav:false,//是否显示导航栏
			viewScale:0,//宽度比高度
			notice:"",//公告内容
			userInfo:{
				name:"",//用户名
				guid:"",//用户guid
				realCoin:"",//真人币
				esportsCoin:""//竞技币
			},
			videoLineList:[],//视频线路列表
			wsMsgFn:null,//websocket接收消息处理函数
			currVideoLine:"",//当前视频线路id
			resizeFn:null,//其他页面要添加的窗口大小改变处理函数
			audio:{
				bgAudio:false,//背景音效开关
				startAudio:true,//开始/结束音效开关
				resultAudio:true,//开注音效
				tenAudio:true,//最后10秒音效
			},
			activeGame:GAME_ID.bac,//当前激活的游戏
			currLang:this.$i18n.locale,//当前选中的语言
			chatWs:null,//用于全局保存客服功能的websocket连接
			hasChatMsg:false,//是否有未读的客服消息
			fastChatMsg:{
				serverName:"",
				msg:"",
			},//聊天快捷消息显示对象
			chatMsgTimer:null,//聊天快捷消息显示计时器对象
			columnList:[],//台面列表画布绘制列数(没办法，想不到idea，只能暂且放这里了)
			isPageReady:false,//页面是否加载完毕
			ip:{
				cip: '',
				cname: ''
			},
			teachIndex:0,//当前引导页下标
			teachList:[
				{
					text:this.$t("app.helpCustomer"),
					class:"teach teach-chat"
				},
				{
					text:this.$t("app.helpPersonal"),
					class:"teach teach-personal"
				},
				{
					text:this.$t("app.helpSetting"),
					class:"teach teach-settings"
				},
			],//引导内容列表
			isShowTeach:localStorage.getItem('isTeach')=='false'?false:true,//是否显示引导
			callInfo:null,//通话信息
			skin:"",//皮肤设置（darkness）
			gameModel:"",//游戏模式（tel）
			currAmountList:[],//当前自选额度
			isFullscreen:false,//当前是否全屏
			beforeGoPageFn:null,//跳转页面前的回调函数
			isTryUser:false,//是否试玩用户
		};
	},
	mounted(){
		this.ip=this.getIP();
		this.setViewSize();
		addEventListener("resize",this.setViewSize);
		window.fullScreen=this.setFullscreen;//设置window函数使点击全屏提示中的全屏按钮起作用
		this.showBestTip();
		//监听设备纵横方向改变
		window.onorientationchange=()=>{this.showBestTip();};
		//监听全屏改变
		addEventListener("fullscreenchange",()=>{
			if(document.fullscreenElement){
				this.isFullscreen=true;
			}else{
				this.isFullscreen=false;
			}
		})
	},
	methods: {
		/**
		 * 设置全屏/取消全屏
		 */
		setFullscreen(){
			fullScreen().then(v=>{
				v==-1&&useLayer({str:this.$t("app.unFullscreen")});
			});
		},
		/**
		 * 横屏时显示全屏提示
		 */
		showBestTip(){
			if(!(document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement)){
				setTimeout(() => {
					if(window.orientation&&(window.orientation==90||window.orientation==-90)){//横屏
						useLayer({str:this.$t("app.isHorizontal")+this.$t("app.click")+"<a href='javascript:window.fullScreen()'>"+this.$t("login.fullscreen")+"</a>"+this.$t("app.better")});
					}
				});
			}
		},
		/**
		 * 设置显示区大小
		 */
		setViewSize() {
			// this.viewScale=(innerHeight/innerWidth>1.6&&innerHeight/innerWidth<2.2)?innerHeight/innerWidth:1.8;
			let whScale=innerWidth/innerHeight;
			this.viewScale=(innerHeight/innerWidth>1.6&&innerHeight/innerWidth<2.2)?innerHeight/innerWidth:(whScale<1.6?1.6:(whScale>2.4?2.4:whScale));
			IS_DEBUG&&console.log("设置显示区大小");
			let width=innerWidth;
			let height=innerHeight;
			let elem=document.getElementById("wrapper");
			elem.removeAttribute("style");
			if(width<height&&width<576){//竖屏显示
				if (width*this.viewScale < height) {//宽度小了或者高度大了，不能增加宽度(因为宽度已达极限)，只能减小高度
				//重新配置高度，宽度为满宽度
					elem.style.width="100%";
					elem.style.height=width * this.viewScale+"px";
				} else {//大于设定比例，说明宽度大了或者高度小了，不能增加高度(因为高度已达极限)，只能减小宽度
				//重新配置宽度，高度为满高度
					elem.style.height="100%";
					elem.style.width=height / this.viewScale+"px";
				}
				document.getElementsByTagName("html")[0].style.fontSize=(elem.clientHeight-1035)/1552+1+"rem";//以高度每1035尺寸1rem为标准
			}else{//横屏显示
				let style=getComputedStyle(document.getElementById("wrapper"));
				if(width/this.viewScale>height){//宽度大了或者高度小了，不能增加高度(因为高度已达极限)，只能减小宽度
					elem.style.width=height*this.viewScale+"px";
				}
				elem.style.height=style.width.replace('px','')/ this.viewScale+"px";//重新配置高度
				document.getElementsByTagName("html")[0].removeAttribute("style");
				document.getElementsByTagName("html")[0].style.fontSize=(elem.clientWidth-1320)/1700+0.9+"rem";//宽度超过1320的部分，每增加1980，字体增加1rem
			}
		},
		/**
		 * 设置画布大小
		 * @param {HTMLElement} elem 画布DOM对象
		 */
		setCanvasSize(elem) {
			//将画布宽高设置为0，以免对获取其父元素宽高造成影响
            elem.width=0;
            elem.height=0;
            elem.style.width=0;
            elem.style.height=0;
			//获取父元素样式
			let parentStyle=getComputedStyle(elem.parentElement);
			let parentWidth=parentStyle.width.replace('px','');
			let parentHeight=parentStyle.height.replace('px','');
			//重新设置画布大小
			elem.width=RATIO*parentWidth;
			elem.height=RATIO*parentHeight;
			elem.style.width=parentWidth+"px";
			elem.style.height=parentHeight+"px";
		},
		/**
		 * 绘制画布表格
		 * @param {HTMLElement} canvasElem 要绘制的canvas集合
		 * @param {Number} row 要绘制的行数
		 * @param {bool} hasHead 是否有列头
		 * @param {Number} ratio 宽比高的值
		 */
		drawCanvasTable(canvasElem,row,hasHead,ratio){
			if(canvasElem){
				let obj=null;
				let canvas=canvasElem;
				let style=getComputedStyle(canvas);
				let width=RATIO*style.width.replace('px','');
				let height=RATIO*style.height.replace('px','');
				if (canvas.nodeName=="CANVAS"&&width>0&&height>0) {//限制宽高条件防止视口太小导致死循环
					let ctx=canvas.getContext("2d");
					let cellHeight=height/row;//单元格高度
					let column=Math.round(width / (cellHeight*(ratio?ratio:1)));
					let cellWidth = width / column;//获取单元格宽度
					ctx.clearRect(0, 0, width, height);//清除画布
					ctx.strokeStyle = '#b1b1b1';
					ctx.lineWidth = 1*RATIO;
					//绘制表格横线
					for (var j = 0; j < row; j++) {
						ctx.beginPath();
						ctx.moveTo(0, cellHeight * j);
						ctx.lineTo(cellWidth * column, cellHeight * j);
						ctx.stroke();
					}
					//绘制表格竖线
					for (let k = 0; k < column; k++) {
						ctx.beginPath();
						ctx.moveTo(cellWidth * k, 0);
						ctx.lineTo(cellWidth * k, height);
						ctx.stroke();
					}
					obj={
						elem:canvas,
						column:hasHead?--column:column,
						cellWidth,
						cellHeight
					}
				}
				return obj;
			}
		},
		/**
		 * 获取IP
		 */
		getIP() {
			try {
                return returnCitySN;
            } catch (e) {
                return {
                    cip: '127.0.0.1',
                    cname: '无法获取'
                }
            }
		},
		/**
		 * websocket发送消息
		 */
		wsSend(){
			if(this.ws){
				let str = '';
				for (let i = 0; i < arguments.length; i++) {
					i != arguments.length - 1 && (str += arguments[i] + '|');
					i == arguments.length - 1 && (str += arguments[i] + ';');
				}
				this.ws.send(str);
				IS_DEBUG&&console.log(str);
			}
		},
		/**
		 * websocket接收消息
		 * @param {Function} fn 
		 */
		wsMsg(fn){
			this.ws&&(this.wsMsgFn=fn);
		},
		/**
		 * websocket接收消息
		 */
		onMessage(){
			if(this.ws){
				let onlineTimer,timer;//心跳定时器、线路优劣验证定时器
				let invalid="";//无效字符串
				this.ws.onmessage=async (e)=>{
					IS_DEBUG&&console.log(e.data);
					//数据包处理
					let obj = this.setSocketData(e.data,invalid);
					let arr=obj.arr;
					invalid=obj.invalid;
					
					for (let i = 0; i < arr.length; i++) {
						let data = arr[i];
						IS_DEBUG&&console.log("转换后的数据："+JSON.stringify(data));
						switch (data.command) {
							case COMMAND.all_online:
								if(timer){
									clearTimeout(timer);
									timer=null;
								}
								if(!onlineTimer&&this.ws.readyState==1){//如果没有心跳定时器并且websocket为可通信状态
									let callback=()=>{
										this.wsSend(COMMAND.all_online, this.userInfo.name, this.userInfo.guid);
										timer = setTimeout(()=>{//4秒没有应答则提示网络问题
											useLayer({str:this.$t("app.lowline")});
										},4000);
										return callback;
									}
									onlineTimer = setInterval(callback,10000);
								}
								break;
							case COMMAND.all_getout:
								this.ws&&this.ws.close();
								clearTimeout(timer);
								clearInterval(onlineTimer);
								this.currVideoLine="";
								useLayer({
									type:LAYER_TYPE.confirm,
									str:this.$t("app.getout"),
									btn:[this.$t("app.toLogin")],
									yesFn:(i)=>{
										layer.close(i);
										this.$router.push("/");
									}
								})
								break;
							case COMMAND.all_otherlogin:
								this.ws.close();
								clearTimeout(timer);
								clearInterval(onlineTimer);
								this.currVideoLine="";
								useLayer({
									type:LAYER_TYPE.confirm,
									str:this.$t("app.otherLogin"),
									btn:[this.$t("app.toLogin")],
									yesFn:(i)=>{
										layer.close(i);
										this.$router.push("/");
									}
								})
								break;
							case COMMAND.all_logintop:
								this.ws.close();
								clearTimeout(timer);
								clearInterval(onlineTimer);
								this.currVideoLine="";
								useLayer({
									type:LAYER_TYPE.confirm,
									str:this.$t("app.loginTop"),
									btn:[this.$t("app.toLogin")],
									yesFn:(i)=>{
										layer.close(i);
										this.$router.push("/");
									}
								})
								break;
							case COMMAND.all_logout:
								this.ws.close();
								clearTimeout(timer);
								clearInterval(onlineTimer);
								this.currVideoLine="";
								this.userInfo.guid="";
								this.$router.push("/");
								break;
						}
						
						//模拟收到推送
						// if(data.command=="texa_gettablepro"){
						// 	setTimeout(() => {
						// 		this.wsMsgFn({"command":"texa_move","seat":"3"});
						// 		setTimeout(() => {
						// 			this.wsMsgFn({"command":"texa_submitbet","tableid":44,"userid":"a13","bet":0,"type":9,"seat":3,"result":1});
						// 			this.wsMsgFn({"command":"texa_move","seat":"1"});
						// 		}, 2000);
						// 	}, 4000);
						// }
						// if(data.command=="texa_gettablepro"){
						// 	setTimeout(() => {
						// 		this.wsMsgFn({
						// 			"command":"texa_end",
						// 			"pubcard":[48,40,43,52,36,1,2,3],
						// 			"pool":[300],
						// 			"list":{"0":{"card":[34,44],"gain":[]},"1":{"card":[20,26],"gain":[]},"2":{"card":[13,49],"gain":[300]},"3":{"card":[],"gain":[]},"4":{"card":[],"gain":[]},"5":{"card":[],"gain":[]},"6":{"card":[],"gain":[]}}
						// 		});
						// 	}, 2000);
						// }
						// if(data.command=="texa_gettablepro"){
						// 	setTimeout(() => {
						// 		this.wsMsgFn({
						// 			"command":"texa_gettablepro",
						// 			"gamestate":4,"dseat":2,"sseat":3,"bseat":1,"countdown":15,"pubcard":[48,22,41],"pricard":[25,13],"antes":100,"isget":false,"list":{"0":{"userid":"a11","state":3,"currbet":0,"boutbet":100},"1":{"userid":"a12","state":3,"currbet":0,"boutbet":100},"2":{"userid":"a13","state":3,"currbet":0,"boutbet":100},"3":{},"4":{},"5":{},"6":{}}
						// 		});
						// 	}, 7000);
						// }
						// if(data.command=="cor_getbetdetail"){
						// 	setTimeout(() => {
						// 		this.wsMsgFn({
						// 			"command":"all_getcurrpoker",
						// 			"id":39,"index":[1,1],"poker":[[1],[],[],[]]
						// 		});
						// 	}, 3000);
						// }
						
						this.wsMsgFn&&this.wsMsgFn(data);
						await nextTick();//加上这个以免因连包导致循环赋值，但因为vue的机制使循环最后一次才能监听到
					}
				}
			}
		},
		/**
         * 处理websocket接收的数据
         */
		setSocketData(str,invalid){
            //数据包处理
            let arr=[];//存放接收到的数据
            try {
                let obj1 = JSON.parse(str);//尝试转换接收到的数据
                arr.push(obj1);//成功则加入数组作为一条有效数据
            } catch (error) {
                while (str.indexOf("}{")!=-1) {//连包
                    IS_DEBUG&&console.log("有连包");
                    let part=str.substring(0,str.indexOf("}{")+1);//截取背向花括号前的片段
                    str = str.replace(part,"");
                    try {
                        let obj2 =JSON.parse(part);//尝试转换截取出来的片段
                        arr.push(obj2);//成功则加入数组作为一条有效数据
                    } catch (error) {//失败说明是不完整数据
                        invalid+=part;//与上一次的无效数据拼一起
                        try {
                            let obj3=JSON.parse(invalid);//尝试转换拼接后的字符串
                            arr.push(obj3);//成功则加入数组作为一条有效数据
                            invalid="";//并且清空无效字符串
                        } catch (error) {}//不成功不理会
                    }
                }
                //走到这里说明没有连包了
                try {
                    let obj4=JSON.parse(str);//尝试转换剩余的片段
                    arr.push(obj4);//成功则加入数组作为一条有效数据
                } catch (error) {//失败则说明是不完整数据
                    IS_DEBUG&&console.log("有分包");
                    invalid+=str;//与上一次的无效数据拼一起
                    try {
                        let obj5=JSON.parse(invalid);//尝试转换拼接后的字符串
                        arr.push(obj5);//成功则加入数组作为一条有效数据
                        invalid="";//并且清空无效字符串
                    } catch (error) {}//不成功则不理会，等待下一次接收数据再处理就好
                }
            }
            return {
				arr,invalid
			};
        },
		/**
		 * 设置用户信息
		 * @param {userInfo} obj 画布DOM元素
		 */
		setUserInfo(obj){
			obj.name&&(this.userInfo.name=obj.name);
			obj.guid&&(this.userInfo.guid=obj.guid);
			obj.realCoin&&(this.userInfo.realCoin=obj.realCoin);
			obj.esportsCoin&&(this.userInfo.esportsCoin=obj.esportsCoin);
		},
		/**
		 * 绘制台面列表和台面共有的路单
		 * @param {Object} elemObj 使用drawCanvasTable绘制画布表格返回的对象
		 * @param {Array} arr 路单数据
		 * @param {PEARL_TYPE} type 路单类型
		 * @param {GAME_ID} game 游戏
		 * @param {Bool} isScale 小路是否放大，用于查看全部
		 */
		drawPearl(elemObj,arr,type,game,isScale){
			let pearlArr=JSON.parse(JSON.stringify(arr));
			let cellHeight=elemObj.cellHeight;//单元格高度
			let cellWidth = elemObj.cellWidth;//单元格宽度
			let ctx=elemObj.elem.getContext("2d");
			ctx.textBaseline = 'middle';
			ctx.font = 'bold ' + cellWidth * .5 + 'px SimHei';
			if(game==GAME_ID.bac||game==GAME_ID.longfong||game==GAME_ID.coin){
				//bac
				let zCode = PEARL_CODE.Z;
				let xCode = PEARL_CODE.X;
				let hCode = PEARL_CODE.H;
				let zdCode=PEARL_CODE.ZD;
				let xdCode=PEARL_CODE.XD;
				//longfong
				if(game==GAME_ID.longfong){
					zCode = PEARL_CODE.L;
					xCode = PEARL_CODE.F;
					hCode = PEARL_CODE.HE;
					zdCode=PEARL_CODE.LSG;
					xdCode=PEARL_CODE.FSG;
				}
				// let lCode=PEARL_CODE.L;
				// let fCode=PEARL_CODE.F;
				// let heCode=PEARL_CODE.HE;
				let radius=2.6;//半径比例
				// if(type!=PEARL_TYPE.big_hollow){//小路需要按照原始格子的高度去计算，否则会造成计算的列数出错
				// 	column=Math.round(width / (cellHeight*2))*2;
				// }
				let maxPoint = [0, 0, 0, 0, 0, 0];//保存每一行露珠达到的最大x坐标点
				let lastPoint = 0;//保存上一次最后一行露珠的最大坐标点，用来比较是否可以左拐
				let currentRow;//保存当前列已达到的行数
				if((type==PEARL_TYPE.small_solid||type==PEARL_TYPE.small_hollow||type==PEARL_TYPE.bevel)&&!isScale){//小路的单元格为正常单元格的一半
					cellHeight/=2;
					cellWidth/=2;
				}
				for (let i = 0; i < pearlArr.length; i++) {
					currentRow=0;
					for (let j = 0; j < pearlArr[i].length; j++) {//currentRow为当前列的露珠已到达的行数
						//设置边框粗细
						if(type == PEARL_TYPE.big_hollow){
							ctx.lineWidth = cellWidth * .12;
						}else{
							ctx.lineWidth = cellWidth * .15;
						}
						//设置圆圈位置
						let x = i * cellWidth + (cellWidth / 2);//正常向下的x值
						let y = j * cellHeight + (cellHeight / 2);//正常向下的y值
						if (currentRow == 6 || x <= maxPoint[currentRow]) {//已经到达最后一行或者当前要画的x坐标小于或等于当前要画行的最大坐标，即当前要画的位置已有露珠
							if (currentRow == 6 && lastPoint < x - (pearlArr[i].length - 6) * cellWidth) {//如果当前已到达第6行并且第6行露珠的x坐标大于该行最大坐标的8倍，即前面有7个空位，则向左拐
								x = (i - ((j + 1) - currentRow)) * cellWidth + (cellWidth / 2);//当前列数-
							} else {//否则右拐
								x = (i + j - currentRow + 1) * cellWidth + (cellWidth / 2);//当前个数-当前所在行+当前列=要画的露珠列
								maxPoint[currentRow - 1] = x;//更新最大坐标点
							}
							y = (currentRow - 1) * cellHeight + (cellHeight / 2);
						} else {//否则向下画
							currentRow == 5 && (lastPoint = maxPoint[currentRow] == 0 ? cellWidth / -2 : maxPoint[currentRow]);//如果已是最后一行，则保存最大坐标点，用于左拐判断
							maxPoint[currentRow] = x;//更新最大坐标点
							currentRow++;//累加当前列露珠到达的行数
						}
						//设置圆圈颜色
						let letter = pearlArr[i][j].substr(0, 1);//获取首字母
						if (xCode.indexOf(letter)!=-1) {
							ctx.fillStyle = ctx.strokeStyle = (game==GAME_ID.bac||game==GAME_ID.coin?COLOR.blue:COLOR.red);//填充颜色
						} else if (zCode.indexOf(letter)!=-1) {
							ctx.fillStyle = ctx.strokeStyle = (game==GAME_ID.bac||game==GAME_ID.coin?COLOR.red:COLOR.blue);
						} else if (hCode.indexOf(letter)!=-1) {
							ctx.fillStyle = ctx.strokeStyle = COLOR.green;
						}
						//绘制圆圈/斜线
						ctx.beginPath();
						if (type == PEARL_TYPE.bevel) {
							ctx.moveTo(x - cellWidth / radius , y + cellHeight / radius );
							ctx.lineTo(x + cellWidth / radius , y - cellHeight / radius );
						} else {
							ctx.arc(x, y, cellHeight / radius, 0, 2 * Math.PI);
						}
						if(type==PEARL_TYPE.small_solid){
							ctx.fill();
						}else{
							ctx.stroke();
						}
						if(type==PEARL_TYPE.big_hollow){
							//绘制斜线和数字
							if ((game==GAME_ID.bac&&pearlArr[i][j].length >= 3)||(game==GAME_ID.longfong&&pearlArr[i][j].length >=2)||(game==GAME_ID.coin&&pearlArr[i][j].length >= 3)) {//当前遍历到的最后一项并且字符串长度为3
								var text = pearlArr[i][j].substr(2,1);//截取第三位
								game==GAME_ID.longfong&&(text=pearlArr[i][j].substr(1,1));//截取第二位
								if(text!="0"){//有需要显示“和”统计
									ctx.fillText(text, x - ctx.measureText(text).width / 2, y);
									ctx.lineWidth = cellWidth * .07;//边框粗细
									ctx.moveTo(x, y + cellHeight / radius);
									ctx.lineTo(x + cellHeight / radius, y);
									ctx.stroke();
								}
							}
							//填充圆点
							if (zdCode.indexOf(letter)!=-1) {//含有庄对
								ctx.fillStyle = (game==GAME_ID.bac||game==GAME_ID.coin?COLOR.red:COLOR.blue);
								ctx.beginPath();
								ctx.arc(x - cellWidth / 3.5, y - cellHeight / 3.5, cellWidth / radius / 3.5, 0, 2 * Math.PI);
								ctx.fill();
							}
							if (xdCode.indexOf(letter)!=-1) {//含有闲对
								ctx.fillStyle = (game==GAME_ID.bac||game==GAME_ID.coin?COLOR.blue:COLOR.red);
								ctx.beginPath();
								ctx.arc(x + cellWidth / 3.5, y - cellHeight / 3.5, cellWidth / radius / 3.5, 0, 2 * Math.PI);
								ctx.fill();
							}
						}
					}
				}
			}else if(game==GAME_ID.cor){
				ctx.font = 'bold ' + cellWidth * .3 + 'px SimHei';
				//绘制列头
				ctx.fillStyle = COLOR.red;
				ctx.fillRect(0, 0, cellWidth, cellHeight);
				ctx.fillStyle = COLOR.blue;
				for (let i = 0; i < 3; i++) {
					ctx.fillRect(0, cellHeight + (i * cellHeight), cellWidth, cellHeight);
				}
				ctx.fillStyle = "#fff";
				for (let i = 0, arr = [this.$t("common.z"), this.$t("common.x")+'1', this.$t("common.x")+'2', this.$t("common.x")+'3']; i < arr.length; i++) {
					ctx.fillText(arr[i], cellWidth / 2 - ctx.measureText(arr[i]).width / 2, (i * cellHeight) + cellHeight / 2);
				}
				//绘制路单
				for (let i = 0; i < pearlArr.length; i++) {
					for (let j = 0; j < pearlArr[i].length; j++) {
						// 创建渐变
						let grd=ctx.createRadialGradient(
							(i + 1) * cellWidth + cellWidth / 2,
							j * cellHeight + cellHeight / 2,
							cellWidth / 2.4,
							(i + 1) * cellWidth + cellWidth / 2-(cellWidth / 2.4/2),//x坐标减去二分之一半径
							j * cellHeight + cellHeight / 2-(cellWidth / 2.4/2),
							0);
						//填充圆
						let color=COLOR.red;//设置填充颜色(红色)
						if (j > 0) {//轮到闲
							if(pearlArr[i][j] < 0){
								color = '#848484';//输填充灰色
							}else{
								if(pearlArr[i][j]==40){//炸弹填充黑色
									color=COLOR.black;
								}else if(pearlArr[i][j]==30){//金牛填充金色
									color='#f2c056';
								}else if(pearlArr[i][j]==20){//银牛填充银色
									color='#c0c0c0';
								}else{//赢填充蓝色
									color = COLOR.blue;
								}
							}
						}
						grd.addColorStop(0,color);
						grd.addColorStop(1,COLOR.ray);
						ctx.fillStyle = color;
						ctx.strokeStyle=color;
						ctx.beginPath();
						// ctx.arc((i + 1) * cellWidth + cellWidth / 2, j * cellHeight + cellHeight / 2, cellWidth / 2.4, 0, 2 * Math.PI);//除2.4才不会刚好跟单元格一样大
						// ctx.roundRect((i + 1) * cellWidth+cellWidth /8,j * cellHeight + cellHeight /3.8,cellWidth/1.3,cellHeight/1.5,[5,10]);//画圆角矩形
						ctx.roundRect((i + 1) * cellWidth+cellWidth /8,j * cellHeight + cellHeight /6,cellWidth/1.3,cellHeight/1.5,[0,RATIO*5]);//画圆角矩形
						ctx.fill();
						// ctx.beginPath();
						// ctx.arc((i + 1) * cellWidth + cellWidth / 2, j * cellHeight + cellHeight / 2,cellHeight/1.5/2, Math.PI,Math.PI*3/2,false);
						ctx.stroke();
						//填充点数
						ctx.fillStyle = '#fff';
						let text = Math.abs(pearlArr[i][j]);
						if (text == '10') {
							text = this.$t("app.great")+this.$t("app.great");
						} else if (text == '20') {
							text = this.$t("room.resultSilver");
						} else if (text == '30') {
							text = this.$t("room.resultGold");
						} else if (text == '40') {
							text = this.$t("room.resultBomb");
						} else if (text == '11') {
							text = this.$t("app.nothing");
						}else{
							text=this.$t("app.great")+text;
						}
						// ctx.fillText(text, (i + 1) * cellWidth + cellWidth / 2 - ctx.measureText(text).width / 2, j * cellHeight + cellHeight / 1.7);
						ctx.fillText(text, (i + 1) * cellWidth + cellWidth / 2 - ctx.measureText(text).width / 2, j * cellHeight + cellHeight / 2);
					}
				}
			}else if(game==GAME_ID.dice){
				//绘制列头
				ctx.fillStyle = COLOR.red;
				ctx.fillRect(0, 0, cellWidth, cellHeight);
				ctx.fillStyle = COLOR.blue;
				for (let i = 0; i < 3; i++) {
					ctx.fillRect(0, cellHeight + (i * cellHeight), cellWidth, cellHeight);
				}
				ctx.fillStyle = "#fff";
				for (let i = 0, arr = [this.$t("room.bigSmall"), this.$t("room.oddEven"), this.$t("room.sumCount"), this.$t("room.eachNum")]; i < arr.length; i++) {
					ctx.fillText(arr[i], cellWidth / 2 - ctx.measureText(arr[i]).width / 2, (i * cellHeight) + cellHeight / 2);
				}
				//绘制路单
				for (let i = 0; i < pearlArr.length; i++) {
					let sum = pearlArr[i][0]+pearlArr[i][1]+pearlArr[i][2];//总和
					let text=['',sum % 2 == 0 ? this.$t("common.even") : this.$t("common.odd"),sum,[pearlArr[i][0],pearlArr[i][1],pearlArr[i][2]].join('')];//路单文本
					//设置颜色
					let color=COLOR.red;//设置填充颜色(红色)
					if(pearlArr[i][0]==pearlArr[i][1]&&pearlArr[i][1]==pearlArr[i][2]){//围
						color=COLOR.green;
						text[0]=this.$t("common.equal");
					}else if(sum>=4&&sum<=10){//小
						color=COLOR.blue;
						text[0]=this.$t("common.small");
					}else if(sum>=11&&sum<=17){//大
						color=COLOR.red;
						text[0]=this.$t("common.big");
					}
					for (let j = 0; j < Object.keys(GAME_ROW).find(key=>GAME_ROW[key].indexOf(GAME_ID.dice)!=-1); j++) {
						// 创建渐变
						let grd=ctx.createRadialGradient(
							(i + 1) * cellWidth + cellWidth / 2,
							j * cellHeight + cellHeight / 2,
							cellWidth / 2.4,
							(i + 1) * cellWidth + cellWidth / 2-(cellWidth / 2.4/2),//x坐标减去二分之一半径
							j * cellHeight + cellHeight / 2-(cellWidth / 2.4/2),
							0);
						//填充圆
						grd.addColorStop(0,color);
						grd.addColorStop(1,COLOR.ray);
						ctx.fillStyle = grd;
						ctx.beginPath();
						ctx.arc((i + 1) * cellWidth + cellWidth / 2, j * cellHeight + cellHeight / 2, cellWidth / 2.4, 0, 2 * Math.PI);//除2.4才不会刚好跟单元格一样大
						ctx.fill();
						//填充字
						ctx.fillStyle = '#fff';
						ctx.fillText(text[j], (i + 1) * cellWidth + cellWidth / 2 - ctx.measureText(text[j]).width / 2, j * cellHeight + cellHeight / 2);
					}
				}
			}else if(game==GAME_ID.rou){
				let redCode=PEARL_CODE.red;
				let blackCode=PEARL_CODE.black;
				let radius=2.4;//半径比例
				let maxPoint = [0, 0, 0, 0, 0, 0];//保存每一行露珠达到的最大x坐标点
				let lastPoint = 0;//保存上一次最后一行露珠的最大坐标点，用来比较是否可以左拐
				let currentRow;//保存当前列已达到的行数
				for (let i = 0; i < pearlArr.length; i++) {
					currentRow=0;
					for (let j = 0; j < pearlArr[i].length; j++) {//currentRow为当前列的露珠已到达的行数
						let color=COLOR.green;
						//设置边框粗细
						ctx.lineWidth = cellWidth * .12;
						//设置圆圈颜色
						let letter = pearlArr[i][j];//获取数字
						if (blackCode.indexOf(letter)!=-1) {
							color = COLOR.black;//填充颜色
						} else if (redCode.indexOf(letter)!=-1) {
							color = COLOR.red;
						}
						ctx.fillStyle =color;
						ctx.strokeStyle =color;
						//设置圆圈位置
						let x = i * cellWidth + (cellWidth / 2);//正常向下的x值
						let y = j * cellHeight + (cellHeight / 2);//正常向下的y值
						if (currentRow == 6 || x <= maxPoint[currentRow]) {//已经到达最后一行或者当前要画的x坐标小于或等于当前要画行的最大坐标，即当前要画的位置已有露珠
							if (currentRow == 6 && lastPoint < x - (pearlArr[i].length - 6) * cellWidth) {//如果当前已到达第6行并且第6行露珠的x坐标大于该行最大坐标的8倍，即前面有7个空位，则向左拐
								x = (i - ((j + 1) - currentRow)) * cellWidth + (cellWidth / 2);//当前列数-
							} else {//否则右拐
								x = (i + j - currentRow + 1) * cellWidth + (cellWidth / 2);//当前个数-当前所在行+当前列=要画的露珠列
								maxPoint[currentRow - 1] = x;//更新最大坐标点
							}
							y = (currentRow - 1) * cellHeight + (cellHeight / 2);
						} else {//否则向下画
							currentRow == 5 && (lastPoint = maxPoint[currentRow] == 0 ? cellWidth / -2 : maxPoint[currentRow]);//如果已是最后一行，则保存最大坐标点，用于左拐判断
							maxPoint[currentRow] = x;//更新最大坐标点
							currentRow++;//累加当前列露珠到达的行数
						}
						// 创建渐变
						let grd=ctx.createRadialGradient(x,y,cellWidth / 2.4,
							x-(cellWidth / 2.4/2),//x坐标减去二分之一半径
							y-(cellWidth / 2.4/2),
							0);
						grd.addColorStop(0,color);
						grd.addColorStop(1,COLOR.ray);
						ctx.fillStyle = grd;
						//绘制圆圈/斜线
						ctx.beginPath();
						ctx.arc(x, y, cellHeight / radius, 0, 2 * Math.PI);
						ctx.fill();
						//填充字
						ctx.fillStyle = '#fff';
						ctx.fillText(letter, x - ctx.measureText(letter).width / 2, y);
					}
				}
			}
		},
		/**
		 * 处理拐路数据
		 * @param {Boolean} isHold 末列的和是否保留
		 */
		setPearlData(game,arr,column,isHold){
			//bac
			var arrZ = PEARL_CODE.Z;
			var arrX = PEARL_CODE.X;
			var arrH = PEARL_CODE.H;
			//longfong
			if(game==GAME_ID.longfong){
				arrZ=PEARL_CODE.L;
				arrX=PEARL_CODE.F;
				arrH=PEARL_CODE.HE;
			}
			var arr1 = [];//外层数组
			var letter = '';//首字母
			var count = 0;//和计数器
			if(game==GAME_ID.bac||game==GAME_ID.lh||game==GAME_ID.longfong||game==GAME_ID.coin){
				for (var i = arr.length-1; i >= 0; i--) {//数据为从旧到新，所以需要从后往前遍历
					if (arr1.length < column) {//未达到需要的列数
						letter = arr[i][0].substr(0, 1);//取内层数组第一项首字母
						if (arrH.indexOf(letter)!=-1) {//是和
							if (i == arr.length - 1 && isHold) {//如果是和的前提下并且已经遍历到最后一项
								arr1[arr1.length] = [];
								arr1[arr1.length - 1].unshift(arr[i][0]);//只压入第一项和
								arr[i].length - 1 != 0 && (arr1[arr1.length - 1][0] = arr1[arr1.length - 1][0] + (arr[i].length - 1));
							} else {//不是末列
								count = arr[i].length;
								continue;
							}
						} else {//不是和
							if (count != 0) {//有统计了和
								var arr2 = [];
								for (var j = 0; j < arr[i].length - 1; j++) {//遍历并添加除了最后一项
									arr2.push(arr[i][j]);
								}
								arr2.push(arr[i][arr[i].length - 1] + count);//将统计值拼接在当前遍历到的数组的最后一项
								if (
									arr1.length > 0 && (
									(arrZ.indexOf(arr[i][0].substr(0, 1)) != -1 && arrZ.indexOf(arr1[0][0].substr(0, 1)) != -1) ||
									(arrX.indexOf(arr[i][0].substr(0, 1)) != -1 && arrX.indexOf(arr1[0][0].substr(0, 1)) != -1)
									)) {//如果当前项之前已有项并且当前项与‘和’之前的项一样为庄类或者一样为闲类，则合并到一起
									var delArr = arr1.splice(0, 1);//删掉原有的拼接好的数组中的最后一项
									arr2 = arr2.concat(delArr[0]);//并拼接到当前项的最后
								}
								arr1.unshift(arr2);
								count = 0;
							} else {//还没有统计和
								arr1.unshift(arr[i].slice());
							}
						}
					} else {
						break;
					}
				}
			}else{
				arr1=arr;
			}
			//再次循环用于验证是否需要因右拐超出画布而移除最前面部分列
			// let columnCount=0;//拐弯后的总列数
			var maxPoint = [0, 0, 0, 0, 0, 0];//保存每一行露珠达到的最大x坐标点
			var lastPoint = 0;
			for (var i = 0; i <arr1.length; i++) {//倒序遍历处理后的数组
				for (var j = 0, currentRow = 0; j < arr1[i].length; j++) {
					if (currentRow == 6 || (i+1) <= maxPoint[currentRow]) {//已经到达最后一行或者当前要画的x坐标小于或等于当前要画行的最大坐标，即当前要画的位置已有露珠
						if (!(currentRow == 6 && lastPoint < (i+1) - (arr1[i].length - 6))) {//如果非(当前已到达第6行并且向左拐不与当前行最大点重叠)，则向右拐
							maxPoint[currentRow - 1] = (i+1) + (j - (currentRow-1));//更新最大坐标点为当前个数-当前所在行+当前列=要画的露珠列
						}
					} else {//否则向下画
						currentRow == 5 && (lastPoint = maxPoint[currentRow] == 0 ? 0 : maxPoint[currentRow]);//如果已是最后一行，则保存最大坐标点，用于左拐判断
						maxPoint[currentRow] = i+1;//更新最大坐标点
						currentRow++;//累加当前列露珠到达的行数
					}
				}
			}
			for (let i = 0; i < maxPoint.length; i++) {
				if(maxPoint[i]>column){//只要有坐标点大于列数，即为超出画布
					arr1.splice(0,maxPoint[i]-column);//超过多少则移除前面多少列
					break;
				}
			}
			return arr1;
		},
		/**
		 * 设置当前视频线路id
		 */
		setCurrVideoLine(videoLineId,callback){
			if(!videoLineId){//没有指定id则配置为下一个
				let index = this.videoLineList.findIndex(v=>v.id==this.currVideoLine);
				if(this.videoLineList[index+1]){
					videoLineId=this.videoLineList[index+1].id;
					useLayer({str:this.$t("nav.videoChangeSucc")});
				}else{
					callback&&callback();
					return;
				}
			}
			localStorage.setItem("videoline",videoLineId);
			this.currVideoLine=videoLineId;
			useLayer({str:this.$t("nav.videoChangeSucc")});
		},
		/**
         * 根据游戏类型获取代号
         */
        getTextByGame(langStr,gameId,index){
            if(gameId==GAME_ID.bac){
                return this.$t(langStr+["z","x"][index]);
            }else if(gameId==GAME_ID.lh){
                return this.$t(langStr+["l","hu"][index]);
            }else if(gameId==GAME_ID.coin){
                return this.$t(langStr+["even","odd"][index]);
            }
			return "";
        },
		/**
         * 根据id获取游戏名
         */
		getGameName(gameId){
			return Object.keys(GAME_CODE).indexOf(String(gameId))==-1?this.$t("common.game"):this.$t("common."+GAME_CODE[gameId]);
        },
		/**
		 * 进入台面
		 * @param {GAME_ID} gameId 要进入的游戏
		 * @param {Object} params 要传递的参数
		 */
		enterTable(gameId,params){
			this.$router.push({
				name:GAME_CODE[gameId],
				params
			})
		},
		/**
         * 额度文本转换
         */
		chipsTxtConvert(amount){
            if(amount==Infinity){
                return "梭哈";
            }else if(amount>=10000){
                return amount/10000+"w";
            }else{
                return amount;
            }
        },
		/**
		 * 关闭引导
		 */
		closeTeach(){
			this.isShowTeach=false;
			localStorage.setItem("isTeach",false);//设置本地存储不再引导
		},
		/**
         * 根据序号获取牌
         */
		getPokerByNum(num) {
            let poker={
                name:num?Math.ceil(num/4):"?",//名称
                shape:"",//形状
                color:""//颜色
            }
            if(num&&num%4==0){
                poker.color="text-dark";
                poker.shape="bi-suit-spade-fill";//黑
            }else if(num%4==1){
                poker.color="text-danger";
                poker.shape="bi-suit-diamond-fill";//方
            }else if(num%4==2){
                poker.color="text-dark";
                poker.shape="bi-suit-club-fill";//梅
            }else if(num%4==3){
                poker.color="text-danger";
                poker.shape="bi-suit-heart-fill";//红
            }else{
				poker.color="text-secondary";
                poker.shape="bi-question-lg";//灰
			}
            if(num>0&&num<5){//1-4
                poker.name="A";
            }else if(num>40&&num<45){//41-44
                poker.name="J";
            }else if(num>44&&num<49){//45-48
                poker.name="Q";
            }else if(num>48&&num<53){//49-52
                poker.name="K";
            }
            return poker;
        },
		/**
         * 游戏是否有路子
         */
		hasPearl(gameId){
            return Object.values(GAME_ROW).find(v=>v.indexOf(Number(gameId))!=-1)?true:false;
        },
		/**
         * 跳转页面
         */
		goPage(pageName,params,state){
            if(this.$route.name==pageName){
                useLayer({str:this.$t("nav.at"+pageName.slice(0,1).toUpperCase()+pageName.slice(1).toLowerCase())});
                return;
            }else{
                // if(pageName=="home"){
                    // }
				this.beforeGoPageFn&&this.beforeGoPageFn();
                //if(this.isPageReady){
                    let p=params||{};
                    let s=state||{};
                    p.guid=this.userInfo.guid;
                    this.$router.push({
                        name:pageName,
                        params:p,
                        state:s
                    })
                //}
            }
        },
		/**
		 * 注销登录
		 */
		logout(){
			useLayer({
				type:LAYER_TYPE.confirm,
				str:this.$t("app.isLogout"),
				btn:[this.$t("common.yes"),this.$t("common.no")],
				yesFn:(i)=>{
					layer.close(i);
					this.$root.wsSend(COMMAND.all_logout, this.userInfo.name, this.userInfo.guid);
				}
			})
		},
		/**
		 * 进入页面前的事件
		 */
		// beforeGoPage(pageName){
		// 	// if(pageName=="home"&&this.){
				
		// 	// }
		// },
		
	},
	watch: {
		//监听路由变化
		$route(to) {
			if (Object.values(GAME_CODE).concat(['home','personal','help','share']).indexOf(to.name)!=-1) {
				this.showNav=true;//显示导航
			} else {//不加else设置为true的话，当页面处于home并在地址栏修改成login，又重新回到home时，showNav依然为false
				this.showNav=false;//不显示导航
			}
			layer.closeAll();
			this.resizeFn=null;
		},
		//监听客服快捷消息
		fastChatMsg(newVal){
			if(newVal.serverName!=""&&newVal.msg!=""){
				this.chatMsgTimer&&clearTimeout(this.chatMsgTimer);
				this.chatMsgTimer = setTimeout(() => {
					this.fastChatMsg={
						serverName:"",
						msg:"",
					}
				}, 3000);
			}
		},
		//改变语言时再赋值引导设置，否则会出现语言不改变问题
		currLang(){
			this.teachList=[
				{
					text:this.$t("app.helpCustomer"),
					class:"teach teach-chat"
				},
				{
					text:this.$t("app.helpPersonal"),
					class:"teach teach-personal"
				},
				{
					text:this.$t("app.helpSetting"),
					class:"teach teach-settings"
				},
			]
		}
	},
}
</script>
