import { createI18n } from "vue-i18n/index.mjs";
export default createI18n({
    legacy:false,//让setup函数可以通过t()访问
    globalInjection:true,//让template可以像vue2一样使用$t()访问
    locale: navigator.language.substring(0,navigator.language.indexOf('-')),
    fallbackLocale:"zh",
    messages:langConfig()
    // messages:{
    //     "zh":{
    //         common:{
    //             xd:"闲对",
    //             zd:"庄对",
    //             x:"闲",
    //             z:"庄",
    //             l:"龙",
    //             hu:"虎",
    //             h:"和",
    //             boot:"靴",
    //             bout:"局",
    //             close:"关闭",
    //             subSuccess:"提交成功",
    //             subBan:"您已被禁止游戏",
    //             subErr:"提交失败",
    //             unMoney:"可用余额不足",
    //             subCountdown:"计时将要结束，不可提交",
    //             service:"系统维护中...",
    //             cancelSuccess:"取消成功",
    //             cancelErr:"取消失败",
    //             isCancel:"是否确认要继续取消？",
    //             yes:"确认",
    //             no:"取消",
    //             lose:"您输了",
    //             win:"您赢了",
    //             bac:"百家/龙虎",
    //             cor:"真人牛牛",
    //             dice:"真人骰子",
    //             rou:"真人轮盘",
    //             ok:"好的",
    //             isDel:"是否确认删除？",
    //             sum:"总",
    //             one:"一",
    //             two:"二",
    //             three:"三",
    //             four:"四",
    //             five:"五",
    //             six:"六",
    //             seven:"七",
    //             eight:"八",
    //             nine:"九",
    //             load:"加载中...",
    //             big:"大",
    //             small:"小",
    //             odd:"单",
    //             even:"双",
    //             equal:"围",
    //             red:"红",
    //             black:"黑",
    //             green:"绿",
    //         },
    //         app:{
    //             lowline:"当前线路较慢或不可用，建议返回登录选用其他线路",
    //             getout:"由于您长时间未下注，请重新登录！",
    //             otherLogin:"当前账号已在其他地方登录，若非您本人操作，请尽快修改密码！",
    //             loginTop:"您的账号被强制登出！",
    //             toLogin:"重新登录",
    //             isLogout:"是否确认注销登录？",
    //             nothing:"无",
    //             great:"牛",
    //         },
    //         nav:{
    //             realMoney:"真人",
    //             esportsMoney:"竞技",
    //             settings:"设置",
    //             home:"大厅",
    //             personal:"服务",
    //             network:"视频线路",
    //             sound:"音效设置",
    //             agreement:"协议条款",
    //             logout:"注销登录",
    //             atHome:"当前已在游戏大厅",
    //             atPersonal:"当前已在服务大厅",
    //             atChat:"当前已在客服中心",
    //             videoChangeSucc:"已切换视频线路",
    //             chat:"客服",
    //             bgAudio:"背景音乐",
    //             startAudio:"开始/结束提示",
    //             resultAudio:"开注播报",
    //             tenAudio:"最后10秒提示",
    //         },
    //         video:{
    //             pool:"彩池",
    //             refresh:"刷新视频",
    //             focal:"焦距转换",
    //             noRebate:"当前免佣",
    //             rebate:"当前非免佣",
    //             noChangeRebate:"当前台没有免佣模式",
    //             videoErr:"当前视频流不可用，请选择其他视频线路",
    //             noFocal:"没有其他焦距可转换",
    //             noVideo:"当前台面暂无视频",
    //         },
    //         roomlist:{
    //             hear:"当前位置",
    //             hearTip:"当前已在该台面！",
    //             old:"经典",
    //             new:"新版",
    //         },
    //         login:{
    //             userName:"用户名",
    //             pwd:"密码",
    //             code:"验证码",
    //             verifyType:"验证方式：",
    //             slider:"滑块",
    //             chooseNetwork:"选择线路：",
    //             login:"登 录",
    //             slideTip:"请按住滑块，拖动到最右",
    //             slideSuccessTip:"验证通过",
    //             userEmpty:"用户名不能为空",
    //             pwdEmpty:"密码不能为空",
    //             slideVerify:"请拖动滑块进行验证",
    //             codeVerify:"验证码错误",
    //             loginErr:"用户名或密码错误",
    //             wsError:"当前线路不可用，请尝试其他线路",
    //             loginFail:"登录失败",
    //             logining:"登录中...",
    //             gameModel:"游戏模式：",
    //             classics:"经典",
    //             tel:"电话",
    //             colorType:"色调选择：",
    //             light:"明亮",
    //             dark:"黑夜",
    //             fullscreen:"全屏",
    //             unFullscreen:"取消全屏",
    //         },
    //         home:{
    //             goRoomErr:"进入台面失败",
    //             moreGame:"更多游戏",
    //         },
    //         room:{
    //             allBet:"总投",
    //             ingBet:"预投",
    //             edBet:"已投",
    //             multipleRoom:"多台",
    //             singleRoom:"单台",
    //             clear:"清除",
    //             cancel:"取消",
    //             confirm:"确定",
    //             nextz:"下局庄",
    //             nextx:"下局闲",
    //             nextl:"下局龙",
    //             nexthu:"下局虎",
    //             willz:"庄问路",
    //             willx:"闲问路",
    //             willl:"龙问路",
    //             willhu:"虎问路",
    //             rebateTxt:"庄赢6点赔一半",
    //             lowerLimitred:"该位置下限为",
    //             higherLimitred:"该位置上限为",
    //             laveLimitred:"该位置剩余限额为",
    //             noStartGame:"倒计时已结束，请等待下一轮游戏的开始",
    //             fullAmount:"该位置已满额",
    //             changeTable:"切换台面",
    //             isChangeRebate:"您有尚未提交的注额，切换模式将被清除，是否仍要切换？",
    //             unRebate:"免佣",
    //             singleCount:"平倍",
    //             doubleCount:"翻倍",
    //             multiplesCount:"多倍",
    //             resultGreat:"牛牛",
    //             resultNothing:"无牛",
    //             resultBomb:"炸弹",
    //             resultGold:"金牛",
    //             resultSilver:"银牛",
    //             bigSmall:"大小",
    //             oddEven:"单双",
    //             sumCount:"和值",
    //             eachNum:"珠仔",
    //         },
    //         personal:{
    //             funds:"充值提现",
    //             recharge:"充值",
    //             withdraw:"提现",
    //             record:"记录查询",
    //             rechargeLog:"充值记录",
    //             withdrawLog:"提现记录",
    //             submitLog:"下注记录",
    //             updateInformation:"信息修改",
    //             updateSource:"资金渠道",
    //             updatePwd:"修改密码",
    //             promote:"推广反馈",
    //             share:"分享",
    //             problem:"建议反馈",
    //             agreement:"协议条款",
    //             about:"关于我们",
    //             back:"返回",
    //             rechargeAmount:"充值金额",
    //             rechargeType:"充值类型",
    //             payment:"支付方式",
    //             currency:"币种",
    //             submit:"提交",
    //             withdrawAmount:"提现金额",
    //             withdrawal:"提现方式",
    //             withdrawType:"提现类型",
    //             accepted:"收款方",
    //             withdrawPwd:"提现密码",
    //             startDate:"开始日期",
    //             endDate:"结束日期",
    //             date:"日期",
    //             money:"金额",
    //             rechargeState:"充值状态",
    //             withdrawState:"提现状态",
    //             gameType:"游戏类型",
    //             room:"房间",
    //             round:"靴局",
    //             result:"结果",
    //             final:"输赢",
    //             deposit:"余额",
    //             media:"设备",
    //             report:"注单",
    //             alipay:"支付宝",
    //             name:"姓名",
    //             account:"账号",
    //             unionPay:"银联",
    //             bank:"开户行",
    //             wechat:"微信",
    //             virtualCurrency:"区块链",
    //             walletAddress:"钱包地址",
    //             pwdType:"密码类型",
    //             loginPwd:"登录密码",
    //             withdrawPwd:"提现密码",
    //             oldPwd:"原密码",
    //             newPwd:"新密码",
    //             againPwd:"确认密码",
    //             rechargeAmountErr:"充值金额有误",
    //             rechargeTypeErr:"充值类型有误",
    //             rechargeSuccess:"充值申请提交成功！",
    //             rechargeTypeChoose:"请选择充值类型",
    //             rechargePaymentChoose:"请选择支付方式",
    //             rechargeCurrencyChoose:"请选择币种",
    //             rechargeErr:"充值失败",
    //             rechargeRange:"单笔充值金额为",
    //             submitMuch:"提交过于频繁，请稍后再试",
    //             noVip:"非注册会员无法充值",
    //             invalidUser:"用户异常",
    //             submitAgain:"您已提交过该充值",
    //             aisleErr:"充值通道异常，请尝试其他充值方式！",
    //             bossErr:"因您上级代理问题，暂时无法为您充值！",
    //             officialErr:"官方充值额度不足，请尝试其他方式或币种！",
    //             qrcodeIs:"您的验证码是",
    //             codeToRemark:"请在充值时将验证码填写到备注中，以确保充值成功！",
    //             scanOrClick:"请扫码或点击",
    //             hear:"这里",
    //             toRecharge:"充值",
    //             all:"全部",
    //             real:"真人",
    //             esports:"竞技",
    //             phone:"手机",
    //             pc:"电脑",
    //             enterTitle:"请输入标题",
    //             enterProblem:"请输入您的问题或建议",
    //             titleEmpty:"标题不能为空",
    //             contentEmpty:"内容不能为空",
    //             send:"发送",
    //             sendErr:"发送失败",
    //             withdrawPwdErr:"提现密码有误",
    //             withdrawAmountErr:"提现金额有误",
    //             withdrawRange:"单笔提现额度为",
    //             withdrawHandlingFee:"每次提现需要50手续费，超过5万需要100手续费",
    //             withdrawAmountLack:"提现余额不足",
    //             withdrawWithdrawalChoose:"请选择提现方式",
    //             withdrawTypeChoose:"请选择提现类型",
    //             toAddAccepted:"请到信息修改项为该提现方式添加收款方",
    //             reportErr:"存在未结算注单",
    //             acceptedErr:"无效的收款方",
    //             withdrawErr:"提现失败",
    //             withdrawSuccess:"提现申请已提交成功，请耐心等待！",
    //             updLoginPwdSuccess:"修改成功，请重新登录",
    //             updateSuccess:"修改成功",
    //             oldPwdEmpty:"原密码不能为空",
    //             newPwdEmpty:"新密码不能为空",
    //             againPwdEmpty:"确认密码不能为空",
    //             pwdEmpty:"原密码或新密码不能为空",
    //             pwdErr:"原密码错误",
    //             pwdUnequal:"新密码与确认密码不一致",
    //             pwdEqual:"新密码与原密码不能一致",
    //             pwdTypeChoose:"请选择密码类型",
    //             pwdLength:"密码长度须为6~12位",
    //             accountLimit:"该类型的账号已达上限",
    //             invalidCurrency:"无效币种",
    //             sourceHasBills:"该账号有未完成的订单，暂时无法删除",
    //             nameEmpty:"姓名不能为空",
    //             accountEmpty:"账号不能为空",
    //             bankEmpty:"开户行不能为空",
    //             walletAddressEmpty:"钱包地址不能为空",
    //             stateWait:"待处理",
    //             stateDoing:"处理中",
    //             stateReady:"已完成",
    //             stateFail:"未通过",
    //             total:"总额",
    //             valid:"有效额",
    //             noSubmitLog:"查不到任何记录，赶紧去赢一把吧~",
    //             noWithdrawLog:"查不到任何记录，赶紧去提一笔吧~",
    //             noRechargeLog:"查不到任何记录，赶紧去充一笔吧~",
    //         },
    //         chat:{
    //             serverCon:"客服服务器连接中...",
    //             serverErr:"客服服务器连接失败！",
    //             serverNo:"暂无客服",
    //             serverUrl:"客服服务器地址有误",
    //             helpTip:"您好，有什么可以帮您？",
    //             serverNoTip:"您好，当前没有客服在线",
    //             msgEmpty:"发送消息不能为空",
    //         },
    //     },
    //     "en":{
    //         common:{
    //             xd:"XD",
    //             zd:"ZD",
    //             x:"X",
    //             z:"Z",
    //             l:"D",
    //             hu:"T",
    //             h:"H",
    //             boot:"Boot",
    //             bout:"Bout",
    //             close:"close",
    //             subSuccess:"Successful",
    //             subBan:"You have been banned from the game!",
    //             subErr:"Failure",
    //             unMoney:"Not enough balance",
    //             subCountdown:"The timer is about to expire and cannot be submitted!",
    //             service:"System is under maintenance!",
    //             cancelSuccess:"Cancel success",
    //             cancelErr:"Cancel failed",
    //             isCancel:"Are you sure you want to cancel?",
    //             yes:"Yes",
    //             no:"Cancel",
    //             lose:"You lose ",
    //             win:"You win ",
    //             bac:"Baccarat/DragonTiger",
    //             cor:"Corrida",
    //             dice:"Dice",
    //             rou:"Roulette",
    //             ok:"OK",
    //             isDel:"Are you sure you want to delete?",
    //             sum:"sum",
    //             one:"1",
    //             two:"2",
    //             three:"3",
    //             four:"4",
    //             five:"5",
    //             six:"6",
    //             seven:"7",
    //             eight:"8",
    //             nine:"9",
    //             load:"Loading...",
    //             big:"Big",
    //             small:"Small",
    //             odd:"Odd",
    //             even:"Even",
    //             equal:"Equal",
    //             red:"red",
    //             black:"black",
    //             green:"green",
    //         },
    //         app:{
    //             lowline:"The current network line is slow or unavailable, it is recommended to return to the login to choose other.",
    //             getout:"You haven't placed a bet for a long time, please login again!",
    //             otherLogin:"The current account has been logged in elsewhere. If it is not your operation, please change the password as soon as possible!",
    //             loginTop:"Your account is forcibly logged out!",
    //             toLogin:"To Login",
    //             isLogout:"Are you sure you want to log out?",
    //             nothing:"N",
    //             great:"G",
    //         },
    //         nav:{
    //             realMoney:"Real",
    //             esportsMoney:"Esports",
    //             settings:"Settings",
    //             home:"Home",
    //             personal:"Personal",
    //             network:"Network",
    //             sound:"Sound",
    //             agreement:"Agreement",
    //             logout:"Logout",
    //             atHome:"already in the Home",
    //             atPersonal:"already in the Service",
    //             videoChangeSucc:"Video line modified successfully!",
    //             home:"Home",
    //             chat:"Chat",
    //             bgAudio:"background music",
    //             startAudio:"start/end beep",
    //             resultAudio:"betting broadcast",
    //             tenAudio:"last 10 seconds",
    //         },
    //         video:{
    //             pool:"Prize Pool",
    //             refresh:"Refresh Video",
    //             focal:"Focal Conversion",
    //             noRebate:"No Rebate Now",
    //             rebate:"Rebate Now",
    //             noChangeRebate:"There is no commission free mode for this table!",
    //             videoErr:"The current video stream is unavailable, please select another video line!",
    //             noFocal:"No other focal lengths to convert!",
    //             noVideo:"当前台面暂无视频",
    //         },
    //         roomlist:{
    //             hear:"Current",
    //             hearTip:"It's here now!",
    //             old:"OLD",
    //             new:"NEW",
    //         },
    //         login:{
    //             userName:"UserName",
    //             pwd:"Password",
    //             code:"VerifyCode",
    //             verifyType:"Verify Type：",
    //             slider:"Slider",
    //             chooseNetwork:"Choose Network：",
    //             login:"Login",
    //             slideTip:"Hold the slider and drag to the right",
    //             slideSuccessTip:"Verification succeeded",
    //             userEmpty:"Username can not be empty!",
    //             pwdEmpty:"Password can not be empty!",
    //             slideVerify:"Please slide the blocks above for verify!",
    //             codeVerify:"Verification code error!",
    //             loginErr:"Bad username or password!",
    //             wsError:"The current line is not available, please try another line!",
    //             loginFail:"Login failed!",
    //             logining:"Logging...",
    //             gameModel:"Game Mode：",
    //             classics:"Classics",
    //             tel:"Telephone",
    //             colorType:"Choose a color tone：",
    //             light:"Bright",
    //             dark:"Dark",
    //             fullscreen:"Fullscreen",
    //             unFullscreen:"Exit Fullscreen",
    //         },
    //         home:{
    //             goRoomErr:"Failed to enter the room!",
    //             moreGame:"more games",
    //         },
    //         room:{
    //             allBet:"All Bet",
    //             ingBet:"Will Bet",
    //             edBet:"Already Bet",
    //             multipleRoom:"Multiple",
    //             singleRoom:"Single",
    //             clear:"Clear",
    //             cancel:"Cancel",
    //             confirm:"Submit",
    //             nextz:"Next Z",
    //             nextx:"Next X",
    //             willz:"Will Z",
    //             willx:"Will X",
    //             willl:"Will L",
    //             willhu:"Will HU",
    //             rebateTxt:"Banker wins 6 points and loses half",
    //             lowerLimitred:"Here at least ",
    //             higherLimitred:"Here at most ",
    //             laveLimitred:"The remaining limit here is ",
    //             noStartGame:"The countdown is over, please wait for the start of the next round of the game!",
    //             fullAmount:"Here at full",
    //             changeTable:"Change Room",
    //             isChangeRebate:"You have not submitted a bet, continue will be cleared, continue?",
    //             unRebate:"unRebate",
    //             singleCount:"Single",
    //             doubleCount:"Double",
    //             multiplesCount:"Multiples",
    //             resultGreat:"Great",
    //             resultNothing:"None",
    //             resultBomb:"Bomb",
    //             resultGold:"Gold",
    //             resultSilver:"Silver",
    //             bigSmall:"Size",
    //             oddEven:"Odd",
    //             sumCount:"Sum",
    //             eachNum:"Num",
    //         },
    //         personal:{
    //             funds:"Funds",
    //             recharge:"Recharge",
    //             withdraw:"Withdraw",
    //             record:"Record",
    //             rechargeLog:"Recharge Log",
    //             withdrawLog:"Withdraw Log",
    //             submitLog:"Bet Log",
    //             updateInformation:"Information",
    //             updateSource:"Funds Source",
    //             updatePwd:"Password",
    //             promote:"Promote",
    //             share:"Share",
    //             problem:"Problem",
    //             agreement:"Agreement",
    //             about:"About",
    //             back:"Back",
    //             rechargeAmount:"Amount",
    //             rechargeType:"Type",
    //             payment:"Payment",
    //             currency:"Currency",
    //             submit:"Submit",
    //             withdrawAmount:"Amount",
    //             withdrawal:"Withdrawal",
    //             withdrawType:"Type",
    //             accepted:"Accepted",
    //             withdrawPwd:"Withdraw Password",
    //             startDate:"Start Date",
    //             endDate:"End Date",
    //             date:"Date",
    //             money:"Money",
    //             rechargeState:"Recharge State",
    //             withdrawState:"Withdraw State",
    //             gameType:"Game Type",
    //             room:"Room",
    //             round:"Round",
    //             result:"Result",
    //             final:"Final",
    //             deposit:"Deposit",
    //             media:"Media",
    //             report:"Bet Report",
    //             alipay:"Alipay",
    //             name:"Name",
    //             account:"Account",
    //             unionPay:"Union pay",
    //             bank:"Bank",
    //             wechat:"Wechat",
    //             virtualCurrency:"Virtual currency",
    //             walletAddress:"Wallet address",
    //             pwdType:"Password Type",
    //             loginPwd:"Login Password",
    //             withdrawPwd:"Withdraw Password",
    //             oldPwd:"Old Password",
    //             newPwd:"New Password",
    //             againPwd:"Again Password",
    //             rechargeAmountErr:"Recharge amount Error!",
    //             rechargeTypeErr:"Recharge type Error!",
    //             rechargeSuccess:"Application for recharge submitted successfully!",
    //             rechargeTypeChoose:"Please select a recharge type!",
    //             rechargePaymentChoose:"Please select a payment!",
    //             rechargeCurrencyChoose:"Please select a currency!",
    //             rechargeErr:"Recharge failed!",
    //             rechargeRange:"A single recharge amount is ",
    //             submitMuch:"Too frequent submissions, please try again later!",
    //             noVip:"Not a registered member can not recharge!",
    //             invalidUser:"Invalid user!",
    //             submitAgain:"You have already submitted this recharge!",
    //             aisleErr:"The recharge channel is abnormal, please try other recharge methods!",
    //             bossErr:"Due to the reason of your superior agent, temporarily unable to recharge for you!",
    //             officialErr:"The official recharge amount is insufficient, please try other methods or currencies!",
    //             qrcodeIs:"Your verification code is ",
    //             codeToRemark:"Please fill in the verification code in the remarks to make the recharge successful!",
    //             scanOrClick:"Please scan the code or click.",
    //             hear:"Hear",
    //             toRecharge:"to recharge",
    //             all:"All",
    //             real:"Real",
    //             esports:"Esports",
    //             phone:"Phone",
    //             pc:"PC",
    //             enterTitle:"Please enter a title",
    //             enterProblem:"Please enter your question or suggestion",
    //             titleEmpty:"Title can not be empty!",
    //             contentEmpty:"Content can not be empty!",
    //             send:"Send",
    //             sendErr:"Send Error!",
    //             withdrawPwdErr:"Withdraw password Error!",
    //             withdrawAmountErr:"Withdraw amount Error!",
    //             withdrawRange:"A single withdrawal amount is ",
    //             withdrawHandlingFee:"50 handling fee for each withdrawal, 100 handling fee for more than 50,000",
    //             withdrawAmountLack:"Insufficient withdrawal balance!",
    //             withdrawWithdrawalChoose:"Please select a withdrawal!",
    //             withdrawTypeChoose:"Please select a withdraw type!",
    //             toAddAccepted:"Please go to the information modification to add the payee for the withdrawal method!",
    //             reportErr:"Have unsettled bills!",
    //             acceptedErr:"Invalid payee!",
    //             withdrawErr:"Withdrawal failed!",
    //             withdrawSuccess:"The withdrawal request has been submitted, please be patient!",
    //             updLoginPwdSuccess:"The modification is successful, please log in again!",
    //             oldPwdEmpty:"Old password can not be empty!",
    //             newPwdEmpty:"New password can not be empty!",
    //             againPwdEmpty:"Again password can not be empty!",
    //             pwdEmpty:"The original password or the new password cannot be empty!",
    //             pwdErr:"Old password Error!",
    //             pwdUnequal:"The new password is different from the again password!",
    //             pwdEqual:"The new password does not match the old password!",
    //             pwdTypeChoose:"Please select a password type!",
    //             pwdLength:"Password length must be 6~12 characters!",
    //             accountLimit:"This type of account has reached its limit!",
    //             invalidCurrency:"Invalid currency!",
    //             sourceHasBills:"This account has unfinished bills and cannot be deleted temporarily!",
    //             nameEmpty:"Name can not be empty!",
    //             accountEmpty:"Account can not be empty!",
    //             bankEmpty:"Bank can not be empty!",
    //             walletAddressEmpty:"Wallet address cannot be empty!",
    //             stateWait:"Pending",
    //             stateDoing:"Processing",
    //             stateReady:"Completed",
    //             stateFail:"Failed",
    //             total:"Total",
    //             valid:"Effective",
    //             noSubmitLog:"Can't find any records. Go to win quickly~",
    //             noWithdrawLog:"Can't find any records. Go to withdraw the cash now~",
    //             noRechargeLog:"Can't find any records, go to recharge now~",
    //         },
    //         chat:{
    //             serverCon:"The customer service server is connected...",
    //             serverErr:"Customer service server connection failed!",
    //             serverNo:"No customer service",
    //             serverUrl:"The customer service server address is not configured!",
    //             helpTip:"Hello, how can I help you?",
    //             serverNoTip:"There is no customer service online at present!",
    //             msgEmpty:"Sending message cannot be empty",
    //         }
    //     },
    //     "th":{
    //         common:{
    //             xd:"คู่ฟรี",
    //             zd:"จ้วงดุย",
    //             x:"ว่าง",
    //             z:"หมู่บ้าน",
    //             l:"มังกร",
    //             hu:"เสือ",
    //             h:"และ",
    //             boot:"กลม",
    //             bout:"สำนัก",
    //             close:"ปิด",
    //             subSuccess:"ส่งเรียบร้อยแล้ว",
    //             subBan:"คุณถูกแบนจากเกม",
    //             subErr:"ส่งไม่สำเร็จ",
    //             unMoney:"ยอดเงินคงเหลือไม่เพียงพอ",
    //             subCountdown:"ตัวจับเวลากำลังจะหมดอายุและไม่สามารถส่งได้",
    //             service:"การบำรุงรักษาระบบ...",
    //             cancelSuccess:"ยกเลิกความสำเร็จ",
    //             cancelErr:"การยกเลิกล้มเหลว",
    //             isCancel:"คุณแน่ใจหรือว่าต้องการยกเลิกต่อไป?",
    //             yes:"ยืนยัน",
    //             no:"ยกเลิก",
    //             lose:"คุณแพ้",
    //             win:"คุณได้รับรางวัล",
    //             bac:"ไป่เจีย/เสือมังกร",
    //             cor:"คอร์ริดา",
    //             dice:"ลูกเต๋า",
    //             rou:"รูเล็ต",
    //             ok:"ตกลง",
    //             isDel:"ไม่ว่าจะยืนยันการลบ?",
    //             sum:"ผลรวม",
    //             one:"1",
    //             two:"2",
    //             three:"3",
    //             four:"4",
    //             five:"5",
    //             six:"6",
    //             seven:"7",
    //             eight:"8",
    //             nine:"9",
    //             load:"กำลังโหลด...",
    //             big:"ใหญ่",
    //             small:"เล็ก",
    //             odd:"เดียว",
    //             even:"คู่",
    //             equal:"ไว",
    //             red:"สีแดง",
    //             black:"สีดำ",
    //             green:"สีเขียว",
    //         },
    //         app:{
    //             lowline:"สายปัจจุบันช้าหรือไม่พร้อมใช้งาน ขอแนะนำให้กลับไปเข้าสู่ระบบและเลือกสายอื่น",
    //             getout:"เนื่องจากคุณไม่ได้วางเดิมพันเป็นเวลานาน โปรดเข้าสู่ระบบอีกครั้ง!",
    //             otherLogin:"บัญชีปัจจุบันถูกลงชื่อเข้าใช้ที่อื่น หากไม่ใช่การดำเนินการของคุณ โปรดเปลี่ยนรหัสผ่านโดยเร็วที่สุด!",
    //             loginTop:"บัญชีของคุณถูกบังคับให้ออกจากระบบ!",
    //             toLogin:"ลงทะเบียนใหม่",
    //             isLogout:"คุณแน่ใจว่าคุณต้องการที่จะออกจากระบบ?",
    //             nothing:"N",
    //             great:"G",
    //         },
    //         nav:{
    //             realMoney:"คนจริง",
    //             esportsMoney:"กีฬา",
    //             settings:"ติดตั้ง",
    //             home:"ห้องโถง",
    //             personal:"ให้บริการ",
    //             network:"วีดีโอไลน์",
    //             sound:"การตั้งค่าเสียง",
    //             agreement:"เงื่อนไขข้อตกลง",
    //             logout:"ออกจากระบบ",
    //             atHome:"ขณะนี้อยู่ในล็อบบี้เกม",
    //             atPersonal:"อยู่ในห้องบริการ",
    //             videoChangeSucc:"เปลี่ยนสายวิดีโอ",
    //             chat:"บริการลูกค้า",
    //             bgAudio:"เพลงประกอบ",
    //             startAudio:"เริ่มต้น/สิ้นสุดพร้อมท์",
    //             resultAudio:"เปิดเดิมพัน",
    //             tenAudio:"10 วินาทีสุดท้าย",
    //         },
    //         video:{
    //             pool:"หม้อ",
    //             refresh:"รีเฟรชวิดีโอ",
    //             focal:"เปลี่ยนโฟกัส",
    //             noRebate:"ปัจจุบันไม่มีค่าคอมมิชชั่น",
    //             rebate:"ปัจจุบันไม่มีค่าคอมมิชชั่นฟรี",
    //             noChangeRebate:"ไม่มีโหมดปลอดค่าคอมมิชชันที่แผนกต้อนรับปัจจุบัน",
    //             videoErr:"สตรีมวิดีโอปัจจุบันไม่พร้อมใช้งาน โปรดเลือกสายวิดีโออื่น",
    //             noFocal:"ไม่มีความยาวโฟกัสอื่นที่จะแปลง",
    //             noVideo:"ขณะนี้ไม่มีวิดีโอที่ด้านหน้า",
    //         },
    //         roomlist:{
    //             hear:"ตำแหน่งปัจจุบัน",
    //             hearTip:"ขณะนี้อยู่บนโต๊ะ!",
    //             old:"เก่า",
    //             new:"ใหม่",
    //         },
    //         login:{
    //             userName:"ชื่อผู้ใช้",
    //             pwd:"รหัสผ่าน",
    //             code:"รหัสยืนยัน",
    //             verifyType:"วิธีการระบุ:",
    //             slider:"ตัวเลื่อน",
    //             chooseNetwork:"เลือกบรรทัด:",
    //             login:"เข้าสู่ระบบ",
    //             slideTip:"กรุณากดตัวเลื่อนค้างไว้แล้วลากไปทางขวาสุด",
    //             slideSuccessTip:"ตรวจสอบแล้ว",
    //             userEmpty:"ต้องระบุชื่อผู้ใช้",
    //             pwdEmpty:"รหัสผ่านไม่สามารถเว้นว่างได้",
    //             slideVerify:"โปรดลากตัวเลื่อนเพื่อยืนยัน",
    //             codeVerify:"รหัสยืนยันผิดพลาด",
    //             loginErr:"ชื่อผู้ใช้หรือรหัสผ่านผิด",
    //             wsError:"สายปัจจุบันไม่พร้อมใช้งาน โปรดลองสายอื่น",
    //             loginFail:"เข้าสู่ระบบล้มเหลว",
    //             logining:"เข้าสู่ระบบใน",
    //             gameModel:"โหมดเกม：",
    //             classics:"คลาสสิค",
    //             tel:"โทรศัพท์",
    //             colorType:"เลือกเฉดสี：",
    //             light:"มีแสงสว่างเพียงพอ",
    //             dark:"มืด",
    //             fullscreen:"เต็มหน้าจอ",
    //             unFullscreen:"ออกจากเต็มหน้าจอ",
    //         },
    //         home:{
    //             goRoomErr:"เข้าตารางไม่สำเร็จ",
    //             moreGame:"เกมส์อื่นๆ",
    //         },
    //         room:{
    //             allBet:"เดิมพันทั้งหมด",
    //             ingBet:"ก่อนม้วน",
    //             edBet:"โหวต",
    //             multipleRoom:"หลายรายการ",
    //             singleRoom:"เดี่ยว",
    //             clear:"แจ่มใส",
    //             cancel:"ยกเลิก",
    //             confirm:"แน่นอน",
    //             nextz:"เปิดเกมต่อไป",
    //             nextx:"เกมต่อไป",
    //             nextl:"เกมต่อไป มังกร",
    //             nexthu:"เกมต่อไปเสือ",
    //             willz:"จ้วง เหวินหลู่",
    //             willx:"สอบถามเส้นทาง",
    //             willl:"มังกรถามทาง",
    //             willhu:"เสือถามทาง",
    //             rebateTxt:"เจ้ามือ ชนะ 6 แต้ม แพ้ครึ่ง",
    //             lowerLimitred:"ขีดจำกัดล่างของตำแหน่งนี้คือ",
    //             higherLimitred:"ตำแหน่งนี้ต่อยอดที่",
    //             laveLimitred:"ขีดจำกัดที่เหลือสำหรับสถานที่นี้คือ",
    //             noStartGame:"สิ้นสุดการนับถอยหลัง โปรดรอการเริ่มเกมรอบต่อไป",
    //             fullAmount:"ตำแหน่งนี้เต็มแล้ว",
    //             changeTable:"สลับโต๊ะ",
    //             isChangeRebate:"คุณมีเดิมพันที่ยังไม่ได้ส่ง โหมดสวิตซ์จะถูกล้าง คุณยังต้องการเปลี่ยนหรือไม่?",
    //             unRebate:"ไม่มีค่าคอมมิชชั่น",
    //             singleCount:"มีเพียงหนึ่ง",
    //             doubleCount:"ซึ่งเป็นสองเท่า",
    //             multiplesCount:"กาละ",
    //             resultGreat:"เยี่ยมมาก",
    //             resultNothing:"ไม่มีอะไร",
    //             resultBomb:"ระเบิด",
    //             resultGold:"คิม",
    //             resultSilver:"ดัชนี",
    //             bigSmall:"ขนาด",
    //             oddEven:"เพียร์",
    //             sumCount:"ผลรวม",
    //             eachNum:"จำนวน",
    //         },
    //         personal:{
    //             funds:"ฝากและถอน",
    //             recharge:"เติมเงิน",
    //             withdraw:"ถอน",
    //             record:"บันทึกแบบสอบถาม",
    //             rechargeLog:"บันทึกการเติมเงิน",
    //             withdrawLog:"บันทึกการถอน",
    //             submitLog:"บันทึกการเดิมพัน",
    //             updateInformation:"การปรับเปลี่ยนข้อมูล",
    //             updateSource:"ช่องทางการระดมทุน",
    //             updatePwd:"เปลี่ยนรหัสผ่าน",
    //             promote:"ข้อเสนอแนะโปรโมชั่น",
    //             share:"แบ่งปัน",
    //             problem:"ข้อเสนอแนะ",
    //             agreement:"เงื่อนไขข้อตกลง",
    //             about:"เกี่ยวกับเรา",
    //             back:"กลับ",
    //             rechargeAmount:"จำนวนเติมเงิน",
    //             rechargeType:"ประเภทเติมเงิน",
    //             payment:"วิธีการชำระเงิน",
    //             currency:"สกุลเงิน",
    //             submit:"ส่ง",
    //             withdrawAmount:"จำนวนเงินที่ถอน",
    //             withdrawal:"วิธีการถอนเงิน",
    //             withdrawType:"ประเภทการถอน",
    //             accepted:"ผู้รับผลประโยชน์",
    //             withdrawPwd:"รหัสผ่านการถอน",
    //             startDate:"วันที่เริ่มต้น",
    //             endDate:"วันที่สิ้นสุด",
    //             date:"วันที่",
    //             money:"จำนวน",
    //             rechargeState:"สถานะการเติมเงิน",
    //             withdrawState:"สถานะการถอน",
    //             gameType:"ประเภทเกม",
    //             room:"ห้อง",
    //             round:"รองเท้าบูท",
    //             result:"ผลลัพธ์",
    //             final:"ชนะหรือแพ้",
    //             deposit:"สมดุล",
    //             media:"อุปกรณ์",
    //             report:"เดิมพัน",
    //             alipay:"อาลีเพย์",
    //             name:"ชื่อ",
    //             account:"บัญชีผู้ใช้",
    //             unionPay:"ยูเนี่ยนเพย์",
    //             bank:"ธนาคาร",
    //             wechat:"wechat",
    //             virtualCurrency:"สกุลเงินเสมือน",
    //             walletAddress:"ที่อยู่กระเป๋าสตางค์",
    //             pwdType:"ประเภทรหัสผ่าน",
    //             loginPwd:"รหัสผ่านในการเข้าสู่ระบบ",
    //             withdrawPwd:"รหัสผ่านการถอน",
    //             oldPwd:"รหัสผ่านเก่า",
    //             newPwd:"รหัสผ่านใหม่",
    //             againPwd:"ยืนยันรหัสผ่าน",
    //             rechargeAmountErr:"จำนวนเงินที่เติมไม่ถูกต้อง",
    //             rechargeTypeErr:"เติมเงินผิดประเภท",
    //             rechargeSuccess:"ส่งใบสมัครเติมเงินเรียบร้อยแล้ว!",
    //             rechargeTypeChoose:"กรุณาเลือกประเภทการเติมเงิน",
    //             rechargePaymentChoose:"กรุณาเลือกวิธีการชำระเงิน",
    //             rechargeCurrencyChoose:"กรุณาเลือกสกุลเงิน",
    //             rechargeErr:"เติมเงินไม่สำเร็จ",
    //             rechargeRange:"จำนวนการเติมเงินครั้งเดียวคือ",
    //             submitMuch:"ส่งบ่อยเกินไป โปรดลองอีกครั้งในภายหลัง",
    //             noVip:"สมาชิกที่ไม่ได้ลงทะเบียนไม่สามารถเติมเงินได้",
    //             invalidUser:"ข้อยกเว้นผู้ใช้",
    //             submitAgain:"คุณได้ส่งการเติมเงินนี้แล้ว",
    //             aisleErr:"ช่องทางการเติมเงินผิดปกติ โปรดลองวิธีการเติมเงินแบบอื่น!",
    //             bossErr:"เนื่องจากปัญหาของตัวแทนที่เหนือกว่าของคุณ ไม่สามารถเติมเงินให้คุณได้ชั่วคราว!",
    //             officialErr:"จำนวนเงินที่เติมอย่างเป็นทางการไม่เพียงพอ โปรดลองใช้วิธีหรือสกุลเงินอื่น!",
    //             qrcodeIs:"รหัสยืนยันของคุณคือ",
    //             codeToRemark:"กรุณากรอกรหัสยืนยันในหมายเหตุเมื่อทำการชาร์จไฟเพื่อให้แน่ใจว่าการชาร์จสำเร็จ!",
    //             scanOrClick:"กรุณาสแกนรหัสหรือคลิก",
    //             hear:"ที่นี่",
    //             toRecharge:"เติมเงิน",
    //             all:"ทั้งหมด",
    //             real:"คนจริง",
    //             esports:"กีฬา",
    //             phone:"โทรศัพท์มือถือ",
    //             pc:"คอมพิวเตอร์",
    //             enterTitle:"กรุณาใส่ชื่อ",
    //             enterProblem:"กรุณาใส่คำถามหรือข้อเสนอแนะของคุณ",
    //             titleEmpty:"ชื่อไม่สามารถเว้นว่างได้",
    //             contentEmpty:"เนื้อหาไม่สามารถเว้นว่างได้",
    //             send:"ส่ง",
    //             sendErr:"การส่งล้มเหลว",
    //             withdrawPwdErr:"รหัสผ่านการถอนไม่ถูกต้อง",
    //             withdrawAmountErr:"จำนวนเงินที่ถอนไม่ถูกต้อง",
    //             withdrawRange:"จำนวนการถอนเดียวคือ",
    //             withdrawHandlingFee:"ค่าธรรมเนียมการจัดการ 50 สำหรับการถอนแต่ละครั้ง ค่าธรรมเนียมการจัดการ 100 สำหรับมากกว่า 50,000",
    //             withdrawAmountLack:"ยอดถอนไม่เพียงพอ",
    //             withdrawWithdrawalChoose:"กรุณาเลือกวิธีการถอนเงิน",
    //             withdrawTypeChoose:"กรุณาเลือกประเภทการถอน",
    //             toAddAccepted:"โปรดไปที่รายการแก้ไขข้อมูลเพื่อเพิ่มผู้รับเงินสำหรับวิธีการถอนเงินนี้",
    //             reportErr:"มีการเดิมพันที่ไม่แน่นอน",
    //             acceptedErr:"ผู้รับเงินไม่ถูกต้อง",
    //             withdrawErr:"การถอนเงินล้มเหลว",
    //             withdrawSuccess:"ส่งใบสมัครถอนเงินสำเร็จแล้ว โปรดอดใจรอ!",
    //             updLoginPwdSuccess:"แก้ไขเรียบร้อยแล้ว กรุณาเข้าสู่ระบบใหม่อีกครั้ง",
    //             updateSuccess:"แก้ไขเรียบร้อยแล้ว",
    //             oldPwdEmpty:"รหัสผ่านเดิมไม่สามารถเว้นว่างได้",
    //             newPwdEmpty:"รหัสผ่านใหม่ไม่สามารถเว้นว่างได้",
    //             againPwdEmpty:"ยืนยันรหัสผ่านต้องไม่เว้นว่าง",
    //             pwdEmpty:"รหัสผ่านเดิมหรือรหัสผ่านใหม่ไม่สามารถเว้นว่างได้",
    //             pwdErr:"รหัสผ่านเดิมไม่ถูกต้อง",
    //             pwdUnequal:"รหัสผ่านใหม่ไม่ตรงกับรหัสผ่านที่ยืนยันแล้ว",
    //             pwdEqual:"รหัสผ่านใหม่ไม่ตรงกับรหัสผ่านเก่า",
    //             pwdTypeChoose:"กรุณาเลือกประเภทรหัสผ่าน",
    //             pwdLength:"ความยาวรหัสผ่านต้อง 6~12 ตัวอักษร",
    //             accountLimit:"บัญชีประเภทนี้ถึงขีดจำกัดแล้ว",
    //             invalidCurrency:"สกุลเงินไม่ถูกต้อง",
    //             sourceHasBills:"บัญชีนี้มีคำสั่งซื้อที่ยังไม่เสร็จและไม่สามารถลบได้ชั่วคราว",
    //             nameEmpty:"ชื่อไม่สามารถเว้นว่างได้",
    //             accountEmpty:"บัญชีไม่สามารถเว้นว่างได้",
    //             bankEmpty:"บัญชีธนาคารต้องไม่ว่างเปล่า",
    //             walletAddressEmpty:"ต้องระบุที่อยู่กระเป๋าเงิน",
    //             stateWait:"แขวน",
    //             stateDoing:"จัดการกับ",
    //             stateReady:"ซึ่งสมบูรณ์",
    //             stateFail:"เสียเหลี่ยม",
    //             total:"ทั้งหมด",
    //             valid:"ประสิทธิผล",
    //             noSubmitLog:"ตรวจสอบประวัติไม่พบ รีบไปชนะกันเถอะ ~",
    //             noWithdrawLog:"ไม่พบบันทึก รีบไปเอาเงินมา",
    //             noRechargeLog:"ไม่พบบันทึก รีบไปเติมเงิน ~",
    //         },
    //         chat:{
    //             serverCon:"เซิร์ฟเวอร์บริการลูกค้าเชื่อมต่อ",
    //             serverErr:"บริการลูกค้าเซิร์ฟเวอร์การเชื่อมต่อล้มเหลว",
    //             serverNo:"ไม่มีบริการลูกค้า",
    //             serverUrl:"ที่อยู่เซิร์ฟเวอร์การบริการลูกค้าไม่ได้กำหนดค่า",
    //             helpTip:"สวัสดีมีอะไรให้ช่วยไหม",
    //             serverNoTip:"ไม่มีการบริการลูกค้าออนไลน์",
    //             msgEmpty:"ไม่สามารถส่งข้อความว่างเปล่า",
    //         }
    //     },
    //     "vi":{
    //         common:{
    //             xd:"cặp miễn phí",
    //             zd:"Choang cặp",
    //             x:"nhàn rỗi",
    //             z:"làng quê",
    //             l:"rồng",
    //             hu:"Con hổ",
    //             h:"và",
    //             boot:"bốt",
    //             bout:"Cục",
    //             close:"Khép kín",
    //             subSuccess:"gửi thành công",
    //             subBan:"Bạn đã bị cấm khỏi trò chơi",
    //             subErr:"Gửi không thành công",
    //             unMoney:"Không đủ số dư khả dụng",
    //             subCountdown:"Bộ hẹn giờ sắp hết hạn và không thể gửi được",
    //             service:"Bảo trì hệ thống ...",
    //             cancelSuccess:"Hủy thành công",
    //             cancelErr:"Hủy không thành công",
    //             isCancel:"Bạn có chắc chắn muốn tiếp tục hủy không?",
    //             yes:"xác nhận",
    //             no:"Hủy bỏ",
    //             lose:"bạn đã thua",
    //             win:"bạn đã thắng",
    //             bac:"Baijia/Dragon Tiger",
    //             cor:"đấu bò",
    //             dice:"Xúc xắc",
    //             rou:"bàn xoay",
    //             ok:"ĐƯỢC RỒI",
    //             isDel:"Bạn có chắc muốn xóa không?",
    //             sum:"tổng",
    //             one:"1",
    //             two:"2",
    //             three:"3",
    //             four:"4",
    //             five:"5",
    //             six:"6",
    //             seven:"7",
    //             eight:"8",
    //             nine:"9",
    //             load:"Đang tải...",
    //             big:"Lớn",
    //             small:"Nhỏ",
    //             odd:"Lạ",
    //             even:"Đôi",
    //             equal:"ngang",
    //             red:"Đỏ",
    //             black:"Đen",
    //             green:"xanh",
    //         },
    //         app:{
    //             lowline:"Dòng hiện tại chậm hoặc không có, nên quay lại đăng nhập và chọn dòng khác",
    //             getout:"Vì bạn đã lâu không đặt cược, vui lòng đăng nhập lại!",
    //             otherLogin:"Tài khoản hiện tại đã được đăng nhập ở nơi khác. Nếu đó không phải là thao tác của bạn, vui lòng thay đổi mật khẩu càng sớm càng tốt!",
    //             loginTop:"Tài khoản của bạn bị buộc đăng xuất!",
    //             toLogin:"đăng ký lại",
    //             isLogout:"Bạn có chắc chắn bạn muốn thoát?",
    //             nothing:"N",
    //             great:"G",
    //         },
    //         nav:{
    //             realMoney:"người thực",
    //             esportsMoney:"các môn thể thao",
    //             settings:"cài đặt",
    //             home:"đại sảnh",
    //             personal:"Phục vụ",
    //             network:"dòng video",
    //             sound:"Cài đặt âm thanh",
    //             agreement:"Khoản của thỏa thuận",
    //             logout:"đăng xuất",
    //             atHome:"Hiện đang ở sảnh trò chơi",
    //             atPersonal:"Hiện đang ở sảnh dịch vụ",
    //             videoChangeSucc:"Đã chuyển dòng video",
    //             chat:"dịch vụ khách hàng",
    //             bgAudio:"Nhạc nền",
    //             startAudio:"bắt đầu kết thúc",
    //             resultAudio:"mở cược",
    //             tenAudio:"10 giây cuối cùng",
    //         },
    //         video:{
    //             pool:"nồi",
    //             refresh:"làm mới video",
    //             focal:"chuyển đổi độ dài tiêu cự",
    //             noRebate:"Hiện tại không có hoa hồng",
    //             rebate:"Hiện không miễn phí hoa hồng",
    //             noChangeRebate:"Không có chế độ miễn hoa hồng tại quầy lễ tân hiện tại",
    //             videoErr:"Dòng video hiện tại không khả dụng, vui lòng chọn dòng video khác",
    //             noFocal:"Không có độ dài tiêu cự nào khác để chuyển đổi",
    //             noVideo:"Hiện tại không có video nào ở mặt trước",
    //         },
    //         roomlist:{
    //             hear:"vị trí hiện tại",
    //             hearTip:"Hiện tại trên bàn!",
    //             old:"cũ",
    //             new:"mới",
    //         },
    //         login:{
    //             userName:"tên tài khoản",
    //             pwd:"mật khẩu mở khóa",
    //             code:"mã xác nhận",
    //             verifyType:"Cách xác định:",
    //             slider:"thanh trượt",
    //             chooseNetwork:"Chọn một dòng:",
    //             login:"Đăng nhập",
    //             slideTip:"Giữ thanh trượt và kéo sang bên phải",
    //             slideSuccessTip:"Đã xác minh",
    //             userEmpty:"Tên ngươi dung không được để trông",
    //             pwdEmpty:"mật khẩu không được để trống",
    //             slideVerify:"Vui lòng kéo thanh trượt để xác minh",
    //             codeVerify:"Lỗi mã xác minh",
    //             loginErr:"tên người dùng hoặc mật khẩu sai",
    //             wsError:"Dòng hiện tại không khả dụng, vui lòng thử dòng khác",
    //             loginFail:"Đăng nhập thất bại!",
    //             logining:"Ký vào",
    //             gameModel:"Chế độ trò chơi：",
    //             classics:"Cổ điển",
    //             tel:"Điện thoại",
    //             colorType:"Chọn sắc：",
    //             light:"Đủ ánh sáng",
    //             dark:"Bóng tối",
    //             fullscreen:"Toàn màn hình",
    //             unFullscreen:"Thoát toàn màn hình",
    //         },
    //         home:{
    //             goRoomErr:"Không vào được bảng",
    //             moreGame:"thêm những trò chơi khác",
    //         },
    //         room:{
    //             allBet:"Tổng mức đầu tư",
    //             ingBet:"cuộn trước",
    //             edBet:"bình chọn",
    //             multipleRoom:"nhiều",
    //             singleRoom:"Duy nhất",
    //             clear:"xa lạ",
    //             cancel:"Hủy bỏ",
    //             confirm:"xác nhận",
    //             nextz:"Mở trò chơi tiếp theo",
    //             nextx:"trò chơi tiếp theo",
    //             nextl:"Trò chơi tiếp theo để mở rồng",
    //             nexthu:"Mở trò chơi tiếp theo",
    //             willz:"Nhà cái hỏi đường",
    //             willx:"Hỏi đường",
    //             willl:"Rồng hỏi đường",
    //             willhu:"Tiger hỏi đường",
    //             rebateTxt:"Banker thắng 6 điểm và thua một nửa",
    //             lowerLimitred:"Giới hạn dưới của vị trí này là",
    //             higherLimitred:"Vị trí này được giới hạn ở",
    //             laveLimitred:"Giới hạn còn lại cho vị trí này là",
    //             noStartGame:"Đếm ngược đã kết thúc, hãy chờ thời gian bắt đầu của hiệp tiếp theo của trò chơi",
    //             fullAmount:"Vị trí này đã đầy",
    //             changeTable:"chuyển bàn",
    //             isChangeRebate:"Bạn có một số tiền cược chưa được gửi, chế độ chuyển đổi sẽ bị xóa, bạn vẫn muốn chuyển đổi?",
    //             unRebate:"Miễn hoa hồng",
    //             singleCount:"Đơn",
    //             doubleCount:"Đôi",
    //             multiplesCount:"đa",
    //             resultGreat:"Tuyệt.",
    //             resultNothing:"Không.",
    //             resultBomb:"Bom",
    //             resultGold:"Vàng",
    //             resultSilver:"Bạc",
    //             bigSmall:"Cỡ",
    //             oddEven:"Lạ",
    //             sumCount:"Tổng",
    //             eachNum:"Số",
    //         },
    //         personal:{
    //             funds:"Gửi và rút tiền",
    //             recharge:"nạp điện",
    //             withdraw:"rút",
    //             record:"ghi lại truy vấn",
    //             rechargeLog:"Hồ sơ nạp tiền",
    //             withdrawLog:"Bản ghi rút tiền",
    //             submitLog:"kỷ lục cá cược",
    //             updateInformation:"sửa đổi thông tin",
    //             updateSource:"Các kênh tài trợ",
    //             updatePwd:"đổi mật khẩu",
    //             promote:"Phản hồi về khuyến mại",
    //             share:"đăng lại",
    //             problem:"Nhận xét",
    //             agreement:"Khoản của thỏa thuận",
    //             about:"về chúng tôi",
    //             back:"trở về",
    //             rechargeAmount:"Số tiền nạp",
    //             rechargeType:"loại nạp tiền",
    //             payment:"phương thức thanh toán",
    //             currency:"tiền tệ",
    //             submit:"Gửi đi",
    //             withdrawAmount:"Số tiền rút",
    //             withdrawal:"Phương thức rút tiền",
    //             withdrawType:"Loại rút tiền",
    //             accepted:"Người thụ hưởng",
    //             withdrawPwd:"Mật khẩu rút tiền",
    //             startDate:"ngày bắt đầu",
    //             endDate:"ngày cuối",
    //             date:"ngày",
    //             money:"số lượng",
    //             rechargeState:"trạng thái nạp tiền",
    //             withdrawState:"Tình trạng rút tiền",
    //             gameType:"loại trò chơi",
    //             room:"Phòng",
    //             round:"Bốt",
    //             result:"kết quả",
    //             final:"thắng hay thua",
    //             deposit:"THĂNG BẰNG",
    //             media:"Trang thiết bị",
    //             report:"cá cược",
    //             alipay:"Alipay",
    //             name:"Tên",
    //             account:"tài khoản",
    //             unionPay:"UnionPay",
    //             bank:"ngân hàng",
    //             wechat:"WeChat",
    //             virtualCurrency:"chuỗi khối",
    //             walletAddress:"địa chỉ ví",
    //             pwdType:"Loại mật khẩu",
    //             loginPwd:"mật khẩu đăng nhập",
    //             withdrawPwd:"Mật khẩu rút tiền",
    //             oldPwd:"Mật khẩu cũ",
    //             newPwd:"mật khẩu mới",
    //             againPwd:"Xác nhận mật khẩu",
    //             rechargeAmountErr:"Số tiền nạp không chính xác",
    //             rechargeTypeErr:"nạp tiền sai loại",
    //             rechargeSuccess:"Đơn đăng ký nạp tiền đã được gửi thành công!",
    //             rechargeTypeChoose:"Vui lòng chọn hình thức nạp tiền",
    //             rechargePaymentChoose:"Vui lòng chọn phương thức thanh toán",
    //             rechargeCurrencyChoose:"Vui lòng chọn đơn vị tiền tệ",
    //             rechargeErr:"Nạp tiền không thành công",
    //             rechargeRange:"Một số tiền nạp lại là",
    //             submitMuch:"Gửi quá thường xuyên, vui lòng thử lại sau",
    //             noVip:"Thành viên chưa đăng ký không thể nạp tiền",
    //             invalidUser:"Người dùng ngoại lệ",
    //             submitAgain:"Bạn đã gửi khoản tiền này",
    //             aisleErr:"Kênh nạp tiền bất thường, vui lòng thử các cách nạp tiền khác!",
    //             bossErr:"Do đại lý cấp trên gặp sự cố nên tạm thời không thể nạp tiền cho bạn!",
    //             officialErr:"Số tiền nạp chính thức không đủ, vui lòng thử các phương thức hoặc đơn vị tiền tệ khác!",
    //             qrcodeIs:"Mã xác minh của bạn là",
    //             codeToRemark:"Vui lòng điền mã xác nhận vào phần ghi chú khi nạp tiền để đảm bảo nạp tiền thành công!",
    //             scanOrClick:"Vui lòng quét mã hoặc nhấp vào",
    //             hear:"nơi đây",
    //             toRecharge:"nạp điện",
    //             all:"tất cả các",
    //             real:"người thực",
    //             esports:"các môn thể thao",
    //             phone:"điện thoại di động",
    //             pc:"máy tính",
    //             enterTitle:"vui lòng nhập tiêu đề",
    //             enterProblem:"Vui lòng nhập câu hỏi hoặc đề xuất của bạn",
    //             titleEmpty:"Tiêu đề không được để trống",
    //             contentEmpty:"nội dung không được để trống",
    //             send:"gửi",
    //             sendErr:"Gửi thất bại",
    //             withdrawPwdErr:"Mật khẩu rút tiền không chính xác",
    //             withdrawAmountErr:"Số tiền rút không chính xác",
    //             withdrawRange:"Một số tiền rút duy nhất là",
    //             withdrawHandlingFee:"50 phí xử lý cho mỗi lần rút tiền, 100 phí xử lý cho hơn 50.000",
    //             withdrawAmountLack:"Số dư rút tiền không đủ",
    //             withdrawWithdrawalChoose:"Vui lòng chọn phương thức rút tiền",
    //             withdrawTypeChoose:"Vui lòng chọn loại rút tiền",
    //             toAddAccepted:"Vui lòng chuyển đến mục sửa đổi thông tin để thêm người nhận thanh toán cho phương thức rút tiền này",
    //             reportErr:"Có những cược không an toàn",
    //             acceptedErr:"Người nhận thanh toán không hợp lệ",
    //             withdrawErr:"Rút tiền không thành công",
    //             withdrawSuccess:"Đơn rút tiền đã được gửi thành công, hãy kiên nhẫn!",
    //             updLoginPwdSuccess:"Sửa đổi thành công, vui lòng đăng nhập lại",
    //             updateSuccess:"Đã sửa đổi thành công",
    //             oldPwdEmpty:"Mật khẩu ban đầu không được để trống",
    //             newPwdEmpty:"Mật khẩu mới không được để trống",
    //             againPwdEmpty:"xác nhận mật khẩu không được để trống",
    //             pwdEmpty:"Mật khẩu ban đầu hoặc mật khẩu mới không được để trống",
    //             pwdErr:"Mật khẩu ban đầu sai",
    //             pwdUnequal:"Mật khẩu mới không khớp với mật khẩu đã xác nhận",
    //             pwdEqual:"Mật khẩu mới không khớp với mật khẩu cũ",
    //             pwdTypeChoose:"Vui lòng chọn một loại mật khẩu",
    //             pwdLength:"Độ dài mật khẩu phải từ 6 ~ 12 ký tự",
    //             accountLimit:"Loại tài khoản này đã đạt đến giới hạn",
    //             invalidCurrency:"Tiền tệ không hợp lệ",
    //             sourceHasBills:"Tài khoản này có đơn đặt hàng chưa hoàn thành và không thể xóa tạm thời",
    //             nameEmpty:"Tên không được để trống",
    //             accountEmpty:"Tài khoản không được để trống",
    //             bankEmpty:"Ngân hàng tài khoản không được để trống",
    //             walletAddressEmpty:"Địa chỉ ví không được để trống",
    //             stateWait:"Comment",
    //             stateDoing:"Màu:",
    //             stateReady:"Hoàn",
    //             stateFail:"Lỗi",
    //             total:"Tổng",
    //             valid:"Hiệu",
    //             noSubmitLog:"Tôi không t ìm thấy đĩa nào cả.",
    //             noWithdrawLog:"Tôi không t ìm thấy hồ sơ nào cả.",
    //             noRechargeLog:"Không t ìm thấy hồ sơ nào, đi sạc đi.",
    //         },
    //         chat:{
    //             serverCon:"Máy phục vụ khách hàng đã kết nối...",
    //             serverErr:"Kết nối phục vụ khách hàng bị lỗi!",
    //             serverNo:"Không có dịch vụ",
    //             serverUrl:"Địa chỉ máy phục vụ khách hàng không được cấu hình",
    //             helpTip:"Xin chào, tôi có thể giúp gì?",
    //             serverNoTip:"Hiện tại không có dịch vụ khách hàng trực tuyến!",
    //             msgEmpty:"Không thể gửi thông điệp rỗng",
    //         }
    //     }
    // }
})